import React from 'react';

export default function RadioCheckedIcon(props) {
  const { color = '#4597e0', ...otherProps } = props;
  return (
    <svg height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <circle cx='9' cy='9' fill='#fcfdfe' r='8.5' stroke={color} />
      <circle cx='9' cy='9' fill={color} r='4.5' />
    </svg>
  );
}
