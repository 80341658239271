import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ListItem } from './list-item';

type DraggableListItemPreviewProps = {
  primaryText: string;
  secondaryText: string;
  draggableRef: React.MutableRefObject<HTMLDivElement>;
};

export function DraggableListItemPreview({
  draggableRef,
  primaryText,
  secondaryText,
}: DraggableListItemPreviewProps): JSX.Element {
  const { width } = useMemo(() => draggableRef.current.getBoundingClientRect(), [draggableRef]);
  return <StyledListItem width={width} primaryText={primaryText} secondaryText={secondaryText} />;
}

const StyledListItem = styled(ListItem)<{ width: number }>`
  opacity: 0.75;
  width: ${({ width }) => (width ? `${width}px` : 'unset')};
`;
