import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/themes';
import { Logo } from 'shared/assets/logo';
import { mediaQueries } from 'shared/styles';

export function DutchiePay(): JSX.Element {
  return (
    <ImageWrapper>
      <HeaderWrapper>
        <Logo role='img' fill={colors.white} />
        <HeroImage src='https://images.dutchie.com/pay/dutchie-pay-admin-hero.png' alt='Dutchie Pay Hero Image' />
      </HeaderWrapper>
      <BodyWrapper>
        <Text>
          <Description>Dutchie Pay is now Pay by Bank, powered by Plaid</Description>
          <p>
            We’re proud to offer secure bank linking thanks to our exclusive partnership with Plaid, the most trusted
            name in bank linking. It’s the same simple, stable and secure offering, now made better for your business,
            and your customers.
          </p>
        </Text>
        <LearnMore
          href='https://business.dutchie.com/payments?utm_medium=in-app&utm_source=DPOS_product&utm_campaign=base_login_page_DP_PBB&utm_content=base_base_link'
          target='_blank'
          rel='noreferrer'
        >
          Learn More
        </LearnMore>
      </BodyWrapper>
    </ImageWrapper>
  );
}

const LearnMore = styled.a`
  background: ${colors.white};
  border-radius: 0.25rem;
  color: #000000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.00375rem;
  padding: 0.5rem;
  width: fit-content;

  &:hover {
    color: #000000;
    font-weight: 700;
  }
`;

const Description = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.3125rem;
`;

const HeroImage = styled.img`
  display: block;
  width: 100%;
  max-width: 45rem;
`;

const ImageWrapper = styled.div`
  background: radial-gradient(219% 62% at 50% 99%, #002101 0%, #ff000000 70%),
    radial-gradient(50% 90% at 99% 2%, #002101 0%, #002101 0%, #002101 0%, #ff000000 96%, #ff000000 100%),
    radial-gradient(75% 75% at 50% 50%, #00a47c 0%, #00a47c 100%);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 3.5rem 5.5rem 2.25rem 3.5rem;

  @media ${mediaQueries.largeDesktop} {
    width: 100%;
    padding: 1rem;
    align-items: center;
  }
`;

const Text = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 485px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
