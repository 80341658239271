import React from 'react';
import { MenuSectionType } from 'types/graphql';
import { LocalSection, Products } from '../homepage-form.types';
import { AddCategoryModal } from './add-category-modal';
import { BrandModal } from './brand-modal';
import { CustomModal } from './custom-modal';

export type ModalControllerProps = {
  handleAddSection: (newSection: LocalSection) => void;
  menuSection: LocalSection | null;
  menuSections: LocalSection[] | null;
  onToggle: () => void;
  products: Products | null;
  sectionTypeKey: MenuSectionType;
};

const sectionTypeToModal: Record<MenuSectionType, React.FC<ModalControllerProps> | null> = {
  [MenuSectionType.category]: AddCategoryModal,
  [MenuSectionType.subcategory]: AddCategoryModal,
  [MenuSectionType.brand]: BrandModal,
  [MenuSectionType.custom]: CustomModal,
  [MenuSectionType.offers]: null,
  [MenuSectionType.specials]: null,
  [MenuSectionType.staffPicks]: null,
  [MenuSectionType.topSellers]: null,
};

export function ModalController(props: ModalControllerProps): JSX.Element | null {
  const { sectionTypeKey } = props;
  const Modal = sectionTypeToModal[sectionTypeKey];

  if (!Modal) {
    return null;
  }

  return <Modal {...props} />;
}
