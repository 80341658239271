import useErnie from 'shared/hooks/use-ernie';

type UseCopyToClipboardReturn = {
  copyToClipboard: (contentToCopy: string) => void;
};

export const useCopyToClipboard = (): UseCopyToClipboardReturn => {
  const showErnie = useErnie();

  const copyToClipboard = (contentToCopy: string): void => {
    const elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = contentToCopy;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    showErnie('Successfully copied to your clipboard!', 'success');
  };

  return {
    copyToClipboard,
  };
};
