import React from 'react';

import { Preview } from '../components/preview';
import { MenuCustomizationLayout } from '../components/menu-customization-layout';

import { MenuCustomizationData } from '../data-access';

import { useImageBannerForm } from './use-image-banner-form';
import { AddImageBanner } from './add-image-banner';
import { ImageBannerLimit } from './image-banner-limit';
import { ImageBannerList } from './image-banner-list';
import { ImageBannerModal } from './image-banner-modal';

export type MenuBannerFormProps = {
  data: MenuCustomizationData;
};

export function ImageBannerForm({ data }: MenuBannerFormProps): JSX.Element {
  const {
    isDirty,
    imageBanners,
    isImageBannerLimitReached,
    handleAddImageBanner,
    handleRemoveBanner,
    handleEditBanner,
    onDragEnd,
    previewData,
    handlePublish,
    handleReset,
    showModal,
    addImageModal,
    closeModal,
    bannerToEdit,
  } = useImageBannerForm({ data });

  return (
    <MenuCustomizationLayout
      headerText='Image Banner'
      subHeaderText='Display an image carousel above your menu.'
      isDirty={isDirty}
      handlePublish={handlePublish}
      handleReset={handleReset}
      previewComponent={<Preview.HomePage data={previewData} />}
    >
      {isImageBannerLimitReached ? <ImageBannerLimit /> : <AddImageBanner addImageModal={addImageModal} />}
      <ImageBannerList
        imageBanners={imageBanners}
        removeBanner={handleRemoveBanner}
        editBanner={handleEditBanner}
        onDragEnd={onDragEnd}
      />

      <ImageBannerModal
        isOpen={showModal}
        onClose={closeModal}
        addBanner={handleAddImageBanner}
        bannerToEdit={bannerToEdit}
      />
    </MenuCustomizationLayout>
  );
}
