import React, { useState, useEffect, forwardRef } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import _ from 'lodash';
import {
  display,
  fontSize as fontSizeStyled,
  width as widthStyled,
  margin as marginStyled,
  space,
} from 'styled-system';
import styled from 'styled-components';

import useStyledTheme from 'shared/hooks/use-styled-theme';
import { Label } from 'shared/components/legacy-rebass';
import RadioCheckedIcon from 'shared/assets/radio-checked';
import RadioUncheckedIcon from 'shared/assets/radio-unchecked';
import RadioDisabledIcon from 'shared/assets/radio-disabled';
import Tooltip from 'shared/components/tooltip';

const StyledRadioGroup = styled(RadioGroup)`
  display: ${({ hasTooltips }) => (hasTooltips ? 'flex' : 'block')};
`;

const RadioLabel = styled(Label)`
  ${display}
  ${fontSizeStyled}
  ${widthStyled}
  ${marginStyled}
  position: relative;
  line-height: 16px;
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  padding-top: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom || '4px'};
  ${space}
  .--bold {
    font-weight: bold;
    margin-right: 6px;
  }

  & > svg {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const StyledRadio = styled(Radio)`
  height: 17px;
  width: 17px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  + span {
    display: inline-block;
  }
  + span,
  + .radioDiv {
    color: #6d747b;
    padding-left: 30px;
    height: var(--radio-height);
    width: 100%;
    text-align: left;
    padding-top: ${(props) => (props.fontSize ? `calc((16px - ${props.fontSize}) / 2)` : '0')};
    padding-bottom: ${(props) => (props.fontSize ? `calc((16px - ${props.fontSize}) / 2)` : '0')};
  }

  &:disabled + span,
  &:disabled + .radioDiv {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:checked:disabled + span,
  &:checked:disabled + .radioDiv {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.focus-visible + span {
    box-shadow: 0 0 0 2pt rgba(11, 153, 230, 0.4);
  }
`;

export default forwardRef((/** @type { any } */ props, ref) => {
  const {
    checked,
    options = [],
    onChange = _.noop,
    defaultValue = '',
    fontSize = '14px',
    width,
    name,
    hasSublabels,
    hasSubDescriptions,
    required = false,
    showChecksWhenDisabled = false,
    updateCurrentValueWithDefaultValue = false,
    hasTooltips = false,
    tooltipOptions = null,
    ...otherProps
  } = props;
  const theme = useStyledTheme();
  // keep track of the current value
  const [currentValue, setCurrentValue] = useState('');

  function handleChange(value) {
    setCurrentValue(value);
    onChange(value);
  }

  useEffect(() => {
    if (!_.isNil(checked)) {
      // set currentValue to checked prop if one is provided
      setCurrentValue(checked);
    } else if (currentValue === '' && defaultValue !== '' && !_.isNil(defaultValue)) {
      // if we don't start with a default value but then get one, we should default to that
      setCurrentValue(defaultValue);
    } else if (updateCurrentValueWithDefaultValue && currentValue !== defaultValue) {
      // if updateCurrentValueWithDefaultValue is true, then defaultValue is allowed to change and currentValue should
      // reflect it
      setCurrentValue(defaultValue);
    }
  }, [checked, currentValue, defaultValue]);

  return (
    <StyledRadioGroup
      name={name}
      ref={ref}
      onChange={handleChange}
      selectedValue={currentValue}
      role='radiogroup'
      aria-label={name}
      hasTooltips={hasTooltips}
    >
      {_.map(options, (option) => {
        const {
          label,
          sublabel,
          subDescription,
          value,
          disabled,
          LabelComponent,
          marginRight = '45px',
          marginBottom,
        } = option;
        const tooltipCopy = tooltipOptions?.[value];
        return (
          <RadioLabel
            mr={marginRight}
            key={value}
            fontSize={fontSize}
            width={option.width || width}
            mb={marginBottom}
            {...otherProps}
          >
            <StyledRadio
              name='radio'
              value={value}
              disabled={disabled}
              fontSize={fontSize}
              data-cy={option.radioButtonDataCyName}
              data-test={option.radioButtonDataCyName}
              aria-checked={value === currentValue}
              role='radio'
              required={required}
            />
            {hasSublabels && !hasSubDescriptions && !LabelComponent && (
              <span>
                <span
                  className='--bold'
                  data-cy={option.dataCyName || ''}
                  data-test={option.dataCyName || ''}
                  data-testid={option.dataCyName || ''}
                >
                  {label}
                </span>
                <span>{sublabel}</span>
              </span>
            )}
            {hasSublabels && hasSubDescriptions && !LabelComponent && (
              <StyledSubDescriptionWrapper>
                <div>
                  <span
                    className='--bold'
                    data-cy={option.dataCyName || ''}
                    data-test={option.dataCyName || ''}
                    data-testid={option.dataCyName || ''}
                  >
                    {label}
                  </span>
                  <span>{sublabel}</span>
                </div>
                <p>{subDescription}</p>
              </StyledSubDescriptionWrapper>
            )}
            {!hasSublabels && !LabelComponent && <span>{label}</span>}
            {LabelComponent && (
              <div className='radioDiv'>
                <LabelComponent />
              </div>
            )}
            {(!disabled || showChecksWhenDisabled) && value === currentValue && (
              <RadioCheckedIcon
                color={disabled ? '#b7bec3' : theme?.customized?.colors?.buttonsLinks ?? theme?.colors?.primaryBlue}
              />
            )}
            {!disabled && value !== currentValue && <RadioUncheckedIcon />}
            {disabled && value !== currentValue && <RadioDisabledIcon cursor='not-allowed' />}
            {hasTooltips && tooltipCopy && (
              <Tooltip tooltipCopy={tooltipCopy} grey left='auto' bottom='30px' right='-61px' width='239px' />
            )}
          </RadioLabel>
        );
      })}
    </StyledRadioGroup>
  );
});

const StyledSubDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  gap: 12px;
`;
