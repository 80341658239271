import moment from 'moment';
import _ from 'lodash';
import { convertDate } from 'shared/helpers/date-time';
// eslint-disable-next-line import/no-cycle
import { getProductWeight, saleSatisfiesWeightCondition } from './products';
// eslint-disable-next-line import/no-cycle
import { eligibleProductOptionsForRestriction } from './specials-3-5';

const LA_TIMEZONE = 'America/Los_Angeles';

export const currentSpecialsFilter = (special) => {
  // Analogous to currentParams Mongo-based logic
  const now = moment().toDate();
  const basicFilters = special.active && !special.complete && special.version >= 2;
  const recurringFilters =
    (!special.isRecurring && special.endStamp > now) ||
    (special.isRecurring && (!special.recurring?.setEndDate || special.recurring?.endDate > now));

  return basicFilters && recurringFilters;
};

export const days = moment.weekdays(false);

export const dayAbbreviations = [
  { key: 1, day: `Mon` },
  { key: 2, day: `Tue` },
  { key: 3, day: `Wed` },
  { key: 4, day: `Thu` },
  { key: 5, day: `Fri` },
  { key: 6, day: `Sat` },
  { key: 0, day: `Sun` },
];

export const defaultAdvancedConditionOptions = (type, weight = 'Any Weight') => ({
  enabled: true,
  ...(type === 'quantity' ? { quantity: '' } : {}),
  ...(type === 'maxQuantity' ? { maxQuantity: '' } : {}),
  ...(type === 'quantity' ? { quantityOperator: 'equalTo' } : {}),
  ...(type === 'weight' ? { weight } : {}),
  ...(type === 'weight' ? { weightOperator: 'equalTo' } : {}),
  ...(type === 'spend' ? { minimumSpend: 0, maximumSpend: 0 } : {}),
  ...(type === 'spend' ? { spendOperator: 'greaterThanEqualTo' } : {}),
});

// Used as reducer to extract .exclusions from an object (Relevant to special v3.5)
const extractExclusions = (prev, curr) => {
  if (curr?.exclusions?.length > 0) {
    return prev.concat(curr.exclusions);
  }

  return prev;
};

export const extractSaleExclusions = (special) => {
  // v3.5
  if (special?.saleDiscounts?.length > 0) {
    return _.reduce(special.saleDiscounts, extractExclusions, []);
  }

  // v3.0
  return special?.excludedProducts?.length > 0 ? [...special.excludedProducts] : [];
};

export const getDaysList = (daysArr) => {
  const dayList = _.map(daysArr, (key) => _.find(dayAbbreviations, [`key`, key]));
  // eslint-disable-next-line lodash/prefer-lodash-method
  return dayList
    .sort((a, b) => a.key - b.key)
    .map((d) => d.day)
    .join(', ');
};

export const specialIsRecurring = (special) => {
  if (!special.recurring) {
    return false;
  }

  return special.isRecurring;
};

export const specialHasAdvancedConditions = (special) =>
  special.specialType === 'bogo' &&
  (!special.bogoConditions || special.bogoConditions?.length === 0) &&
  (special.totalSpend?.enabled || special.totalWeight?.enabled || special.totalQuantity?.enabled);

export const specialHasRequiredProducts = (special, filteredProducts) => {
  // if filtered products is undefined, skip validation and return true
  if (!filteredProducts) {
    return true;
  }

  // filtered products are serialized with special data
  // reduce the set of specials tied to products and compare with input special
  const specialHasProducts = filteredProducts.some((product) => {
    const specials = [...(product.specialData.bogoSpecials || []), ...(product.specialData.saleSpecials || [])];
    return specials.some((productSpecial) => productSpecial.specialId === special._id);
  });

  // if the special is for min spend and there are no other conditions, it applies to all products
  return specialHasAdvancedConditions(special) || specialHasProducts;
};

export const getAdjustedRecurringConfig = ({ days: recurringDays, endTime, startTime }, timezone = LA_TIMEZONE) => {
  const dateFormat = 'yyyy-MM-DD';
  const timeFormat = 'hh:mm A';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const today = moment.tz(timezone).format(dateFormat);
  /**
   * The reason we need to include the date here is because for some reason without it, the time is created for
   * the next day in some cases, and thus will cause the specialIsActive result to be incorrect.
   */
  let specialStartToday = moment.tz(`${today} ${startTime}`, dateTimeFormat, timezone);
  let specialEndToday = moment.tz(`${today} ${endTime}`, dateTimeFormat, timezone);
  let startDays = recurringDays;
  let endDays = recurringDays;

  // if the end time is earlier than the start time,
  // that probably means the end time is on the next calendar day
  // OR it means the start time is on the previous calendar day
  // Example: enterprise has set a special to be 9:00 AM - 11:45 PM Pacific time
  // this would be 12:00 PM - 2:45 AM for an east coast dispensary.
  if (specialEndToday.isBefore(specialStartToday) && endTime && startTime) {
    // Dispensary is in a more western timezone than what the enterprise selected
    // move start days back
    if (specialStartToday.hour() > 12) {
      startDays = _.map(startDays, (day) => (day === 0 ? 6 : day - 1));
      specialStartToday = specialStartToday.add(-1, 'd');
    }
    // Dispensary is in a more eastern timezone than what the enterprise selected
    // move end days forward
    else {
      endDays = _.map(endDays, (day) => (day === 6 ? 0 : day + 1));
      specialEndToday = specialEndToday.add(1, 'd');
    }
  }
  return { endDays, endTime: specialEndToday, startDays, startTime: specialStartToday };
};

const recurringSpecialIsActiveNow = (special, timezone = LA_TIMEZONE) => {
  const now = moment();

  const { setEndDate, endDate } = special.recurring;
  if (!setEndDate || now.isBefore(endDate)) {
    const { endDays, endTime, startDays, startTime } = getAdjustedRecurringConfig(special.recurring, timezone);

    if (!_.includes([...endDays, ...startDays], Number(now.format(`d`)))) {
      return false;
    }

    // Support for "open all day" if special was generated by LLx
    if (special.source === 'POS' && !special.recurring?.startTime && !special.recurring?.endTime) {
      return true;
    }

    if (!special.recurring?.startTime || !special.recurring?.endTime) {
      return false;
    }

    return now.isBetween(startTime, endTime);
  }
  return false;
};

export const specialIsActive = (special, timezone = LA_TIMEZONE) => {
  if (special.active) {
    if (specialIsRecurring(special)) {
      return recurringSpecialIsActiveNow(special, timezone);
    }

    if (!special.startStamp || !special.endStamp) {
      return false;
    }

    const now = moment();
    const nowIsSameOrAfterStart = now.isSameOrAfter(moment(special.startStamp));
    const nowIsSameOrBeforeEnd = now.isSameOrBefore(moment(special.endStamp));
    return nowIsSameOrAfterStart && nowIsSameOrBeforeEnd;
  }
  return false;
};

export const specialStarted = (special) => {
  const now = moment();
  const stampToUse = getEarliestStartStamp(special);
  return now.isAfter(moment(stampToUse));
};

export const getCloseDate = (hoursType = `pickupHours`, dispensary) => {
  const dispensaryTimezone = dispensary.timezone || LA_TIMEZONE;
  const today = days[new Date().getDay()];
  const hours = _.get(dispensary, hoursType, `8:00 PM`);
  return convertDate(hours[today].end, dispensaryTimezone);
};

export const getLatestOpenTime = (dispensary) => {
  const lastPickupTime = getCloseDate(`pickupHours`, dispensary);
  const lastDeliveryTime = getCloseDate(`deliveryHours`, dispensary);

  return moment.max([lastPickupTime, lastDeliveryTime]);
};

export const addDays = (date, daysProp) => {
  const result = new Date(date);
  result.setDate(result.getDate() + daysProp);
  return result;
};

export const specialIsPending = (special) => {
  const now = moment();
  const stampToUse = getEarliestStartStamp(special);
  return special.active && now.isBefore(stampToUse);
};

export const segmentCurrentAndPendingSpecials = (specials = []) =>
  _.reduce(
    specials,
    (accumulator, special) => {
      accumulator[specialIsPending(special) ? 1 : 0].push(special);
      return accumulator;
    },
    [[], []]
  );

export const parseDates = (special) => {
  const { endDay, endStamp, schedule, startDay, startStamp, isRecurring, recurring, version } = special;
  const specialObj = special;

  if (version === 4) {
    _.set(
      specialObj.schedule,
      `endStamp`,
      schedule.endStamp && moment(schedule.endStamp, [`x`, moment.ISO_8601]).toDate()
    );
    _.set(
      specialObj.schedule,
      `startStamp`,
      schedule.startStamp && moment(schedule.startStamp, [`x`, moment.ISO_8601]).toDate()
    );
  }

  _.set(specialObj, `endDay`, endDay && moment(endDay, [`x`, moment.ISO_8601]).toDate());
  _.set(specialObj, `endStamp`, endStamp && moment(endStamp, [`x`, moment.ISO_8601]).toDate());
  _.set(specialObj, `startDay`, startDay && moment(startDay, [`x`, moment.ISO_8601]).toDate());
  _.set(specialObj, `startStamp`, startStamp && moment(startStamp, [`x`, moment.ISO_8601]).toDate());

  if (isRecurring && recurring?.setEndDate) {
    _.set(specialObj, `recurring.endDate`, moment(recurring?.endDate, [`x`, moment.ISO_8601]).toDate());
  }

  return specialObj;
};

// Currently not used by anything, but if it is at some point, just uncomment and import!
// export const decodeBrandName = (brandName) =>
//   `${brandName}`
//     .replace('\\u002e', '.')
//     .replace('\\u0024', '$')
//     .replace('\\\\', '\\');

// Used because Mongo does not allow keys with \, $, or ., and encodeURI / encodeURIComponent does not encode all of
// those characters: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export const encodeBrandName = (brandName) =>
  !_.isEmpty(brandName)
    ? `${brandName}`.replace(/\//g, '\\\\').replace(/\$/g, `\\u0024`).replace(/\./g, `\\u002e`)
    : '';

export const isDiscountApplied = (costBreakdown) => {
  const { discounts = 0 } = costBreakdown?.receipt?.summary;
  return discounts > 0;
};

export const compareProductWeightToConditionWeight = (condition, option) => {
  if (_.isNil(condition.weight) || condition.weight === 'Any Weight' || condition.weight === 'N/A') {
    return true;
  }
  const conditionWeight = getProductWeight(condition.weight);
  const detailWeight = getProductWeight(option);
  return condition.weightOperator === 'greaterThan' ? detailWeight > conditionWeight : detailWeight === conditionWeight;
};

export const hasSaleSpecialForMenuType = ({ product, medicalOrder, menuType }) => {
  const isMedicalOrder = !_.isNil(medicalOrder) ? medicalOrder === true : menuType === 'med' || menuType === 'medical';
  if (isMedicalOrder) {
    return !_.isEmpty(product.medicalSpecialPrices);
  }
  return !_.isEmpty(product.recSpecialPrices);
};

export const specialMatchesMenuType = (special, menuType) =>
  !special.menuType ||
  special.menuType === 'both' ||
  (special.menuType === 'medical' && menuType === 'med') ||
  (special.menuType === 'recreational' && menuType === 'rec');

export const productHasSaleSpecial = ({ product, medicalOrder, menuType }) => {
  const hasOriginalSaleSpecial = hasSaleSpecialForMenuType({ product, medicalOrder, menuType });
  const hasWeightedSpecial = productHasWeightOnSale(product);
  return hasOriginalSaleSpecial && hasWeightedSpecial;
};

export function productHasWeightOnSale(product) {
  const { formattedOptions } = product;
  return _.some(formattedOptions || [], (productWeight) =>
    product.specialData?.saleSpecials
      ? productSatisfiesSaleWeight(product, product.specialData.saleSpecials, productWeight)
      : true
  );
}

export function productIsExcludedFromOffer(product, currentSpecialId) {
  const currentSpecial = _.find(product?.specialData?.bogoSpecials, ['specialId', currentSpecialId]);
  const productIsExcludedFromConditionsAndRewards =
    currentSpecial?.excludedProducts?.conditions?.length > 0 && currentSpecial?.excludedProducts?.rewards?.length > 0;
  const productIsExcludedFromConditionsAndIFAPEnabled =
    currentSpecial?.excludedProducts?.conditions?.length > 0 && currentSpecial?.itemsForAPrice?.enabled;

  const excludedProductsExist =
    productIsExcludedFromConditionsAndRewards || productIsExcludedFromConditionsAndIFAPEnabled;

  if (excludedProductsExist) {
    const { excludedProducts } = currentSpecial;
    const { conditions, rewards } = excludedProducts;
    // if IFAP is enabled rewards exclusions will be undefined so we only check conditions
    // otherwise we only exclude products that are in both exclusion lists
    const excludedProductsToCheck = currentSpecial?.itemsForAPrice?.enabled
      ? conditions
      : _.intersectionBy(conditions, rewards, '_id');
    return _.some(excludedProductsToCheck, ({ _id }) => product._id === _id);
  }
  return false;
}

export const productHasAtLeastOneQualifyingOption = (product, offerId) => {
  const matchingOffer = _.find(product.specialData?.bogoSpecials, ({ specialId }) => specialId === offerId);
  const qualifyingOptions = _.filter(product.formattedOptions || product.Options, (option) =>
    _.includes(matchingOffer?.qualifyingOptions, option)
  );
  return qualifyingOptions.length > 0;
};

export function productIsPartOfActiveSpecial(product, menuType) {
  if (!product.specialData?.bogoSpecials) {
    return false;
  }
  return (
    _.filter(product.specialData?.bogoSpecials || [], (special) => specialMatchesMenuType(special, menuType))?.length >
      0 && productQualifiesForAtLeastOneOffer(product.specialData.bogoSpecials, product)
  );
}

export function productQualifiesForAtLeastOneOffer(offers, product) {
  return _.some(
    offers,
    (offer) =>
      !productIsExcludedFromOffer(product, offer?.specialId) &&
      productHasAtLeastOneQualifyingOption(product, offer?.specialId)
  );
}

export function isNotSolitaryMinimumSpendOffer(offers) {
  return _.some(offers, ({ totalSpend }) => !totalSpend?.enabled);
}

export const filterPastSpecials = (special) => {
  // Analogous to "past" Mongo-based logic
  const now = moment().toDate();
  const basicFilters = special.active;
  const optionalFilters =
    special.complete ||
    (!special.isRecurring && special.endStamp < now) ||
    (special.isRecurring && special.recurring?.setEndDate && special.recurring?.endDate < now);

  return basicFilters && optionalFilters;
};

export function filterProductsBasedOnSpecials(serializedProducts, pricingType, specialId, specialType) {
  return _.filter(serializedProducts, (product) => {
    if (_.isEmpty(product?.specialData)) {
      return false;
    }
    const viewingIndividualSpecialsPage = !_.isNil(specialId);
    const offerSpecialsMatchingMenu = _.filter(product.specialData?.bogoSpecials || [], (special) =>
      specialMatchesMenuType(special, pricingType)
    );
    const saleSpecialsMatchingMenu = _.filter(product.specialData?.saleSpecials || [], (special) =>
      specialMatchesMenuType(special, pricingType)
    );

    if (viewingIndividualSpecialsPage) {
      if (specialType === 'sale') {
        const productIsOnSale = productHasSaleSpecial({ product, menuType: pricingType });
        return _.some(saleSpecialsMatchingMenu, (special) => special?.specialId === specialId && productIsOnSale);
      }
      const isNotExcluded = !productIsExcludedFromOffer(product, specialId);
      const productQualifies = productHasAtLeastOneQualifyingOption(product, specialId);
      return _.some(
        offerSpecialsMatchingMenu,
        (special) => special?.specialId === specialId && isNotExcluded && productQualifies
      );
    }

    const currentProductHasSaleSpecial =
      saleSpecialsMatchingMenu.length > 0 && productHasSaleSpecial({ product, menuType: pricingType });
    const currentProductQualifiesForAtLeastOneOffer =
      offerSpecialsMatchingMenu.length > 0 && productQualifiesForAtLeastOneOffer(offerSpecialsMatchingMenu, product);

    return currentProductHasSaleSpecial || currentProductQualifiesForAtLeastOneOffer;
  });
}

export const sortSpecialCards = (specials) => _.sortBy(specials, ['displayRank', 'endStamp', `recurring.endDate`]);

export const getSpecialsBasePath = ({ dispensaryId, enterpriseId, scope }) => {
  const isEnterpriseSpecial = scope === 'enterprise';
  const dispensaryOrEnterpriseId = isEnterpriseSpecial && enterpriseId ? enterpriseId : dispensaryId;
  const dispensaryOrEnterpriseText = isEnterpriseSpecial && enterpriseId ? 'enterprise' : 'dispensaries';
  return `/${dispensaryOrEnterpriseText}/${dispensaryOrEnterpriseId}/promote/specials`;
};

export const getEditUrl = ({ _id: specialId, enterpriseSpecialId, ...special }) =>
  `${getSpecialsBasePath(special)}/edit/${enterpriseSpecialId || specialId}`;

export const getCurrentStepIndex = (isReadOnly, SpecialsState) => {
  let stepIndex = 1;
  if (isReadOnly) {
    const specialSteps = _.reduce(
      SpecialsState.completedSteps,
      (result, value, key) => {
        if (value) {
          result.push(key);
        }
        return result;
      },
      []
    );
    stepIndex = specialSteps.length - 1;
  }
  // This is used to reset the index to 0 when customizing Discount SYnc POS Sales,
  // since we only show one step, Menu Design
  if (SpecialsState.isPOSSpecial) {
    stepIndex = 0;
  }
  return stepIndex;
};

export const getEarliestStartStamp = (special) => {
  if (special.earliestStartStamp) {
    return special.earliestStartStamp;
  }
  return special.startStamp;
};

export const includeSettingsStep = ({ globalSpecialsSettings, special }) =>
  globalSpecialsSettings.enableIndividualDiscountStacking ||
  globalSpecialsSettings.enableIndividualSpecialPrecedence ||
  !_.isNil(special.discountStacking) ||
  !_.isNil(special.discountPrecedence);

export const isSettingsStepComplete = (special) =>
  !_.isNil(special.discountStacking) || !_.isNil(special.discountPrecedence);

export const removePOSOnlyFieldsFromBogo = (special) => {
  const { bogoConditions, bogoRewards } = special;
  const fieldsToOmit = [
    'requiresSingleSku',
    'totalQuantity',
    'totalWeight',
    'totalSpend',
    'discountAmount',
    'discountType',
    'applicationRules',
  ];
  special.bogoConditions = _.map(bogoConditions, (condition) => _.omit(condition, fieldsToOmit));
  special.bogoRewards = _.map(bogoRewards, (reward) => _.omit(reward, fieldsToOmit));
};

export const productSatisfiesSaleWeight = (
  product,
  saleSpecials = [],
  formattedWeight = `N/A`,
  withSpecialData = false
) => {
  // If there are no sale specials, return early
  if (saleSpecials === null || saleSpecials?.length < 1) {
    return withSpecialData ? { appliedSpecials: [], result: false } : false;
  }

  const { salesWithRestrictions, salesWithoutRestrictions, threeDotFiveSales } = _.reduce(
    saleSpecials,
    (result, special) => {
      if (!_.isEmpty(special?.saleDiscounts)) {
        result.threeDotFiveSales.push(special); // 3.5 special
      } else if (_.values(special?.specialRestrictions).length > 0) {
        result.salesWithRestrictions.push(special); // < 3.5 special
      } else {
        result.salesWithoutRestrictions.push(special); // < 3.5 special
      }
      return result;
    },
    { threeDotFiveSales: [], salesWithRestrictions: [], salesWithoutRestrictions: [] }
  );

  // If we have a < 3.5 sale without specialRestrictions, then the sale can apply as normal
  if (salesWithoutRestrictions.length > 0) {
    return withSpecialData ? { appliedSpecials: [...saleSpecials], result: true } : true;
  }

  const appliedSpecials = [];
  const formattedWeightInGrams = getProductWeight(formattedWeight);
  let hasApplicableSpecial;

  // For 3.5 sales
  if (threeDotFiveSales.length > 0) {
    _.forEach(threeDotFiveSales, (special) => {
      // 3.5 sales should already have this property after calculation in the serializer
      const eligibleProductOptions =
        special?.eligibleProductOptions ??
        eligibleProductOptionsForRestriction(
          product,
          special.saleDiscounts?.[0],
          'inclusion',
          special,
          special.useActiveBatchTags
        );
      _.forEach(eligibleProductOptions, (productOption) => {
        // Try to match on the string option itself first before weight conversion
        if (formattedWeight === productOption || formattedWeightInGrams === getProductWeight(productOption)) {
          appliedSpecials.push({ specialId: special._id, weight: productOption });
          return false;
        }
        return true; // consistent return
      });
    });
  }

  // For < 3.5 sales with specialRestrictions
  if (salesWithRestrictions.length > 0) {
    const salesType = getSalesType(product);
    const specialRestrictions = _.map(salesWithRestrictions, (s, i) => ({
      [s._id || s.specialId || i]: s.specialRestrictions,
    }));
    let hasMatchingRestriction = false;

    _.forEach(specialRestrictions, (special) => {
      const specialId = _.keys(special)[0];
      const restrictions = Object.entries(_.values(special)[0] || {});

      _.forEach(restrictions, ([key, condition]) => {
        // if we found a matching restriction, see if this product weight applies
        const enterpriseKey = key?.startsWith('EPID_') ? key.split('EPID_')?.[1] : key;
        if (_.includes(salesType, key) || _.includes(salesType, enterpriseKey)) {
          hasMatchingRestriction = true;
          const weights = _.map(condition.weights || [condition.weight], (conditionWeight) =>
            getProductWeight(conditionWeight)
          );
          if (saleSatisfiesWeightCondition(formattedWeightInGrams, condition.weightOperator, weights)) {
            if (!_.includes(appliedSpecials, specialId)) {
              appliedSpecials.push({ specialId, weight: condition.weight });
            }
          }
        }
      });
    });

    hasApplicableSpecial = appliedSpecials.length > 0 || !hasMatchingRestriction;
  } else {
    hasApplicableSpecial = appliedSpecials.length > 0;
  }

  return withSpecialData ? { appliedSpecials, result: hasApplicableSpecial } : hasApplicableSpecial;
};

export const getSalesType = (product) => [
  product.productBrandId ||
    product.brandId ||
    product?.brand?.id ||
    product.productBrandName ||
    product.brandName ||
    product?.brand?.name,
  product.productCategory || product.type || product.category,
  product.productSubcategory || product.subcategory,
  product._id || product.id,
  !product.subcategory ? `uncategorized_${product.productCategory || product.type || product.category}` : null,
  product?.enterpriseProductId ?? null,
];

export const getSpecialBadgeType = (product = {}, saleSpecials = [], weights = []) => {
  const satisfies = [];
  const appliedSpecials = [];

  _.forEach(weights, (weight) => {
    const data = productSatisfiesSaleWeight(product, saleSpecials, weight, true);
    if (data.result) {
      satisfies.push(true);
      _.forEach(data.appliedSpecials, (special) => {
        if (special?.specialId && !_.find(appliedSpecials, [`specialId`, special.specialId])) {
          appliedSpecials.push({ ...special });
        }
      });
    } else {
      satisfies.push(false);
    }
  });

  const uniqueWeights = _.map(appliedSpecials, `weight`);
  if (_.every(satisfies, Boolean) && (uniqueWeights.length === 0 || satisfies.length === uniqueWeights.length)) {
    return `pill`;
  }

  if (_.some(satisfies, Boolean)) {
    return `label`;
  }
  return `none`;
};

export const getProductKeyFromRule = (rule) =>
  rule?.enterpriseProductIds?.[0] ?? rule?.enterpriseProductId ?? rule?.productIds?.[0] ?? rule?.productId ?? rule._id;

// item accepts either a product object, cart-item store object, or breakdownItem object from cart calculations
export const formatDiscountForDisplay = (
  originalPrice = 0,
  discountedPrice,
  item = {},
  type,
  weightOverride, // For specific sale weights, otherwise it's derived from the item itself
  isMobile
) => {
  if (_.isNil(discountedPrice)) {
    return null; // Return early if there is no discount
  }

  const startingPrice = parseFloat(`${originalPrice}`);
  let reducedPrice = parseFloat(`${discountedPrice}`);

  if (_.isNaN(startingPrice) || _.isNaN(reducedPrice)) {
    return null; // Return early if startingPrice or reducedPrice are NaN
  }

  reducedPrice = reducedPrice <= 0 ? 0 : reducedPrice;

  if (reducedPrice >= startingPrice) {
    return null; // Return early if reducedPrice would result in inverse discount
  }

  let formattedDiscount = 'Discounted!';
  let workableItem = item;

  // If item is a cart-item store object, grab the breakdownItem
  if (_.isFunction(workableItem?.getBreakdownItem)) {
    workableItem = workableItem.getBreakdownItem();
  }

  const bogoSavings = workableItem?.bogoSavings;
  const saleSpecials = workableItem?.specialData?.saleSpecials;
  const weight = weightOverride ?? workableItem?.option ?? workableItem?.Option;
  let formatInDollars;

  if (type === 'bogo' || !_.isEmpty(bogoSavings)) {
    // Since offers are conditional and always displayed on the cart or at checkout, we don't have to concern ourselves
    // with weights / menu display elsewhere and can rely on the receipt data alone
    formatInDollars = _.some(
      bogoSavings?.individual,
      (discount) => !discount.displayAsPercentDiscount && (discount.dollarDiscount || discount.targetPrice)
    );
    const suffix = isMobile ? 'discount!' : 'discount applied!';
    formattedDiscount = formatInDollars
      ? `$${(startingPrice - reducedPrice).toFixed(2)} ${suffix}`
      : `${((1 - reducedPrice / startingPrice) * 100.0).toFixed(0)}% ${suffix}`;
  } else if (type === 'sale' || !_.isEmpty(saleSpecials)) {
    formatInDollars = _.some(saleSpecials, (special) => {
      if (weight) {
        return (
          productSatisfiesSaleWeight(workableItem, [special], weight) && (special.dollarDiscount || special.targetPrice)
        );
      }
      return special.dollarDiscount || special.targetPrice;
    });
    formattedDiscount = formatInDollars
      ? `$${(startingPrice - reducedPrice).toFixed(2)} off`
      : `${((1 - reducedPrice / startingPrice) * 100.0).toFixed(0)}% off`;
  }

  return formattedDiscount;
};

export const specialsLogger = ({ logger, data, level = 'debug' }) => {
  const calculationsLogger = logger ?? console;
  switch (level) {
    case 'debug':
      calculationsLogger.debug(data);
      break;
    case 'info':
      calculationsLogger.info(data);
      break;
    case 'warn':
      calculationsLogger.warn(data);
      break;
    case 'error':
      calculationsLogger.error(data);
      break;
    default:
      calculationsLogger.debug(data);
  }
};

export * from './specials/excluded-products';

export { default as excludedProducts } from './specials/excluded-products';
