/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, RefObject, useState } from 'react';
import styled from 'styled-components';
import RichTextHtmlWrapper from 'shared/components/rich-text-html-wrapper';
import { ChevronIcon } from 'src/svg/chevron-icon';

export type MenuBannerProps = {
  bannerColor: string;
  bannerHtml: string;
  bannerCollapsed: boolean;
  shouldScrollInView: boolean;
  scrollIntoView: (componentRef: RefObject<HTMLDivElement>) => void;
};

export const TEST_ID_MENU_BANNER = 'menu-banner';

export function MenuBanner({
  bannerColor,
  bannerHtml,
  bannerCollapsed,
  shouldScrollInView,
  scrollIntoView,
}: MenuBannerProps): JSX.Element {
  const menuBannerRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(bannerCollapsed && bannerHtml.length > 500);
  useEffect(() => {
    if (!shouldScrollInView) {
      return;
    }

    scrollIntoView(menuBannerRef);
  }, [shouldScrollInView, menuBannerRef, scrollIntoView]);

  useEffect(() => {
    setIsCollapsed(bannerCollapsed);
  }, [bannerCollapsed]);

  const handleToggleCollapse = (val: boolean): void => {
    setIsCollapsed(!val);
  };

  return (
    <MenuBannerWrapper
      bannerColor={bannerColor}
      data-testid={TEST_ID_MENU_BANNER}
      ref={menuBannerRef}
      tabIndex={-1}
      isLongHtmlContent={isCollapsed}
    >
      <StyledRichTextWrapper color={bannerColor} isLongHtmlContent={isCollapsed}>
        <RichTextHtmlWrapper html={bannerHtml} className='preview-menu-banner' />
      </StyledRichTextWrapper>
      <StyledChevronWrapper isLongHtmlContent={isCollapsed} onClick={() => handleToggleCollapse(isCollapsed)}>
        <ChevronIcon fill={bannerColor} width='16px' height='16px' />
      </StyledChevronWrapper>
    </MenuBannerWrapper>
  );
}

const MenuBannerWrapper = styled.div<{ bannerColor: string; isLongHtmlContent: boolean }>`
  background: ${({ bannerColor }) => bannerColor};
  border-radius: 11px;
  cursor: default;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 13px;
  justify-content: space-between;
  line-height: 1.65;
  margin: 20px 16px;
  max-width: 1200px;
  padding: 25px;
  word-break: break-word;

  ${({ isLongHtmlContent }) =>
    isLongHtmlContent &&
    `
      height: 150px;
      overflow: hidden;
      cursor: pointer;
    `}
`;

const StyledChevronWrapper = styled.div<{
  isLongHtmlContent: boolean;
}>`
  display: flex;
  height: min-content;

  ${({ isLongHtmlContent }) =>
    !isLongHtmlContent &&
    `
      cursor: pointer;
      svg {
       transform: rotate(180deg);
      }
    `}
`;

const StyledRichTextWrapper = styled.div<{
  isLongHtmlContent: boolean;
  color: string;
}>`
  ${({ isLongHtmlContent, color }) =>
    isLongHtmlContent &&
    `
      height: 126px;
      -webkit-mask-image: linear-gradient(180deg, ${color} 60%, transparent);
      mask-image: linear-gradient(180deg, ${color} 60%, transparent);

      &:hover {
        -webkit-mask-image: linear-gradient(180deg, ${color} 100%, transparent);
        mask-image: linear-gradient(180deg, ${color} 100%, transparent);
      }
    `}
`;
