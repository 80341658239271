import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import PublicEnv from 'shared/utils/public-env';

export function initializeRum(currentVersion) {
  const sharedConfig = {
    applicationId: '9d60d694-ced3-417c-995b-dd454c454e4a',
    clientToken: 'pub6f5efdaab22141c2cd59685f6a22f6cb',
    env: PublicEnv.appEnv,
    service: 'ecomm-admin-react',
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    version: currentVersion,
    allowedTracingOrigins: [/https:\/\/.*\.dutchie\.com/],
  };

  if (PublicEnv.appEnv === 'production') {
    datadogRum.init({
      ...sharedConfig,
      sessionReplaySampleRate: 0,
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
    });

    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      ...sharedConfig,
      forwardErrorsToLogs: false,
    });
  }
}
