import { useParams } from 'react-router-dom';

import {
  GetMenuSectionsDocument,
  useUpdateMenuSectionsMutation,
  UpdateMenuSectionsMutationHookResult,
} from 'types/graphql';

import { useStores } from 'src/hooks/use-stores';

export function useUpdateMenuSections(): UpdateMenuSectionsMutationHookResult[0] {
  const { id: dispensaryId } = useParams<{ id: string }>();
  const { apolloClient } = useStores();

  const [handleUpdate] = useUpdateMenuSectionsMutation({
    client: apolloClient,
    refetchQueries: [
      {
        query: GetMenuSectionsDocument,
        variables: {
          dispensaryId,
        },
      },
    ],
  });

  return handleUpdate;
}
