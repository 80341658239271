import React from 'react';

import { FullPageLoader } from 'shared/components/loading';
import { useGetMenuCustomization } from '../data-access';

import { HomepageForm } from './homepage-form';

export function Homepage(): JSX.Element | null {
  const { data, loading } = useGetMenuCustomization();

  if (loading) {
    return <FullPageLoader />;
  }

  if (data) {
    return <HomepageForm data={data} />;
  }

  return null;
}
