import { useParams } from 'react-router-dom';
import { appState } from 'src/state/app';
import { EnterpriseJSONFeatureFlag } from 'shared/helpers/types';
import {
  GqlEnterpriseSpecialsSettings,
  useGetEnterpriseQuery,
  useGetEnterpriseByNameQuery,
  useGetEnterpriseSpecialsSettingsQuery,
} from 'types/graphql';

import useStores from 'shared/hooks/use-stores';
import flagIsEnabledForEnterprise from 'shared/utils/flag-is-enabled-for-enterprise';

type PossibleParams = {
  chainId?: string;
  dispensaryId?: string;
  enterpriseId?: string;
};

type UseEnterpriseResult = {
  billingVersion?: number;
  id: string;
  loading: boolean;
  uniqueName?: string;
  enterpriseSpecialsSettings?: GqlEnterpriseSpecialsSettings;
};

const useEnterprise = (): UseEnterpriseResult => {
  const { apolloClient, UI, User } = useStores();
  const { chainId, enterpriseId: enterpriseIdParam } = useParams<PossibleParams>();
  const defaultResult = { billingVersion: undefined, id: User.enterpriseId, loading: true, uniqueName: undefined };
  const enterpriseId = enterpriseIdParam ?? User.enterpriseId ?? UI.dispensary.retailer?.enterprise?.id;
  const enterpriseSpecialsSettingsEnabled = flagIsEnabledForEnterprise(
    'rollout.enterprise-specials-settings',
    appState.FeatureFlags.flags as Record<string, EnterpriseJSONFeatureFlag>,
    enterpriseId
  );

  const skipGetEnterpriseByIdQuery = !enterpriseId;
  const skipGetEnterpriseByNameQuery = !chainId || !User.isSuperAdmin;

  const enterpriseByIdResult = useGetEnterpriseQuery({
    client: apolloClient,
    skip: skipGetEnterpriseByIdQuery,
    variables: { enterpriseId },
  });

  const enterpriseByNameResult = useGetEnterpriseByNameQuery({
    client: apolloClient,
    skip: skipGetEnterpriseByNameQuery,
    variables: { uniqueName: chainId ?? '' },
  });

  const byIdResult = enterpriseByIdResult.data?.getEnterprise;
  const byNameResult = enterpriseByNameResult.data?.getEnterpriseByName?.[0];

  const enterpriseSettingsResult = useGetEnterpriseSpecialsSettingsQuery({
    client: apolloClient,
    fetchPolicy: 'network-only',
    skip: !(byIdResult?.id ?? byNameResult?.id ?? enterpriseId) || !enterpriseSpecialsSettingsEnabled,
    variables: {
      enterpriseId: byIdResult?.id ?? byNameResult?.id ?? enterpriseId,
    },
  });

  const enterpriseSpecialsSettings = enterpriseSettingsResult.data?.getEnterpriseSpecialsSettings ?? {};
  const loading = [enterpriseByIdResult.loading, enterpriseByNameResult.loading, enterpriseSettingsResult.loading].some(
    (val) => !!val
  );

  return {
    billingVersion: byIdResult?.billingVersion ?? byNameResult?.billingVersion ?? defaultResult.billingVersion,
    id: byIdResult?.id ?? byNameResult?.id ?? enterpriseId ?? defaultResult.id,
    loading,
    uniqueName: byIdResult?.uniqueName ?? byNameResult?.uniqueName ?? defaultResult.uniqueName,
    enterpriseSpecialsSettings,
  };
};

export default useEnterprise;
