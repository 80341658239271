import React from 'react';

export function BrowserIcon(): JSX.Element {
  return (
    <svg width='53' height='46' viewBox='0 0 53 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50.1759 0.0249939H2.8241C1.3979 0.0249939 0.237793 1.18511 0.237793 2.6113V42.865C0.237793 44.2908 1.3979 45.4513 2.8241 45.4513H50.1759C51.6021 45.4513 52.7622 44.2908 52.7622 42.865V2.6113C52.7622 1.18511 51.6021 0.0249939 50.1759 0.0249939ZM49.6846 3.1026V11.227H3.3154V3.1026H49.6846ZM3.3154 42.3733V14.3046H49.6846V42.3733H3.3154Z'
        fill='#A4B2C1'
      />
      <path
        d='M7.69115 29.278C7.07861 29.278 6.58148 28.9218 6.58148 28.4812V25.656C6.58148 25.2154 7.07861 24.8593 7.69115 24.8593C8.30369 24.8593 8.80082 25.2154 8.80082 25.656V28.4812C8.80082 28.9218 8.30369 29.278 7.69115 29.278Z'
        fill='#A4B2C1'
      />
      <path
        d='M41.3089 22.1181H38.9705C38.5307 22.1181 38.1738 21.6221 38.1738 21.0084C38.1738 20.3948 38.5307 19.8988 38.9705 19.8988H41.3089C41.7487 19.8988 42.1057 20.3948 42.1057 21.0084C42.1057 21.6221 41.7487 22.1181 41.3089 22.1181ZM35.8529 22.1181H33.5145C33.0747 22.1181 32.7177 21.6221 32.7177 21.0084C32.7177 20.3948 33.0747 19.8988 33.5145 19.8988H35.8529C36.2927 19.8988 36.6496 20.3948 36.6496 21.0084C36.6496 21.6221 36.2935 22.1181 35.8529 22.1181ZM30.3968 22.1181H28.0584C27.6186 22.1181 27.2617 21.6221 27.2617 21.0084C27.2617 20.3948 27.6186 19.8988 28.0584 19.8988H30.3968C30.8366 19.8988 31.1936 20.3948 31.1936 21.0084C31.1936 21.6221 30.8366 22.1181 30.3968 22.1181ZM24.9408 22.1181H22.6023C22.1625 22.1181 21.8056 21.6221 21.8056 21.0084C21.8056 20.3948 22.1625 19.8988 22.6023 19.8988H24.9408C25.3806 19.8988 25.7375 20.3948 25.7375 21.0084C25.7375 21.6221 25.3814 22.1181 24.9408 22.1181ZM19.4855 22.1181H17.1471C16.7073 22.1181 16.3503 21.6221 16.3503 21.0084C16.3503 20.3948 16.7073 19.8988 17.1471 19.8988H19.4855C19.9253 19.8988 20.2822 20.3948 20.2822 21.0084C20.2822 21.6221 19.9253 22.1181 19.4855 22.1181ZM14.0294 22.1181H11.691C11.2512 22.1181 10.8943 21.6221 10.8943 21.0084C10.8943 20.3948 11.2512 19.8988 11.691 19.8988H14.0294C14.4692 19.8988 14.8262 20.3948 14.8262 21.0084C14.8262 21.6221 14.4692 22.1181 14.0294 22.1181Z'
        fill='#A4B2C1'
      />
      <path
        d='M45.5501 23.8824C44.9782 23.8824 44.5141 23.3737 44.5141 22.7443V22.1752H43.9961C43.4242 22.1752 42.9601 21.6664 42.9601 21.037C42.9601 20.4075 43.4242 19.8988 43.9961 19.8988H45.5501C46.1219 19.8988 46.586 20.4075 46.586 21.037V22.7443C46.586 23.3737 46.1219 23.8824 45.5501 23.8824Z'
        fill='#A4B2C1'
      />
      <path
        d='M7.61749 23.8824C8.18935 23.8824 8.65348 23.3737 8.65348 22.7443V22.1752H9.17147C9.74334 22.1752 10.2075 21.6664 10.2075 21.037C10.2075 20.4075 9.74334 19.8988 9.17147 19.8988H7.61749C7.04562 19.8988 6.5815 20.4075 6.5815 21.037V22.7443C6.5815 23.3737 7.04562 23.8824 7.61749 23.8824Z'
        fill='#A4B2C1'
      />
      <path
        d='M45.4733 30.5099C44.8503 30.5099 44.3447 31.0096 44.3447 31.6279V32.5463H43.7804C43.1575 32.5463 42.6519 33.046 42.6519 33.6643C42.6519 34.2826 43.1575 34.7823 43.7804 34.7823H45.4733C46.0963 34.7823 46.6019 34.2826 46.6019 33.6643V31.6279C46.6019 31.0096 46.0963 30.5099 45.4733 30.5099Z'
        fill='#A4B2C1'
      />
      <path
        d='M7.61749 30.8822C8.18935 30.8822 8.65348 31.391 8.65348 32.0204V32.5895H9.17147C9.74334 32.5895 10.2075 33.0983 10.2075 33.7277C10.2075 34.3571 9.74334 34.8659 9.17147 34.8659H7.61749C7.04562 34.8659 6.5815 34.3571 6.5815 33.7277V32.0204C6.5815 31.391 7.04562 30.8822 7.61749 30.8822Z'
        fill='#A4B2C1'
      />
      <path
        d='M45.4764 29.2788C44.8638 29.2788 44.3667 28.9226 44.3667 28.482V25.6568C44.3667 25.2162 44.8638 24.86 45.4764 24.86C46.0889 24.86 46.586 25.2162 46.586 25.6568V28.482C46.586 28.9226 46.0889 29.2788 45.4764 29.2788Z'
        fill='#A4B2C1'
      />
      <path
        d='M41.3089 34.8659H38.9705C38.5307 34.8659 38.1738 34.3699 38.1738 33.7562C38.1738 33.1426 38.5307 32.6466 38.9705 32.6466H41.3089C41.7487 32.6466 42.1057 33.1426 42.1057 33.7562C42.1057 34.3699 41.7487 34.8659 41.3089 34.8659ZM35.8529 34.8659H33.5145C33.0747 34.8659 32.7177 34.3699 32.7177 33.7562C32.7177 33.1426 33.0747 32.6466 33.5145 32.6466H35.8529C36.2927 32.6466 36.6496 33.1426 36.6496 33.7562C36.6496 34.3699 36.2935 34.8659 35.8529 34.8659ZM30.3968 34.8659H28.0584C27.6186 34.8659 27.2617 34.3699 27.2617 33.7562C27.2617 33.1426 27.6186 32.6466 28.0584 32.6466H30.3968C30.8366 32.6466 31.1936 33.1426 31.1936 33.7562C31.1936 34.3699 30.8366 34.8659 30.3968 34.8659ZM24.9408 34.8659H22.6023C22.1625 34.8659 21.8056 34.3699 21.8056 33.7562C21.8056 33.1426 22.1625 32.6466 22.6023 32.6466H24.9408C25.3806 32.6466 25.7375 33.1426 25.7375 33.7562C25.7375 34.3699 25.3814 34.8659 24.9408 34.8659ZM19.4855 34.8659H17.1471C16.7073 34.8659 16.3503 34.3699 16.3503 33.7562C16.3503 33.1426 16.7073 32.6466 17.1471 32.6466H19.4855C19.9253 32.6466 20.2822 33.1426 20.2822 33.7562C20.2822 34.3699 19.9253 34.8659 19.4855 34.8659ZM14.0294 34.8659H11.691C11.2512 34.8659 10.8943 34.3699 10.8943 33.7562C10.8943 33.1426 11.2512 32.6466 11.691 32.6466H14.0294C14.4692 32.6466 14.8262 33.1426 14.8262 33.7562C14.8262 34.3699 14.4692 34.8659 14.0294 34.8659Z'
        fill='#A4B2C1'
      />
      <path
        d='M46.3449 7.16481C46.3449 8.2572 45.4593 9.14282 44.3665 9.14282C43.2741 9.14282 42.3885 8.2572 42.3885 7.16481C42.3885 6.07243 43.2741 5.18681 44.3665 5.18681C45.4593 5.18681 46.3449 6.07243 46.3449 7.16481Z'
        fill='#A4B2C1'
      />
      <path
        d='M40.602 7.16481C40.602 8.2572 39.7164 9.14282 38.624 9.14282C37.5312 9.14282 36.6456 8.2572 36.6456 7.16481C36.6456 6.07243 37.5312 5.18681 38.624 5.18681C39.7164 5.18681 40.602 6.07243 40.602 7.16481Z'
        fill='#A4B2C1'
      />
      <path
        d='M34.8592 7.16481C34.8592 8.2572 33.9736 9.14282 32.8808 9.14282C31.7884 9.14282 30.9028 8.2572 30.9028 7.16481C30.9028 6.07243 31.7884 5.18681 32.8808 5.18681C33.9736 5.18681 34.8592 6.07243 34.8592 7.16481Z'
        fill='#A4B2C1'
      />
    </svg>
  );
}
