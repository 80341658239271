import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

export const changePasswordWhileLoggedInSchema = yup.object({
  oldPass: yup
    .string()
    .test(
      'differentFromNewPass',
      'New password must be different than your current password',
      function testNewPass(value) {
        const ref = yup.ref('newPass');
        return value !== this.resolve(ref);
      }
    )
    .required('Please enter your current password'),
  newPass: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .test(
      'differentFromNewPass',
      'New password must be different than your current password',
      function testOldPass(value) {
        const ref = yup.ref('oldPass');
        return value !== this.resolve(ref);
      }
    )
    .required('Please enter a new password'),
  confirmPass: yup
    .string()
    .oneOf([yup.ref('newPass')], 'New passwords must match')
    .required('Please confirm your new password'),
});

export type ChangePasswordWhileLoggedInFormInput = yup.InferType<typeof changePasswordWhileLoggedInSchema>;

export const changePasswordWithTokenSchema = yup.object({
  newPass: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .test(
      'differentFromNewPass',
      'New password must be different than your current password',
      function testOldPassToken(value) {
        const ref = yup.ref('oldPass');
        return value !== this.resolve(ref);
      }
    )
    .required('Please enter a new password'),
  confirmPass: yup
    .string()
    .oneOf([yup.ref('newPass')], 'New passwords must match')
    .required('Please confirm your new password'),
});

export type ChangePasswordWithTokenFormInput = yup.InferType<typeof changePasswordWithTokenSchema>;

export const forgotPasswordEmailResetSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter your email address')
    .test(
      'isEmail',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      (msg) => `${msg.value} is not a valid email address`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Please enter a valid email address'))
    ),
});
export type ForgotPasswordEmailResetFormInput = yup.InferType<typeof forgotPasswordEmailResetSchema>;

export const loginSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter your email address')
    .test(
      'isEmail',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      (msg) => `${msg.value} is not a valid email address`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Please enter a valid email address'))
    ),
  password: yup.string().required('Please enter your password'),
});

export type LoginFormInput = yup.InferType<typeof loginSchema>;
export const setPasswordFromInviteSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .test(
      'differentFromNewPass',
      'New password must be different than your current password',
      function testOldPassInvite(value) {
        const ref = yup.ref('oldPass');
        return value !== this.resolve(ref);
      }
    )
    .required('Please enter a new password'),
  agree: yup.boolean().required('You must agree to the terms and conditions to continue'),
});

export type SetPasswordFromInviteFormInput = yup.InferType<typeof setPasswordFromInviteSchema>;
