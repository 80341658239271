import React, { useState } from 'react';
import styled from 'styled-components';

import { CheckmarkStroke } from 'src/svg/checkmark-stroke';

type ColorPickerProps = {
  colors: string[];
  label: string;
  onSelect: (color: string) => void;
  selectedColor: string;
};

export function ColorPickerV2({ colors, label, onSelect, selectedColor }: ColorPickerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (): void => {
    setIsOpen((value) => !value);
  };

  return (
    <Container>
      <Summary onClick={handleToggle}>
        <Label>{label}</Label>

        {!isOpen && <Swatch $color={selectedColor} />}

        {isOpen && <Close>Close</Close>}
      </Summary>

      {isOpen && (
        <Options>
          {colors.map((color) => {
            const isSelected = color === selectedColor;

            return (
              <Option key={color}>
                <Swatch $color={color} $isSelected={isSelected} onClick={() => onSelect(color)}>
                  {isSelected && <StyledCheckmarkStroke />}
                </Swatch>
              </Option>
            );
          })}
        </Options>
      )}
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d3d8de;
  box-shadow: 0 3px 5px #e9ecf1;
  width: 310px;
`;

const Summary = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 53px;
  justify-content: space-between;
  padding: 19px 20px;
`;

const Label = styled.div`
  color: #4f5d68;
  font-size: 13px;
  font-weight: bold;
  line-height: 23px;
`;

const Swatch = styled.div<{ $color: string; $isSelected?: boolean }>`
  align-items: center;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? `0 0 0 1px #00000099, inset 0 0 0 1px #00000099` : `inset 0 0 0 1px #00000033`};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 23px;
  justify-content: center;
  width: 23px;
`;

const StyledCheckmarkStroke = styled(CheckmarkStroke)`
  path {
    stroke: #00000099;
  }
`;

const Close = styled.div`
  color: #0b99e6;
  cursor: pointer;
  font-size: 13px;
  line-height: 23px;
`;

const Options = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 20px 20px 20px;
  margin: -6px;
`;

const Option = styled.li`
  margin: 6px;
`;
