import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react';

import ErrorIcon from 'shared/components/ernie/error-icon';
import SuccessIcon from 'shared/components/ernie/success-icon';
import InfoIcon from 'shared/components/ernie/info-icon';
import { Container, IconContainer, Message } from 'shared/components/ernie/styled-components';
import { ARIA_ROLES, ERNIE_TIMEOUTS, ERNIE_FADE_TIME } from 'shared/constants';
import useStores from 'shared/hooks/use-stores';

const iconMapping = {
  danger: ErrorIcon,
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
};

const Ernie = observer(({ isIframe = false, iframeOffset = 0, parentOffset = 0 }) => {
  const { UI } = useStores();

  // "end time" for the current operation, tracks both hiding and showing
  const [endTime, setEndTime] = useState(0);
  // whether we're currently hidden or shown
  const [visible, setVisible] = useState(false);
  const [currentAlert, setCurrentAlert] = useState(UI.ernieQueue[0]);

  const { type, msg } = currentAlert ?? {};
  const IconComponent = _.get(iconMapping, type, iconMapping.danger);

  // handle the various timings...
  // we want to show the messages for their `timeout` duration in ms,
  // and then between messages we want to chill with visible=false for ERNIE_FADE_TIME ms
  // plus we need to handle getting re-rendered while maintaining correct timings
  useEffect(() => {
    const now = Date.now();
    // if endTime > 0 then we're either showing a message or in the fade time between
    if (endTime > 0) {
      if (!visible) {
        // in the delay in between messages so we wait, and then clear out state when we get there
        const timeoutId = setTimeout(() => {
          setEndTime(0);
        }, endTime - now);
        return () => clearTimeout(timeoutId);
      }
      // currently showing a message! (re-)schedule the timeout and keep on keeping on
      const timeoutId = setTimeout(() => {
        setCurrentAlert(UI.ernieQueue.shift());
        setVisible(false);
        setEndTime(Date.now() + ERNIE_FADE_TIME);
      }, endTime - now);
      return () => clearTimeout(timeoutId);
    }
    // end time is 0, so we're in an idle state here
    if (_.isEmpty(UI.ernieQueue)) {
      if (visible) {
        // this should never happen but might as well be safe :shrug:
        setVisible(false);
      }
      return null;
    }
    // idle and there's messages in the queue... showing time!
    // all we really need to do is setup the timeout and visible states because that will cause a re-render
    // and the above code will handle registering the timeout for when it's time to hide again
    setCurrentAlert(UI.ernieQueue[0]);
    const messageTimeout = currentAlert?.timeout ?? ERNIE_TIMEOUTS.SHORT;
    setEndTime(now + messageTimeout);
    setVisible(true);
  }, [UI.ernieQueue, UI.ernieQueue.length, currentAlert, endTime, visible]);

  return (
    <Container
      aria-live='assertive'
      aria-label={visible ? 'Alert' : ''}
      aria-labelledby={visible ? 'ernie-message' : ''}
      data-cy='ernie-container'
      data-test='ernie-container'
      data-testid='ernie-container'
      iframeOffset={iframeOffset}
      isIframe={isIframe}
      parentOffset={parentOffset}
      role={visible ? ARIA_ROLES.ALERT : ARIA_ROLES.PRESENTATION}
      type={type}
      visible={visible}
    >
      <IconContainer>
        <IconComponent />
      </IconContainer>

      <Message id='ernie-message' data-cy='ernie-message' data-test='ernie-message' data-testid='ernie-message'>
        <ReactMarkdown children={msg} />
      </Message>
    </Container>
  );
});

export default Ernie;
