import { useObserver } from 'mobx-react-lite';
import { Text } from 'rebass';
import React from 'react';
import styled from 'styled-components';
import { useStores } from 'src/hooks/use-stores';
import { ModalContainer, ModalClose, ModalButton } from 'shared/modals';

export function TextBannerNotificationModal(): JSX.Element | null {
  const { UI } = useStores();
  const { resolve } = UI.textBannerModalObj;

  async function onConfirm(confirmed: boolean): Promise<any> {
    UI.textBannerModalObj.confirmed = confirmed;
    UI.textBannerModalObj.open = false;
    return resolve(confirmed);
  }

  return useObserver(() => {
    if (!UI.textBannerModalObj.open) {
      return null;
    }
    return (
      <ModalContainer width='480px' noPadding isOpen>
        <StyledModalClose onClick={() => onConfirm(false)} />
        <ModalContent>
          <StyledModalHeader>EXPAND TEXT BANNER</StyledModalHeader>
          <StyledBodyWrapper>
            <StyledText>
              Menus that expand the banner by default tend to experience significantly lower conversion rates compared
              to those that collapse by default.
            </StyledText>
            <StyledSpan>Are you sure you want to expand the text banner?</StyledSpan>
          </StyledBodyWrapper>

          <StyledDiv>
            <ModalButton
              width='100%'
              padding='0px 8px'
              buttonContainerWidth='50%'
              onClick={() => {
                void onConfirm(false);
              }}
            >
              No, keep collapsed
            </ModalButton>
            <ModalButton
              width='100%'
              buttonContainerWidth='50%'
              inverted
              onClick={() => {
                void onConfirm(true);
              }}
            >
              Yes, expand text banner
            </ModalButton>
          </StyledDiv>
        </ModalContent>
      </ModalContainer>
    );
  });
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 22px;
  text-align: center;
`;

const StyledModalHeader = styled.h3`
  color: #47494c;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin-top: 10px;
  padding-bottom: 18px;
`;

const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StyledText = styled(Text)`
  color: #47494c;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin: 0;
`;

const StyledSpan = styled.span`
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
`;

const StyledDiv = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const StyledModalClose = styled(ModalClose)`
  &:before,
  &:after {
    background-color: #000;
  }
`;
