/* eslint-disable @typescript-eslint/naming-convention */
import { MenuSectionType } from 'types/graphql';
import { MenuSectionOption, Section } from './homepage-form.types';

export const PRODUCT_LIMIT = 25;

const didNotFindSectionByType = (menuSections: Section[], type: MenuSectionType): boolean =>
  !menuSections.find((menuSection) => menuSection.sectionType === type);

export const defaultSectionOptions: MenuSectionOption[] = [
  {
    key: MenuSectionType.category,
    title: 'Popular Category',
    tooltip: 'Display a section of the most popular items in a top level category, for example “Popular Edibles”.',
    visible: true,
  },
  {
    key: MenuSectionType.subcategory,
    title: 'Popular Subcategory',
    tooltip: 'Display a section of the most popular items in a subcategory, for example “Popular Gummies”.',
    visible: true,
  },
  {
    key: MenuSectionType.brand,
    title: 'Brand',
    tooltip: 'Display a section of the most popular items for the selected brand.',
    visible: true,
  },
  {
    key: MenuSectionType.custom,
    title: 'Custom',
    tooltip: 'Display a section that shows selected products from your menu.',
    visible: true,
  },
];

export const dynamicSectionOptions: MenuSectionOption[] = [
  {
    key: MenuSectionType.specials,
    title: 'Items on Sale',
    tooltip: 'Display a section that shows items that are currently on special.',
    visible: (menuSections: Section[]) => didNotFindSectionByType(menuSections, MenuSectionType.specials),
  },
  {
    key: MenuSectionType.staffPicks,
    title: 'Staff Picks',
    tooltip: 'Display a section that shows items that are currently selected as Staff Picks.',
    visible: (menuSections: Section[]) => didNotFindSectionByType(menuSections, MenuSectionType.staffPicks),
  },
  {
    key: MenuSectionType.topSellers,
    title: 'Top Sellers',
    tooltip: 'Display a section that shows the top selling items on your entire menu.',
    visible: (menuSections: Section[]) => didNotFindSectionByType(menuSections, MenuSectionType.topSellers),
  },
  {
    key: MenuSectionType.offers,
    title: 'Offers',
    tooltip: 'Display a section of your promotional Offers.',
    visible: (menuSections: Section[]) => didNotFindSectionByType(menuSections, MenuSectionType.offers),
  },
];

export const allSectionOptions = [...defaultSectionOptions, ...dynamicSectionOptions];

export const DYNAMIC_LABEL_MAP = {
  [MenuSectionType.offers]: 'Offers',
  [MenuSectionType.specials]: 'Items on Sale',
  [MenuSectionType.staffPicks]: 'Staff Picks',
  [MenuSectionType.topSellers]: 'Top Sellers',
};

export const DEFAULT_SECTIONS = [
  MenuSectionType.category,
  MenuSectionType.subcategory,
  MenuSectionType.brand,
  MenuSectionType.custom,
];

export const DYNAMIC_SECTIONS = [
  MenuSectionType.offers,
  MenuSectionType.specials,
  MenuSectionType.staffPicks,
  MenuSectionType.topSellers,
];
