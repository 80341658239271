import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { Preview, PreviewData } from './preview';

enum PreviewComponent {
  homePage = 'homepage',
  productDetailsPage = 'productDetailsPage',
}

type PreviewSwitcherProps = {
  previewData: PreviewData;
};

export const PreviewSwitcher = ({ previewData }: PreviewSwitcherProps): JSX.Element => {
  const [activePreview, setActivePreview] = useState(PreviewComponent.homePage);
  const isHomepagePreviewVisible = activePreview === PreviewComponent.homePage;

  return (
    <>
      <ButtonContainer>
        <Button onClick={() => setActivePreview(PreviewComponent.homePage)} $isActive={isHomepagePreviewVisible}>
          Homepage
        </Button>
        <Button
          onClick={() => setActivePreview(PreviewComponent.productDetailsPage)}
          $isActive={!isHomepagePreviewVisible}
        >
          Product Page
        </Button>
      </ButtonContainer>

      {isHomepagePreviewVisible ? (
        <Preview.HomePage data={previewData} />
      ) : (
        <Preview.ProductDetailsPage data={previewData} />
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const Button = styled.button<{ $isActive: boolean }>`
  background: #fff;
  border: 2px solid #e0e6ec;
  color: #42505d;
  font-size: 12px;
  font-weight: 500;
  min-width: 124px;
  padding: 8px;
  text-transform: uppercase;

  &:first-child {
    border-radius: 50px 0 0 50px;
  }

  &:last-child {
    border-radius: 0 50px 50px 0;
  }

  ${({ $isActive }) =>
    $isActive
      ? css`
          background: #e0e6ec;
          color: #2e3538;
          font-weight: 700;
        `
      : css`
          cursor: pointer;
        `}
`;
