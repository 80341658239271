import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Preview } from '../components/preview';
import { MenuCustomizationLayout } from '../components/menu-customization-layout';

import { MenuCustomizationData } from '../data-access';
import { Sections } from './sections';
import { useHomepageForm } from './use-homepage-form';
import { AddSectionModal } from './add-section-modal';

export type HomepageFormProps = {
  data: MenuCustomizationData;
};

export function HomepageForm({ data }: HomepageFormProps): JSX.Element {
  const {
    availableMenuSectionTypes,
    currentSection,
    handleAddDefaultSection,
    handleAddSection,
    handleCloseModal,
    handleDrop,
    handleEditSection,
    handleMoveOption,
    handlePublishSections,
    handleRemoveMenuSection,
    handleResetSections,
    isDirty,
    itemsLoading,
    localMenuSections,
    previewMenuSections,
    products,
    showModalType,
  } = useHomepageForm({
    data,
  });
  return (
    <MenuCustomizationLayout
      headerText='Homepage'
      subHeaderText='Drag and drop to reorder the sections on your homepage.'
      isDirty={isDirty}
      handlePublish={handlePublishSections}
      handleReset={handleResetSections}
      previewComponent={<Preview.HomePage data={{ ...data, menuSections: previewMenuSections }} />}
    >
      <DndProvider backend={HTML5Backend}>
        <Sections
          availableMenuSectionTypes={availableMenuSectionTypes}
          handleAddSection={handleAddSection}
          handleDrop={handleDrop}
          handleEditSection={handleEditSection}
          handleMoveOption={handleMoveOption}
          handleRemoveMenuSection={handleRemoveMenuSection}
          itemsLoading={itemsLoading}
          localMenuSections={localMenuSections}
        />
        {showModalType && (
          <AddSectionModal
            handleAddSection={handleAddDefaultSection}
            menuSection={currentSection}
            menuSections={localMenuSections}
            onToggle={handleCloseModal}
            products={products}
            sectionTypeKey={showModalType}
          />
        )}
      </DndProvider>
    </MenuCustomizationLayout>
  );
}
