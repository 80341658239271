/* eslint-disable lodash/prefer-lodash-typecheck */

import { FeatureFlagValue } from '../helpers/types';
import { isNotNullish } from './type-utils';

/**
 * Launch Darkly is user-centric, so we're using JSON feature flags to enable flagging features by enterprise.
 * This helper checks if an enterprise has a feature enabled, or warns if the value is not an object.
 */
const evaluateEnterpriseFeatureFlag = (
  key: string,
  featureFlagValue: FeatureFlagValue,
  enterpriseId: string
): boolean => {
  if (isNotNullish(featureFlagValue)) {
    if (typeof featureFlagValue === 'object') {
      // Assuming featureFlagValue is an object with optional keys enabledForAll and enabledForEnterprises
      // eslint-disable-next-line no-undef
      const flagObject = featureFlagValue as { enabledForAll?: boolean; enabledForEnterprises?: string[] };

      if (flagObject.enabledForAll) {
        return true;
      }

      return !enterpriseId ? false : flagObject.enabledForEnterprises?.includes(enterpriseId) ?? false;
    }

    if (typeof featureFlagValue === 'boolean') {
      return featureFlagValue;
    }
  }

  return false;
};

export default evaluateEnterpriseFeatureFlag;
