export const ARIA_ROLES = Object.freeze({
  ALERT: 'alert',
  BANNER: 'banner',
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  CONTENT_INFO: 'contentinfo',
  IMG: 'img',
  LINK: 'link',
  MAIN: 'main',
  NAV: 'navigation',
  NONE: 'none',
  PRESENTATION: 'presentation',
  REGION: 'region',
  STATUS: 'status',
  TAB: 'tab',
  TAB_LIST: 'tablist',
  TAB_PANEL: 'tabpanel',
});
