import { useCallback } from 'react';
import type { BaseSchema, ValidationError } from 'yup';

// TODO: `any` type; FIXME
type ValidationResults =
  | {
      values: any;
      errors: any;
    }
  | {
      values: any;
      errors: any;
    };

type YupValidationResolver = (data: any) => Promise<ValidationResults>;

export function useYupValidationResolver(validationSchema: BaseSchema): YupValidationResolver {
  return useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: ValidationError[], currentError: ValidationError) => ({
              ...allErrors,
              [currentError.path ?? 'test']: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
}
