import React from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import useErnie from 'shared/hooks/use-ernie';
import useStores from 'shared/hooks/use-stores';
import { TextInput } from 'shared/components/';
import { mediaQueries } from 'shared/styles';
import { ModalContainer, ModalPrimaryHeader, ModalButton, ElementContainer, Label } from 'shared/modals';
import { useYupValidationResolver } from 'src/hooks/use-yup-resolver';
import { useErnieOnSubmitFormErrors } from 'src/utils/form';
import { changePasswordWithTokenSchema, ChangePasswordWithTokenFormInput } from './schema';

export function ResetPasswordWithToken(): JSX.Element {
  const showErnie = useErnie();
  const history = useHistory();
  const { User, UI } = useStores();
  const tokenFromUrl = useRouteMatch<{ id: string }>('/reset/:id');
  const { handleFormErrors } = useErnieOnSubmitFormErrors();
  const resolver = useYupValidationResolver(changePasswordWithTokenSchema);
  const { control, handleSubmit, formState } = useForm<ChangePasswordWithTokenFormInput>({
    resolver,
    mode: 'onBlur',
    defaultValues: {
      newPass: '',
      confirmPass: '',
    },
  });
  const { isSubmitting } = formState;

  function handleClose(): void {
    history.push('/login');
  }

  async function validateAndSavePassword(data: ChangePasswordWithTokenFormInput): Promise<void> {
    try {
      const validToken = await User.validateResetToken(tokenFromUrl?.params.id);
      if (!validToken) {
        showErnie('Your email link has expired. Please request a new password reset.', 'danger');
        return;
      }
      await savePassword(data);
    } catch (error) {
      showErnie('Something went wrong. Please contact support.', 'danger');
      datadogLogs.logger.error('Error validating reset token', { page: 'ResetPasswordWithToken', error });
    }
  }

  async function savePassword(data: ChangePasswordWithTokenFormInput): Promise<void> {
    try {
      const result = await User.resetPassword(tokenFromUrl?.params.id, data.newPass);
      if (result.success) {
        UI.showResetPasswordWithToken = false;
        showErnie('Your password has been successfully reset!', 'success');
        history.push('/login');
      } else {
        showErnie('Please update your new password and try again.', 'danger');
        datadogLogs.logger.error('Failed password reset', { page: 'ResetPasswordWithToken', error: result.error });
      }
    } catch (error) {
      showErnie('Something went wrong. Please contact support.', 'danger');
      datadogLogs.logger.error('Error resetting password', { page: 'ResetPasswordWithToken', error });
    }
  }

  return (
    <ModalContainer fancy isOpen onRequestClose={handleClose} p='95px 47px 45px 47px'>
      <form onSubmit={handleSubmit(validateAndSavePassword, handleFormErrors)}>
        <ModalPrimaryHeader copy='Change Password' close={handleClose} />
        <StyledElementContainer>
          <StyledLabel>New Password:</StyledLabel>
          <Controller name='newPass' control={control} render={(props) => <TextInput {...props} type='password' />} />
        </StyledElementContainer>
        <StyledElementContainer>
          <StyledLabel>Confirm Password:</StyledLabel>
          <Controller
            name='confirmPass'
            control={control}
            render={(props) => <TextInput {...props} type='password' />}
          />
        </StyledElementContainer>
        <ModalButton
          alignItems='flex-start'
          type='submit'
          loading={isSubmitting}
          width={['100%', '100%', '100%', '151px']}
          mt='37px'
        >
          Save Password
        </ModalButton>
      </form>
    </ModalContainer>
  );
}

const StyledLabel = styled(Label)`
  width: 122px;
  padding-right: 31px;
`;

const StyledElementContainer = styled(ElementContainer)`
  width: 448px;
  @media ${mediaQueries.largePhone} {
    width: 100%;
  }
`;
