import React from 'react';

export default function RadioDisabledIcon(props) {
  return (
    <svg height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none'>
        <circle cx='9' cy='9' fill='#fcfdfe' r='8.5' stroke='#b7bec3' />
      </g>
    </svg>
  );
}
