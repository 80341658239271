import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid';

import { ModalContainer } from 'shared/modals';
import { Button } from 'shared/components';
import { usePromotionPopUp } from './use-promotion-popup';

// manually create a MVP promotion banner for Dutchie Pay
export const dutchiePayImageBanner = {
  _id: uuid.v4(),
  image:
    'https://images.dutchie.com/def6ffcd9ca63c76f1443f94bc902b45?ar=3%3A1&auto=format&fit=crop&fill=solid&fillColor=%23fff&ixlib=react-9.0.2&w=4088',
  mobileImage:
    'https://images.dutchie.com/4f427f4f43c7bc586c7034898b91a85d?ar=2.22%3A1&auto=format&fit=crop&fill=solid&fillColor=%23fff&ixlib=react-9.0.2&w=1446',
  alt: 'Dutchie Pay - Cannabis Payment Processing',
  link: '',
  position: 0,
};

export function PromotionPopup(): JSX.Element {
  const { isOpen, handleClose, seenPromotionModal, enableBanner } = usePromotionPopUp();

  return (
    <PromotionModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      width='620px'
      fancy
      alignItems='center'
      pt='90px'
      ariaHideApp={false}
    >
      {/* TODO: update the image asset from design */}
      <Image src='https://images.dutchie.com/0dce9fd5198325be3d4e6212139ea929' />
      <ModalTitle>Enable the Dutchie Pay banner to increase basket sizes by up to 20%</ModalTitle>
      <ModalContent>
        Click the button below to enable the Dutchie Pay banner on your menu. Our research shows that this banner drives
        significantly higher usage on Dutchie Pay, leading to larger average order values and more revenue for your
        store.
      </ModalContent>
      <StyledButton onClick={enableBanner} disabled={false}>
        ENABLE BANNER
      </StyledButton>
      <TextButton onClick={seenPromotionModal}>No Thanks</TextButton>
    </PromotionModal>
  );
}

const PromotionModal = styled(ModalContainer)`
  padding: 16px 16px 40px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 580px;
`;

const ModalTitle = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #393d40;
  max-width: 380px;
  margin-bottom: 16px;
`;

const ModalContent = styled.p`
  line-height: 165%;
  color: #5d666d;
  max-width: 380px;
`;

const StyledButton = styled(Button)`
  padding: 0 24px;
  margin: 20px 0;
`;

const TextButton = styled.span`
  line-height: 120%;
  color: #0b99e6;
  cursor: pointer;
`;
