import React, { RefObject, useCallback } from 'react';

import { menuBannerColors } from '../../../helpers';

import { Preview } from '../preview';
import { PreviewData } from '../preview.types';

import { NavBar } from '../navbar';
import { MenuBanner } from './menu-banner';
import { MenuImageBanner } from './menu-image-banner';
import { Categories } from './categories';
import { Carousels } from './carousels';
import { Disclaimer } from './disclaimer';

type HomePageProps = {
  data: PreviewData;
  activeSection?: 'categories' | 'disclaimer' | 'homepage' | 'textBanner';
};

type UseHomePageReturn = {
  menuBannerBackgroundColor: string;
};

function useHomePage(data: PreviewData): UseHomePageReturn {
  const menuBannerBackgroundColor = menuBannerColors[data.menuBannerColor].background;

  return {
    menuBannerBackgroundColor,
  };
}

export function HomePage({ data, activeSection }: HomePageProps): JSX.Element {
  const { menuBannerBackgroundColor } = useHomePage(data);
  const {
    menuBannerHtml,
    disclaimerTextHtml,
    menuSections,
    categories,
    imageBanners,
    menuBannerTextBannerCollapsed,
  } = data;

  const scrollIntoView = useCallback((componentRef: RefObject<HTMLElement>): void => {
    const previouslyFocused = document.activeElement;
    componentRef.current?.focus();

    if (previouslyFocused instanceof HTMLElement) {
      previouslyFocused.focus();
    }
  }, []);

  return (
    <Preview data={data}>
      <NavBar />

      {!!imageBanners?.length && <MenuImageBanner banners={imageBanners} />}

      {menuBannerHtml && (
        <MenuBanner
          bannerColor={menuBannerBackgroundColor}
          bannerHtml={menuBannerHtml}
          bannerCollapsed={menuBannerTextBannerCollapsed}
          shouldScrollInView={activeSection === 'textBanner'}
          scrollIntoView={scrollIntoView}
        />
      )}

      <Categories categories={categories} />

      <Carousels menuSections={menuSections} />

      {disclaimerTextHtml && <Disclaimer disclaimerHtml={disclaimerTextHtml} />}
    </Preview>
  );
}
