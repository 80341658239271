export default {
  Flower: [
    'https://images.dutchie.com/category-stock-photos/flower/flower-1.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-2.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-3.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-4.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-5.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-6.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-7.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-8.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-9.png',
    'https://images.dutchie.com/category-stock-photos/flower/flower-10.png',
  ],
  'Pre-Rolls': [
    'https://images.dutchie.com/category-stock-photos/pre-rolls/pre-rolls-1.png',
    'https://images.dutchie.com/category-stock-photos/pre-rolls/pre-rolls-2.png',
    'https://images.dutchie.com/category-stock-photos/pre-rolls/pre-rolls-3.png',
  ],
  Vaporizers: [
    'https://images.dutchie.com/category-stock-photos/vaporizers/vaporizers-1.png',
    'https://images.dutchie.com/category-stock-photos/vaporizers/vaporizers-2.png',
    'https://images.dutchie.com/category-stock-photos/vaporizers/vaporizers-3.png',
  ],
  Concentrate: [
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-1.png',
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-2.png',
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-3.png',
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-4.png',
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-5.png',
    'https://images.dutchie.com/category-stock-photos/concentrates/concentrates-6.png',
  ],
  Edible: [
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-1.png',
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-gummies.png',
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-3.png',
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-4.png',
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-5.png',
    'https://images.dutchie.com/category-stock-photos/edibles/edibles-6.png',
  ],
  Oral: ['http://images.dutchie.com/category-stock-photos/orals/orals-1.png'],
  Tincture: [
    'https://images.dutchie.com/category-stock-photos/tinctures/tinctures-1.png',
    'https://images.dutchie.com/category-stock-photos/tinctures/tinctures-2.png',
    'https://images.dutchie.com/category-stock-photos/tinctures/tinctures-3.png',
  ],
  Topicals: [
    'https://images.dutchie.com/category-stock-photos/topicals/topicals-1.png',
    'https://images.dutchie.com/category-stock-photos/topicals/topicals-2.png',
  ],
  CBD: ['https://images.dutchie.com/category-stock-photos/cbd/cbd.png'],
  Seeds: ['https://images.dutchie.com/category-stock-photos/seeds/seeds.png'],
  Clones: ['https://images.dutchie.com/category-stock-photos/clones/clones.png'],
  Accessories: ['https://images.dutchie.com/category-stock-photos/accessories/accessories.png'],
  Apparel: ['https://images.dutchie.com/category-stock-photos/apparel/apparel.png'],
};
