type GetDegradedPollIntervalParams = {
  flags: any;
  milliseconds: number;
  degradationMultiplier: number;
};

export function getDegradedPollInterval(params: GetDegradedPollIntervalParams): number {
  const { flags, milliseconds, degradationMultiplier } = params;
  const increasePollInterval = !!flags[`ecomm.configuration.significiantly-increase-poll-interval`];
  const degradedPollInterval = increasePollInterval ? milliseconds * degradationMultiplier : milliseconds;

  return degradedPollInterval;
}
