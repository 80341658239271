import React from 'react';
import styled from 'styled-components';
import { MenuSectionType } from 'types/graphql';
import { SmallLoader } from 'shared/components';
import { SmallOption } from './components/small-option';
import { DraggableOption } from './components/draggable-option';
import { MenuSectionOption, LocalSection } from '../homepage-form.types';

type SectionsProps = {
  availableMenuSectionTypes: MenuSectionOption[];
  handleAddSection: (sectionTypeKey: MenuSectionType) => void;
  handleDrop: () => void;
  handleEditSection: (id: string, type: MenuSectionType) => void;
  handleMoveOption: (dragIndex: number, hoverIndex: number) => void;
  handleRemoveMenuSection: (menuSectionId: string) => void;
  itemsLoading: boolean;
  localMenuSections: LocalSection[] | null;
};

export function Sections({
  availableMenuSectionTypes,
  handleAddSection,
  handleDrop,
  handleEditSection,
  handleMoveOption,
  handleRemoveMenuSection,
  itemsLoading,
  localMenuSections,
}: SectionsProps): JSX.Element {
  return (
    <Content>
      <AvailableSections data-cy='available-sections' data-test='available-sections'>
        <SmallTitle>Add Sections</SmallTitle>
        {availableMenuSectionTypes.map((sectionType: MenuSectionOption) => (
          <SmallOption
            key={sectionType.key}
            sectionKey={sectionType.key.toLowerCase()}
            onAddSection={() => handleAddSection(sectionType.key)}
            tooltipCopy={sectionType.tooltip}
            title={sectionType.title}
          />
        ))}
      </AvailableSections>
      {itemsLoading ? (
        <SmallLoader />
      ) : (
        localMenuSections?.map((menuSection, index) => (
          <DraggableOption
            key={menuSection.id}
            index={index}
            menuSection={menuSection}
            onDrop={handleDrop}
            onMove={handleMoveOption}
            onRemove={handleRemoveMenuSection}
            onEdit={handleEditSection}
            title={menuSection.label}
          />
        ))
      )}
    </Content>
  );
}

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AvailableSections = styled.div`
  width: 426px;
  border-radius: 8px;
  border: 1px dashed #969ea5;
  display: grid;
  grid-template-columns: 182px 202px;
  margin-bottom: 24px;
  padding: 16px 0 16px 16px;
`;

const SmallTitle = styled.div`
  color: #4f5d68;
  font-size: 14px;
  font-weight: bold;
  grid-column-end: span 2;
  padding-left: 16px;
  margin-bottom: 8px;
`;
