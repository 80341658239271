import React from 'react';
import styled from 'styled-components';

import { Select } from 'shared/components';
import { ModalContainer, ModalButton, ModalSecondaryHeader, ModalClose, ModalCopy } from 'shared/modals';
import { MenuSectionType } from 'types/graphql';

import { LocalSection, Products } from '../../homepage-form.types';
import { useAddCategoryModal } from './use-add-category-modal';

export type AddCategoryModalProps = {
  handleAddSection: (newSection: LocalSection) => void;
  menuSections: LocalSection[] | null;
  onToggle: () => void;
  sectionTypeKey: MenuSectionType | null;
  products: Products | null;
};

export function AddCategoryModal(props: AddCategoryModalProps): JSX.Element {
  const { onToggle } = props;

  const {
    addingCategory,
    allowAdd,
    handleChangeCategory,
    handleChangeSubcategory,
    handleSave,
    selectedCategory,
    selectedSubcategory,
    visibleCategoryOptions,
    visibleSubcategoryOptions,
  } = useAddCategoryModal(props);

  return (
    <ModalContainer isOpen padding='30px 57px 52px' width='462px'>
      <ModalSecondaryHeader lowercase>Add Popular {addingCategory ? 'Category' : 'Subcategory'}</ModalSecondaryHeader>
      <Copy>Add a {addingCategory ? 'category' : 'subcategory'} carousel to your embedded homepage.</Copy>

      <Select fontSize='13px' height={45} onChange={handleChangeCategory} value={selectedCategory?.key} width='326px'>
        <option value=''>Select a category</option>
        {visibleCategoryOptions?.map((categoryOption) => (
          <option key={categoryOption.key} value={categoryOption.key}>
            {categoryOption.value}
          </option>
        ))}
      </Select>

      {!addingCategory && (
        <Select
          disabled={!selectedCategory}
          fontSize='13px'
          height={45}
          mt='15px'
          onChange={handleChangeSubcategory}
          value={selectedSubcategory?.value}
          width='326px'
        >
          <option value=''>Select a subcategory</option>
          {visibleSubcategoryOptions?.map((subcategoryOption) => (
            <option key={subcategoryOption.key} value={subcategoryOption.value ?? ''}>
              {subcategoryOption.label}
            </option>
          ))}
        </Select>
      )}

      <ModalButton mt='30px' disabled={!allowAdd} width='82px' onClick={handleSave}>
        Add
      </ModalButton>

      <ModalClose onClick={onToggle} />
    </ModalContainer>
  );
}

const Copy = styled(ModalCopy)`
  margin-bottom: 24px;
`;
