import React, { SVGAttributes } from 'react';

export function Logo(props: SVGAttributes<unknown>): JSX.Element {
  const { fill } = props;
  return (
    <svg
      width='134'
      height='32'
      viewBox='0 0 134 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      aria-describedby='dutchie'
    >
      <path
        d='M28.4051 22.4996C26.8189 23.3732 25.0957 24.0283 23.4411 24.4155C21.5515 24.8523 19.6129 25.0012 17.6841 24.8523C14.4433 24.5743 11.3004 23.0654 9.13665 20.5837C8.87229 20.2859 8.65689 19.9186 8.24567 19.8193C7.58969 19.6704 7.12952 20.127 6.97287 20.7326C5.37696 26.7384 10.8011 32.4663 16.7441 31.9699C22.0899 31.6423 27.0245 28.4756 29.58 23.7008C29.7366 23.4228 29.7268 23.1051 29.5702 22.8371C29.3352 22.4202 28.8163 22.2613 28.4051 22.4996Z'
        fill={fill ?? '#FF3E51'}
      />
      <path
        d='M15.0308 1.63307C16.5679 2.59599 17.9974 3.7773 19.1527 5.03803C20.4745 6.47744 21.5711 8.10547 22.4131 9.87247C23.7936 12.8605 24.0677 16.3647 23.0397 19.5116C22.9124 19.8987 22.7068 20.266 22.8341 20.673C23.0397 21.3183 23.6467 21.497 24.2537 21.3381C30.187 19.73 32.3606 12.116 28.9632 7.14255C26.0063 2.60592 20.8367 -0.13393 15.4811 0.00504727C15.1678 0.0149743 14.8937 0.173808 14.7468 0.441837C14.5021 0.848843 14.6293 1.39482 15.0308 1.63307Z'
        fill={fill ?? '#FF3E51'}
      />
      <path
        d='M16.4504 11.4012C15.2461 11.2225 14.0321 11.5203 13.053 12.2549C12.5439 12.6321 12.1228 13.1086 11.8095 13.6645C10.899 15.2627 11.0067 17.2581 12.0935 18.7372C12.818 19.7299 13.8852 20.3851 15.0895 20.5638C16.2938 20.7424 17.5078 20.4446 18.4869 19.71C18.996 19.3328 19.417 18.8563 19.7303 18.3004C20.6409 16.7022 20.5332 14.7068 19.4464 13.2277C18.7317 12.235 17.6645 11.5798 16.4504 11.4012Z'
        fill={fill ?? '#FF3E51'}
      />
      <path
        d='M15.1678 7.84733C15.5595 7.76792 15.9805 7.75799 16.2644 7.45025C16.7148 6.94398 16.5679 6.31858 16.1175 5.87186C11.7802 1.46428 4.18248 3.37027 1.63686 8.84003C-0.752113 13.7043 -0.517128 19.6108 2.28306 24.2467C2.4495 24.5247 2.71385 24.6736 3.02716 24.6736C3.49712 24.6736 3.88876 24.2963 3.88876 23.8198C3.93771 21.9933 4.23144 20.1469 4.73077 18.499C5.29864 16.6228 6.14065 14.8459 7.23723 13.2178C9.10728 10.5078 11.9662 8.51244 15.1678 7.84733Z'
        fill={fill ?? '#FF3E51'}
      />
      <path
        d='M39.1848 17.1796C39.1848 12.5735 41.8088 9.40676 46.2049 9.40676C48.0651 9.40676 49.6512 10.1811 50.5128 11.3227V3.98663C50.5128 3.74838 50.6989 3.55977 50.9338 3.55977H53.7928C54.0278 3.55977 54.2138 3.74838 54.2138 3.98663V24.2873C54.2138 24.5256 54.0278 24.7142 53.7928 24.7142H50.9338C50.6989 24.7142 50.5128 24.5256 50.5128 24.2873V22.9869C49.7785 24.1781 48.1239 24.9623 46.2636 24.9623C41.8088 24.9524 39.1848 21.7857 39.1848 17.1796ZM50.738 17.1796C50.738 14.4596 49.1715 12.6628 46.7825 12.6628C44.4229 12.6628 42.8564 14.4596 42.8564 17.1796C42.8564 19.8996 44.4229 21.6964 46.7825 21.6964C49.1715 21.6964 50.738 19.8996 50.738 17.1796Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M69.654 24.7134H66.7951C66.5601 24.7134 66.3741 24.5248 66.3741 24.2865V22.45C65.4341 23.9192 63.7501 24.9615 61.6549 24.9615C58.4434 24.9615 56.2307 22.8074 56.2307 19.5216V10.0909C56.2307 9.85268 56.4167 9.66406 56.6517 9.66406H59.5107C59.7456 9.66406 59.9317 9.85268 59.9317 10.0909V18.6579C59.9317 20.2859 61.2338 21.6459 62.8395 21.6162C65.0914 21.5764 66.3643 19.6109 66.3643 16.2655V10.0909C66.3643 9.85268 66.5503 9.66406 66.7853 9.66406H69.6442C69.8792 9.66406 70.0652 9.85268 70.0652 10.0909V24.2964C70.075 24.5248 69.889 24.7134 69.654 24.7134Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M79.0728 24.9521C76.067 24.9521 73.8249 23.3043 73.8249 19.7802V12.8214H71.7492C71.5143 12.8214 71.3282 12.6328 71.3282 12.3945V10.0915C71.3282 9.85321 71.5143 9.6646 71.7492 9.6646H73.8249V5.67395C73.8249 5.4357 74.0109 5.24709 74.2459 5.24709H77.1049C77.3398 5.24709 77.5259 5.4357 77.5259 5.67395V9.6646H80.845C81.0799 9.6646 81.266 9.85321 81.266 10.0915V12.3945C81.266 12.6328 81.0799 12.8214 80.845 12.8214H77.5259V19.4327C77.5259 20.8722 78.2895 21.6465 79.4449 21.6465C79.8365 21.6465 80.2771 21.6068 80.6883 21.4777C80.9429 21.3983 81.2072 21.577 81.2268 21.845L81.4226 24.2275C81.4422 24.4359 81.3149 24.6246 81.1191 24.6742C80.4827 24.8628 79.7973 24.9521 79.0728 24.9521Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M81.9023 17.1787C81.9023 12.5726 84.9963 9.40588 89.4511 9.40588C93.0836 9.40588 95.6879 11.2325 96.3537 14.1708C96.4027 14.4091 96.2558 14.6473 96.0208 14.687L93.2108 15.243C92.9954 15.2827 92.7898 15.1536 92.7311 14.9352C92.3395 13.5653 91.1645 12.6619 89.5784 12.6619C87.2776 12.6619 85.7795 14.4289 85.7795 17.1787C85.7795 19.9285 87.2874 21.6955 89.5784 21.6955C91.1743 21.6955 92.2807 20.8914 92.7213 19.5016C92.7898 19.2932 92.9954 19.1542 93.2108 19.2038L96.0208 19.7796C96.2558 19.8292 96.4125 20.0675 96.3537 20.3057C95.6292 23.2143 93.0248 24.9516 89.4609 24.9516C84.9963 24.9516 81.9023 21.7848 81.9023 17.1787Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M98.3118 3.54906H101.171C101.406 3.54906 101.592 3.73767 101.592 3.97592V11.9771C102.561 10.3292 104.245 9.40598 106.311 9.40598C109.64 9.40598 111.853 11.5601 111.853 14.846V24.2766C111.853 24.5148 111.667 24.7035 111.432 24.7035H108.573C108.338 24.7035 108.152 24.5148 108.152 24.2766V15.7692C108.152 14.1312 106.859 12.7613 105.244 12.7514C102.923 12.7315 101.611 14.7368 101.611 18.1616V24.2766C101.611 24.5148 101.425 24.7035 101.19 24.7035H98.3313C98.0964 24.7035 97.9103 24.5148 97.9103 24.2766V3.98585C97.881 3.7476 98.0768 3.54906 98.3118 3.54906Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M113.214 5.3557C113.214 4.07512 114.183 3.05264 115.514 3.05264C116.875 3.05264 117.874 4.06519 117.874 5.3557C117.874 6.66606 116.875 7.68855 115.514 7.68855C114.193 7.68855 113.214 6.67599 113.214 5.3557ZM114.085 9.64416H116.944C117.179 9.64416 117.365 9.83277 117.365 10.071V24.2765C117.365 24.5148 117.179 24.7034 116.944 24.7034H114.085C113.85 24.7034 113.664 24.5148 113.664 24.2765V10.0809C113.654 9.8427 113.85 9.64416 114.085 9.64416Z'
        fill={fill ?? '#0B2033'}
      />
      <path
        d='M133.197 18.1714H122.564C122.887 20.4447 124.336 21.7848 126.451 21.7848C128.047 21.7848 129.222 21.08 129.701 19.8392C129.78 19.6406 129.995 19.5314 130.201 19.591L132.854 20.3156C133.099 20.3851 133.236 20.6432 133.148 20.8815C132.188 23.4526 129.692 24.9516 126.392 24.9516C121.467 24.9516 118.843 21.0999 118.843 17.1787C118.843 13.2576 121.262 9.40588 126.186 9.40588C131.199 9.40588 133.647 13.1682 133.647 16.6724C133.647 17.0298 133.637 17.4368 133.618 17.7644C133.608 17.9927 133.422 18.1714 133.197 18.1714ZM129.672 15.4216C129.496 13.6249 128.34 12.2847 126.196 12.2847C124.365 12.2847 123.073 13.3866 122.662 15.4216H129.672Z'
        fill={fill ?? '#0B2033'}
      />
    </svg>
  );
}
