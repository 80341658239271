import React from 'react';
import styled, { css } from 'styled-components';

import { mediaQueries } from 'shared/styles';

import { GqlProducts } from 'types/graphql';
import { SmallText } from './small-text';
import { DraggableListItem } from './draggable-list-item';
import { concatBrandName } from './helpers';
import { Products } from '../../../homepage-form.types';

type ProductListProps = {
  products: Products | null;
  handleMove: (dragIndex: any, hoverIndex: any) => void;
  handleRemove: (id: string) => void;
};

export function ProductList({ products, handleMove, handleRemove }: ProductListProps): JSX.Element | null {
  if (!products) {
    return null;
  }

  return (
    <>
      <DisplayHeader>
        <SmallText>SELECTED PRODUCTS ({products.length})</SmallText>
      </DisplayHeader>
      <DisplayList>
        {products.map((product: GqlProducts, index) => {
          const { id, brand, type, Name } = product;

          return (
            <DraggableListItem
              key={id}
              index={index}
              primaryText={concatBrandName(brand, Name ?? '')}
              secondaryText={type ?? ''}
              onMove={handleMove}
              onRemove={() => handleRemove(id ?? '')}
            />
          );
        })}
      </DisplayList>
    </>
  );
}

const scrollBarStyles = css`
  // firefox
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.grey[60]} transparent;

  // webkit
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background: ${({ theme }) => theme.colors.grey[60]};
    cursor: pointer;
  }

  /* handle hover */
  ::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    transition: 0.3s ease;
  }
`;

const DisplayHeader = styled.span`
  display: block;
  padding: 34px 18px 10px 18px;
`;

const DisplayList = styled.div`
  ${scrollBarStyles}
  overflow-y: auto;

  @media ${mediaQueries.largePhone} {
    overflow: unset;
  }
`;
