import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useErnie from 'shared/hooks/use-ernie';
import { useStores } from 'src/hooks/use-stores';
import { removeTypename } from 'shared/helpers/utils';
import { useGetMenuCustomization, useUpdateImageBanners } from '../data-access';
import { dutchiePayImageBanner } from './promotion-popup';
import { IMAGE_BANNER_MAX } from '../image-banner/use-image-banner-form';

type UsePromotionPopUpReturn = {
  isOpen: boolean;
  handleClose: () => void;
  seenPromotionModal: () => void;
  enableBanner: () => void;
};

export function usePromotionPopUp(): UsePromotionPopUpReturn {
  const { id: dispensaryId } = useParams<{ id: string }>();
  const history = useHistory();
  const { UI } = useStores();
  const { data } = useGetMenuCustomization();
  const updateData = useUpdateImageBanners();
  const showErnie = useErnie();
  const { cName } = UI.dispensary;

  const dutchiePayImageBannerWithSignUpLink = {
    ...dutchiePayImageBanner,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    link: `https://dutchie.com/pay/signup?cname=${cName}?variant=embedded-menu`,
  };

  const SEEN_DUTCHIE_PAY_MODAL = 'seen_dutchie_pay_image_banner_modal';

  const isDutchiePayEnabledForDispo = UI.dispensary.paymentTypesAccepted?.dutchiePay;
  const hasDispoSeenDutchiePayModal = localStorage.getItem(SEEN_DUTCHIE_PAY_MODAL) === 'true';
  const [isOpen, setIsOpen] = useState((isDutchiePayEnabledForDispo && !hasDispoSeenDutchiePayModal) ?? false);

  function handleClose(): void {
    setIsOpen(false);
  }

  function seenPromotionModal(): void {
    localStorage.setItem(SEEN_DUTCHIE_PAY_MODAL, 'true');
    setIsOpen(false);
  }

  const enableBanner = async (): Promise<void> => {
    const imageBanners = data?.imageBanners ?? [];
    if (imageBanners.length >= IMAGE_BANNER_MAX) {
      showErnie('Dutchie Pay banner could not be added due to max banner limit', 'danger');
      return;
    }

    try {
      await updateData({
        variables: {
          dispensaryId,
          input: {
            imageBanners: removeTypename([dutchiePayImageBannerWithSignUpLink, ...imageBanners]),
          },
        },
      });

      showErnie('Your image banner has been applied', 'success');
      localStorage.setItem(SEEN_DUTCHIE_PAY_MODAL, 'true');
      history.push(`/dispensaries/${dispensaryId}/customize/image-banner`);
    } catch (err) {
      showErnie('Something went wrong, please try again.', 'danger');
      console.error(err);
    }

    setIsOpen(false);
  };

  return {
    isOpen,
    handleClose,
    seenPromotionModal,
    enableBanner,
  };
}
