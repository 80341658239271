import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import _ from 'lodash';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import moment from 'moment-timezone';
import React from 'react';
import MetaTags from 'react-meta-tags';
import scrollIntoView from 'scroll-into-view';
import styled from 'styled-components';
import { space } from 'styled-system';

import PublicEnv from 'shared/utils/public-env';
import { getOrderForDelivery } from 'shared/graphql/order/queries';
import { updateOrderStatus } from 'shared/graphql/order/mutations';
import { Button } from 'shared/components';
import { Receipt } from 'shared/components/receipt';
import { ModalContainer, ModalClose } from 'shared/modals';
import { formatAddressWithoutApt } from 'shared/helpers/address';
import { getCustomerNameForOrder } from 'shared/helpers/orders';
import { paymentMethodsToDisplayNames } from 'shared/constants';
import { mediaQueries } from 'shared/styles';
import GMap from 'src/delivery/components/map';

// Fix for issues with LogRocket and Apollo Client
const fetcher = {};
// eslint-disable-next-line lodash/prefer-lodash-typecheck
if (typeof window !== 'undefined' && typeof window.fetch === 'function') {
  fetcher.fetch = (...args) => window.fetch(...args);
}

@inject('UI')
@observer
export default class DeliveryPage extends React.Component {
  @observable initialCenter;
  @observable order;
  @observable receiptModalOpen = false;
  @observable saving = false;

  componentDidMount() {
    this.fetchOrder();
  }

  get apolloClient() {
    const { params } = this.props.match;

    if (this._apolloClient) {
      return this._apolloClient;
    }
    this._apolloClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: createHttpLink({
        uri: PublicEnv.graphqlUri,
        headers: { temporaryAdminAccessToken: params.token },
        ...fetcher,
      }),
      name: 'Delivery Page',
    });
    return this._apolloClient;
  }

  fetchOrder = async () => {
    const { UI } = this.props;
    const { params } = this.props.match;

    try {
      const { data } = await this.apolloClient.query({
        query: getOrderForDelivery,
        variables: {
          id: params.id,
        },
      });

      const order = data.getOrderForDelivery;
      const initialCenter = _.pick(order.deliveryInfo.location, ['lat', 'lng']);
      this.order = order;
      this.initialCenter = initialCenter;
    } catch (error) {
      UI.showErnie('We were unable to find this order. Please contact support.', 'danger');
    }
  };

  closeOrder = async () => {
    const { UI } = this.props;
    const { params } = this.props.match;
    this.saving = true;

    try {
      const { data } = await this.apolloClient.mutate({
        mutation: updateOrderStatus,
        variables: {
          input: {
            id: params.id,
            status: 'closed',
            closedByDriver: true,
          },
        },
      });

      this.order = data?.updateOrderStatus;
      this.saving = false;
      scrollIntoView(document.getElementById('successBanner'), { time: 300 });
    } catch (error) {
      UI.showErnie('We were unable to close this order. Please contact support or close it from the admin.', 'danger');
      this.saving = false;
    }
  };

  render() {
    const { order } = this;

    if (!order) {
      return null;
    }

    let medicalCard;
    if (order.medicalOrder) {
      medicalCard = order.medicalCard || _.get(order, 'customer.profile.medicalCard', null);
    }

    const { guestCustomer } = order;
    const customerName = getCustomerNameForOrder(order);
    const customerPhone = guestCustomer ? guestCustomer.phone : order.customer.profile.phone;
    const customerBirthday = guestCustomer
      ? moment(`${guestCustomer.birthMonth}-${guestCustomer.birthDay}-${guestCustomer.birthYear}`, 'M-D-YYYY')
      : moment(order.customer.profile.birthday, 'M-D-YYYY');

    return (
      <DeliveryPageContainer>
        <MetaTags>
          <title>Delivery Driver Page</title>
          <meta name='description' content='Delivery driver page' />
        </MetaTags>
        <>
          {this.order.status === 'closed' && (
            <SuccessBanner id='successBanner'>
              <p>
                <b>This delivery is closed!</b>
              </p>
              <p>{moment.tz(order.dispensary.timezone).format('LLL')}</p>
            </SuccessBanner>
          )}
          <Header>
            <img src='/icons/dutchie-logo.svg' />
            <HeaderText>
              <p>Current Delivery</p>
              <p>{`Order #${order.orderId}`}</p>
            </HeaderText>
          </Header>
          {this.initialCenter && (
            <GMap initialCenter={this.initialCenter} width='100%' height='240px' tag={order.deliveryInfo.address} />
          )}
          <OrderInfo className='delivery-section'>
            <Section pt='0'>
              <SectionContent>
                <Label>Address:</Label>
                <Info>
                  <p>{order.deliveryInfo.address}</p>
                  {order.deliveryInfo.apartmentNum ? (
                    <p style={{ marginTop: '-1px' }}>Apt/Ste:&nbsp;{order.deliveryInfo.apartmentNum}</p>
                  ) : null}
                  <p>
                    <a
                      href={`https://www.google.com/maps?q=${encodeURIComponent(
                        formatAddressWithoutApt(order.deliveryInfo.location || {})
                      )}`}
                      rel='noopener norefferer'
                      target='_blank'
                    >
                      Get directions
                    </a>
                  </p>
                </Info>
              </SectionContent>
            </Section>
            <Section>
              <SectionContent>
                <Label>Customer:</Label>
                <Info>
                  <p>{customerName}</p>
                  <p>
                    <a href={`tel:${customerPhone?.replace(/\D/g, '')}`}>Call customer</a>
                  </p>
                </Info>
              </SectionContent>
              <SectionContent mt='40px'>
                <Label>Date of Birth:</Label>
                <Info>
                  <p>{customerBirthday.format('MM/DD/YYYY')}</p>
                </Info>
              </SectionContent>
            </Section>
            {order.medicalOrder && medicalCard && (
              <Section>
                <SectionContent>
                  <Label>Medical Card:</Label>
                  <Info>
                    <p>{medicalCard.number}</p>
                    <p>{`Exp: ${medicalCard.expirationDate}`}</p>
                  </Info>
                </SectionContent>
                <SectionContent mt='40px'>
                  <Label>Date of Birth:</Label>
                  <Info>
                    <p>{customerBirthday.format('MM/DD/YYYY')}</p>
                  </Info>
                </SectionContent>
              </Section>
            )}
            <Section>
              <SectionContent>
                <Label>Order Total:</Label>
                <Info>
                  <p>${order.totalCost}</p>
                  <p>
                    <a href='#' onClick={() => (this.receiptModalOpen = true)}>
                      View receipt
                    </a>
                  </p>
                </Info>
              </SectionContent>
              <SectionContent mt='40px'>
                <Label>Payment:</Label>
                <Info>
                  <p>{_.get(paymentMethodsToDisplayNames, order.paymentMethod, 'Cash')}</p>
                </Info>
              </SectionContent>
            </Section>
            {order.status !== 'closed' && (
              <StyledButton loading={this.saving} onClick={this.closeOrder}>
                Complete Order
              </StyledButton>
            )}
          </OrderInfo>
          <Footer>
            <p>Need Help?</p>
            <p>
              <a href={`tel:${order.dispensary?.phone?.replace(/\D/g, '')}`}>Contact Store</a>
            </p>
            <p>
              <a href='tel:8668388244'>Contact Support</a>
            </p>
          </Footer>
          <DutchieFooter>
            <span className='copyright'>&copy; {new Date().getFullYear()}</span>
          </DutchieFooter>
        </>
        {this.receiptModalOpen && (
          <ModalContainer isOpen onRequestClose={() => (this.receiptModalOpen = false)}>
            <ReceiptContainer>
              <Receipt order={order} />
            </ReceiptContainer>
            <ModalClose onClick={() => (this.receiptModalOpen = false)} />
          </ModalContainer>
        )}
      </DeliveryPageContainer>
    );
  }
}

const DeliveryPageContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
`;

const SuccessBanner = styled.div`
  background-color: #669f77;
  color: #fff;
  font-size: 19px;
  padding: 17px;
  text-align: center;

  p:not(:last-child) {
    margin-bottom: 7.5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42.5px 41.5px 29.5px;

  img {
    height: 70px;
  }
`;

const HeaderText = styled.div`
  text-align: right;
  p:first-of-type {
    color: #46494c;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 12.5px;
  }

  p:last-of-type {
    font-size: 34px;
    font-weight: 300;
  }
`;

const OrderInfo = styled.div`
  color: #46494c;
  padding: 42.5px 41.5px 29.5px;
`;

const Section = styled.div`
  font-size: 18px;
  text-align: left;
  padding: 27px 0;
  border-bottom: 1px solid #d3dadf;
  ${space}
`;

const SectionContent = styled.div`
  display: flex;
  ${space}
`;

const Label = styled.div`
  width: 172.5px;
  font-weight: bold;
`;

const Info = styled.div`
  width: 225px;
  line-height: 31px;

  p + p {
    margin-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 54px;
  margin-top: 45px;
  margin-bottom: 45px;
  font-size: 18px;
`;

const Footer = styled.div`
  background-color: #ecf0f3;
  border-top: none;
  color: #6d747b;
  font-size: 21px;
  padding-bottom: 47.5px;
  padding-top: 43px;
  text-align: center;
  font-size: 18px;

  p:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const DutchieFooter = styled.div`
  background-color: #dfe7ec;
  color: #6a6f73;
  font-size: 18px;
  line-height: 52.5px;
  text-align: center;
`;

const ReceiptContainer = styled.div`
  overflow: auto;

  @media ${mediaQueries.tablet} {
    overflow: unset;
  }
`;
