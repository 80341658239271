import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass';

import { textInputStyles } from 'shared/components/text-input';
import { mediaQueries } from 'shared/styles';
import RouteLeavingGuard from 'src/routes/route-leaving-guard';
import PublishModal from 'src/dispensary-admin/publish-modal';
import { DutchieQuill } from 'src/components/dutchie-quill';
import { useLegalDisclaimer } from './use-legal-disclaimer';
import { ContentContainer, Detail, Page, Title } from '../components/customize.styles';
import { PublishBanner } from '../components/publish-banner';

export const LegalDisclaimer = observer(() => {
  const {
    disclaimerTextHtml,
    privacyPolicyUrl,
    isFloridaDispensary,
    handleChange,
    handleUpdateDispensary,
    handlePublishChanges,
    handleClickNo,
    unpublishedDispoChanges,
  } = useLegalDisclaimer();

  return (
    <>
      <Page>
        <Title>Legal Disclaimer</Title>
        <Detail>
          The disclaimer will be shown at the bottom of your embedded menu. You can use this to include a legal
          disclaimer or a message to your customers.
        </Detail>

        <ContentContainer>
          <InputContainer>
            <DutchieQuill htmlValue={disclaimerTextHtml} onChange={handleChange} />
          </InputContainer>
        </ContentContainer>

        {isFloridaDispensary ? (
          <PrivacyPolicyContainer>
            <Title>Privacy Policy</Title>
            <Detail>
              Use the field below to add a URL to your privacy policy. The privacy policy will be linked at checkout
              from your embedded menu.
            </Detail>
            <ContentContainer>
              <InputContainer>
                <PrivacyPolicyLabel>PRIVACY POLICY URL</PrivacyPolicyLabel>
                <PrivacyPolicyInput
                  name='privacyPolicyUrl'
                  defaultValue={privacyPolicyUrl}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleUpdateDispensary('embedSettings.privacyPolicyUrl', value);
                  }}
                  placeholder='Add a link to your privacy policy...'
                />
              </InputContainer>
            </ContentContainer>
          </PrivacyPolicyContainer>
        ) : null}
      </Page>

      {unpublishedDispoChanges && <PublishBanner onPublish={handlePublishChanges} />}

      <RouteLeavingGuard
        when={unpublishedDispoChanges}
        modal={PublishModal}
        handleConfirm={handlePublishChanges}
        handleDeny={handleClickNo}
      />
    </>
  );
});

const InputContainer = styled.div`
  width: 465px;
`;

const PrivacyPolicyContainer = styled.div`
  margin-top: 40px;
  border-top: 1px solid #e5ebef;
  padding-top: 25px;
`;

const PrivacyPolicyInput = styled.input`
  ${textInputStyles}
  height: 45px;
  border-radius: 3px;
`;

const PrivacyPolicyLabel = styled(Text)`
  width: auto;
  padding-right: 6px;
  font-size: 11px;
  font-weight: bold;
  color: #969ea5;

  @media ${mediaQueries.largePhone} {
    width: 100%;
    padding-bottom: 6px;
  }
`;
