import React from 'react';

export function SearchIcon(props) {
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <mask maskUnits='userSpaceOnUse' x='0' y='-1' width='17' height='18' style={{ maskType: 'alpha' }}>
        <path fillRule='evenodd' clipRule='evenodd' d='M0 -0.00012207H16.9964V16.9979H0V-0.00012207Z' fill='red' />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.04895 6.65673C2.04895 4.1121 4.11221 2.04883 6.65684 2.04883C9.282 2.12757 11.3909 4.23736 11.4705 6.86252C11.4705 9.40804 9.40816 11.4704 6.86263 11.4704C4.23747 11.3917 2.12768 9.28189 2.04895 6.65673ZM16.6153 14.3783L12.5263 10.3162C13.1598 9.27561 13.4944 8.08114 13.4926 6.86251C13.4121 3.11625 10.4022 0.09472 6.65684 -0.00012207C2.98037 -0.00012207 0 2.98025 0 6.65672C0.0805263 10.4128 3.10653 13.4388 6.86263 13.5194C8.03742 13.5229 9.19252 13.2151 10.2089 12.6246L14.3158 16.6957C14.7122 17.0956 15.3582 17.0992 15.7581 16.7028L15.7653 16.6957L16.7763 15.6846C17.1611 15.282 17 14.7809 16.6153 14.3783Z'
          fill='#6D747B'
          fillOpacity='0.5'
        />
      </g>
    </svg>
  );
}
