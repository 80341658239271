import { EnterpriseJSONFeatureFlag } from '../helpers/types';
import evaluateEnterpriseFeatureFlag from './evaluate-enterprise-feature-flag';

const flagIsEnabledForEnterprise = (
  key: string,
  flags: Record<string, EnterpriseJSONFeatureFlag>,
  enterpriseId: string
): boolean => {
  const featureFlagValue = flags[key];
  return evaluateEnterpriseFeatureFlag(key, featureFlagValue, enterpriseId);
};

export default flagIsEnabledForEnterprise;
