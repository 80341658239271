import { useState, useEffect, useCallback, useMemo } from 'react';
import update from 'immutability-helper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useGetMenuSectionProductsAdminQuery,
  useFilteredSpecialsQuery,
  useFilteredSpecialsV2Query,
  GqlSpecials,
  MenuSectionType,
  GqlMenuSection,
} from 'types/graphql';
import { useStores } from 'src/hooks/use-stores';
import useErnie from 'shared/hooks/use-ernie';
import { MenuCustomizationData, useUpdateMenuSections } from '../data-access';
import { LocalSection, Products, Offers, OffersV2, DynamicSectionType, MenuSectionOption } from './homepage-form.types';
import { DYNAMIC_SECTIONS, DEFAULT_SECTIONS } from './homepage-form.constants';
import {
  filterMenuSections,
  getAvailableMenuSectionTypes,
  createNewDynamicLocalSection,
  getUpdateParams,
} from './homepage-form.utils';

type UseGetItemDataReturn = {
  products: Products | null;
  offers: Offers | OffersV2 | null;
  itemsLoading: boolean;
};

function useGetItemData(dispensaryId: string): UseGetItemDataReturn {
  const { apolloClient } = useStores();
  const flags = useFlags();

  const { data: productsData, loading: productsLoading } = useGetMenuSectionProductsAdminQuery({
    variables: {
      filter: {
        dispensaryId,
        sortBy: 'popularSortIdx',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Status: 'Active',
      },
    },
    client: apolloClient,
    fetchPolicy: 'network-only',
  });

  const useV2Query = flags['ecomm.discounts.use-arma-filtered-specials.rollout'];
  const filteredSpecialsQuery = useV2Query ? useFilteredSpecialsV2Query : useFilteredSpecialsQuery;
  const { data: specialsData, loading: specialsLoading } = filteredSpecialsQuery({
    variables: {
      specialsFilter: {
        dispensaryId,
        current: true,
      },
    },
    client: apolloClient,
    fetchPolicy: 'network-only',
  });

  const products = useMemo(() => productsData?.filteredProducts?.products ?? null, [productsData]);
  const allSpecials = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => specialsData?.filteredSpecialsV2?.specials ?? specialsData?.filteredSpecials?.specials ?? null,
    [specialsData]
  );
  const offers = useMemo(() => allSpecials?.filter((special: GqlSpecials) => special.specialType === 'bogo'), [
    allSpecials,
  ]);

  return {
    products,
    offers,
    itemsLoading: productsLoading || specialsLoading,
  };
}

type UseHomepageFormProps = {
  data: MenuCustomizationData;
};

type UseHomepageFormReturn = {
  availableMenuSectionTypes: MenuSectionOption[];
  currentSection: LocalSection | null;
  handleAddDefaultSection: (newSection: LocalSection) => void;
  handleAddSection: (sectionTypeKey: MenuSectionType) => void;
  handleCloseModal: () => void;
  handleDrop: () => void;
  handleEditSection: (id: string, type: MenuSectionType) => void;
  handleMoveOption: (dragIndex: number, hoverIndex: number) => void;
  handlePublishSections: () => void;
  handleRemoveMenuSection: (menuSectionId: string) => void;
  handleResetSections: () => void;
  isDirty: boolean;
  itemsLoading: boolean;
  localMenuSections: LocalSection[] | null;
  previewMenuSections: GqlMenuSection[];
  products: Products | null;
  showModalType: MenuSectionType | null;
};

export function useHomepageForm({ data }: UseHomepageFormProps): UseHomepageFormReturn {
  const { menuSections } = data;
  const { UI } = useStores();
  const showErnie = useErnie();
  const [initialSections, setInitialSections] = useState<LocalSection[] | null>(null);
  const [currentSection, setCurrentSection] = useState<LocalSection | null>(null);
  const [availableMenuSectionTypes, setAvailableMenuSectionTypes] = useState<MenuSectionOption[]>([]);
  const [showModalType, setShowModalType] = useState<MenuSectionType | null>(null);
  const [localMenuSections, setLocalMenuSections] = useState<LocalSection[] | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const updateData = useUpdateMenuSections();

  const { products, offers, itemsLoading } = useGetItemData(UI.dispensary.id ?? '');

  const initialQueryResultMenuSections = useMemo(() => filterMenuSections(menuSections, { products, offers }), [
    menuSections,
    products,
    offers,
  ]);

  const handleSetInitialSections = useCallback((newSections: LocalSection[]) => setInitialSections(newSections), []);

  useEffect(() => {
    handleSetInitialSections(initialQueryResultMenuSections);
    if (!localMenuSections && !itemsLoading) {
      setLocalMenuSections(initialQueryResultMenuSections);
    }
  }, [handleSetInitialSections, initialQueryResultMenuSections, itemsLoading, localMenuSections]);

  useEffect(() => {
    if (localMenuSections) {
      setAvailableMenuSectionTypes(getAvailableMenuSectionTypes(localMenuSections));
    } else {
      setAvailableMenuSectionTypes(getAvailableMenuSectionTypes(initialQueryResultMenuSections));
    }
  }, [localMenuSections, initialQueryResultMenuSections]);

  const handlePublishSections = async (): Promise<void> => {
    const updateParams = getUpdateParams(UI.dispensary.id ?? '', initialSections, localMenuSections);

    try {
      await updateData({
        variables: {
          ...updateParams,
        },
      });

      showErnie('Your menu sections have been updated', 'success');
      setIsDirty(false);
    } catch (error) {
      showErnie('Something went wrong, please try again.', 'danger');
      console.error(error);
    }
  };

  const handleResetSections = (): void => {
    setLocalMenuSections(initialSections);
  };

  const handleAddSection = (sectionTypeKey: MenuSectionType): void => {
    if (DEFAULT_SECTIONS.includes(sectionTypeKey)) {
      setShowModalType(sectionTypeKey);
      return;
    }

    if (UI.dispensary.id && DYNAMIC_SECTIONS.includes(sectionTypeKey)) {
      const newSection = createNewDynamicLocalSection(UI.dispensary.id, sectionTypeKey as DynamicSectionType, {
        products,
        offers,
      });

      if (newSection) {
        if (!localMenuSections) {
          setLocalMenuSections([newSection]);
        } else {
          setLocalMenuSections([...localMenuSections, newSection]);
        }
      }
    }

    setIsDirty(true);
  };

  const handleAddDefaultSection = (newSection: LocalSection): void => {
    if (!localMenuSections) {
      setLocalMenuSections([newSection]);
    } else {
      const foundSectionIndex = localMenuSections.findIndex((section) => section.id === newSection.id);

      if (foundSectionIndex > -1) {
        const updatedSections = [...localMenuSections];
        updatedSections.splice(foundSectionIndex, 1, newSection);
        setLocalMenuSections(updatedSections);
      } else {
        setLocalMenuSections([...localMenuSections, newSection]);
      }
    }

    setIsDirty(true);
  };

  const handleDrop = (): void => {
    if (localMenuSections) {
      const sortedLocalSections = localMenuSections.map((section, index) => ({ ...section, position: index + 1 }));
      setLocalMenuSections(sortedLocalSections);
    }

    setIsDirty(true);
  };

  const handleMoveOption = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedMenuSection = localMenuSections?.[dragIndex];
      if (draggedMenuSection) {
        const newSectionOrder = update(localMenuSections, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedMenuSection],
          ],
        });

        setLocalMenuSections(newSectionOrder);
      }
    },
    [localMenuSections]
  );

  const handleRemoveMenuSection = (menuSectionId: string): void => {
    if (localMenuSections) {
      const newLocalSections = localMenuSections
        .filter((section) => section.id !== menuSectionId)
        .map((section, index) => ({ ...section, position: index + 1 }));

      setLocalMenuSections(newLocalSections);
    }
    setIsDirty(true);
  };

  const handleEditSection = (id: string, type: MenuSectionType): void => {
    const sectionToEdit = localMenuSections?.find((section) => section.id === id) ?? null;
    setShowModalType(type);
    setCurrentSection(sectionToEdit);
  };

  const handleCloseModal = (): void => {
    setShowModalType(null);
    setCurrentSection(null);
  };

  const previewMenuSections =
    localMenuSections?.map(
      (section: LocalSection) => ({ ...section, dispensary: UI.dispensary, linkLabel: '' } as GqlMenuSection)
    ) ?? [];

  return {
    availableMenuSectionTypes,
    currentSection,
    handleAddDefaultSection,
    handleAddSection,
    handleCloseModal,
    handleDrop,
    handleEditSection,
    handleMoveOption,
    handlePublishSections,
    handleRemoveMenuSection,
    handleResetSections,
    isDirty,
    itemsLoading,
    localMenuSections,
    previewMenuSections,
    products,
    showModalType,
  };
}
