import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import { MAX_SECTION_NAME_LENGTH } from '../use-custom-modal';

import { CustomTextField } from './custom-text-field';

type SectionNameInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
export function SectionNameInput({ value, onChange }: SectionNameInputProps): JSX.Element {
  const characterCount = value.length;

  return (
    <CustomTextField
      fullWidth
      name='sectionName'
      placeholder='Give your new section a name'
      variant='outlined'
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {characterCount}/{MAX_SECTION_NAME_LENGTH}
          </InputAdornment>
        ),
      }}
    />
  );
}
