import React, { useState } from 'react';
import styled from 'styled-components';

import BrandSelect from 'src/components/brand-select';
import { ModalContainer, ModalButton, ModalSecondaryHeader, ModalClose, ModalCopy } from 'shared/modals';
import { useStores } from 'src/hooks/use-stores';

import { MenuSectionType, GqlBrand } from 'types/graphql';
import { v4 as uuid } from 'uuid';
import { LocalSection, Products } from '../homepage-form.types';
import { getMenuSectionProductFilter, getProductIds } from '../homepage-form.utils';

type BrandModalProps = {
  onToggle: () => void;
  menuSections: LocalSection[] | null;
  handleAddSection: (newSection: LocalSection) => void;
  products: Products | null;
};

type BrandOption = {
  key: string;
  label: string;
  value: GqlBrand;
};

export function BrandModal({ onToggle, menuSections, handleAddSection, products }: BrandModalProps): JSX.Element {
  const { UI } = useStores();
  const [selectedBrand, setSelectedBrand] = useState<BrandOption | null>(null);
  const disabledBrandIds = menuSections?.map((menuSection) => menuSection.brandId ?? '').filter((id) => !!id);

  const handleSave = (): void => {
    if (!selectedBrand) {
      return;
    }

    const filterMethod = getMenuSectionProductFilter(MenuSectionType.brand);
    const foundProducts = filterMethod({
      items: { products, offers: undefined },
      menuSection: { brandId: selectedBrand.value.id },
    });

    const productIds = getProductIds(foundProducts as Products);

    const newMenuSection: LocalSection = {
      id: uuid(),
      dispensaryId: UI.dispensary.id ?? '',
      sectionType: MenuSectionType.brand,
      brandId: selectedBrand.value.id,
      position: 0,
      label: selectedBrand.label,
      products: productIds,
      count: productIds.length,
    };

    handleAddSection(newMenuSection);
    onToggle();
  };

  const handleChangeBrand = (brandOption: BrandOption): void => {
    setSelectedBrand(brandOption);
  };

  return (
    <ModalContainer isOpen padding='30px 57px 52px' width='462px'>
      <ModalSecondaryHeader lowercase>Add a Brand</ModalSecondaryHeader>
      <Copy>Select a brand to show their products in a section on your homepage.</Copy>

      <BrandSelect
        brand={selectedBrand?.value}
        disabledBrandIds={disabledBrandIds}
        onChange={handleChangeBrand}
        placeholder='Select a brand'
        width={300}
      />

      <ModalButton mt='30px' width='82px' onClick={handleSave}>
        Add
      </ModalButton>

      <ModalClose onClick={onToggle} />
    </ModalContainer>
  );
}

const Copy = styled(ModalCopy)`
  margin-bottom: 24px;
`;
