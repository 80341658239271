import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { MenuCustomizationLayout } from '../components/menu-customization-layout';
import { PreviewSwitcher } from '../components/preview-switcher';
import { FontSelectorV2 } from '../components/fonts-selector-v2';

import { useFontsForm } from './use-fonts-form';
import { FontsFormProps } from './fonts.types';
import { nonLocalFonts } from './fonts.constants';

export function FontsForm(props: FontsFormProps): JSX.Element {
  const { control, handlePublish, handleReset, isDirty, previewData } = useFontsForm(props);

  return (
    <MenuCustomizationLayout
      headerText='Fonts'
      subHeaderText='Select fonts to express your brand.'
      isDirty={isDirty}
      handlePublish={handlePublish}
      handleReset={handleReset}
      previewComponent={<PreviewSwitcher previewData={previewData} />}
    >
      {nonLocalFonts.map((font) => (
        <link
          href={`https://fonts.googleapis.com/css2?family=${font.fontFamilyEncoded}&text=${encodeURIComponent(
            font.fontFamily
          )}`}
          rel='stylesheet'
          key={font.fontFamily}
        />
      ))}

      <Inputs>
        <Input>
          <Controller
            name='heading'
            control={control}
            render={({ onChange, value }) => (
              <FontSelectorV2 label='Heading Font' selectedOption={value} onChange={onChange} />
            )}
          />

          <HelperText>Larger text Including titles, button text, links and navigation text.</HelperText>
        </Input>

        <Input>
          <Controller
            name='body'
            control={control}
            render={({ onChange, value }) => (
              <FontSelectorV2 label='Body Font' selectedOption={value} onChange={onChange} />
            )}
          />

          <HelperText>Smaller text including product details, descriptions, captions, tag labels and more.</HelperText>
        </Input>
      </Inputs>
    </MenuCustomizationLayout>
  );
}

const Inputs = styled.div`
  flex: 0 0 auto;
  min-width: 0;
`;

const HelperText = styled.div`
  font-size: 13px;
  line-height: ${16 / 13};
  margin-top: 8px;
  width: 310px;
`;

const Input = styled.div`
  margin-bottom: 20px;
`;
