// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import _ from 'lodash';
import { isExcludedProduct as isExcludedProductHelper } from 'shared/helpers/specials/excluded-products';

const isExcludedProduct = (item, bogoSpecial, type) => {
  // v3.0
  const { excludedProducts = {} } = bogoSpecial || {};
  const excludedTypeProducts = excludedProducts[`${type}s`] || [];
  const isLegacyExcluded = isExcludedProductHelper(item, excludedTypeProducts);

  // v3.5
  const pluralizedAndCapitalizedType = `${_.capitalize(type)}s`;
  const excludedTypeProductsNew = bogoSpecial[`bogo${pluralizedAndCapitalizedType}`]?.exclusions || [];
  const exclusionResults = _.map(excludedTypeProductsNew, (exclusion) =>
    isExcludedProductHelper(
      item,
      [exclusion],
      bogoSpecial?.useActiveBatchTags,
      bogoSpecial?.useActiveBatchTagOfWeightOption
    )
  );
  const isExcluded = _.some(exclusionResults);

  return isLegacyExcluded || isExcluded;
};

export const getAvailableQuantity = ({
  bogoCalcsData,
  discountToCartApplicable = false,
  item,
  excludeDefeatedRewardsSatisfiers,
  pendingConditionsSatisfiers,
  pendingRewardsSatisfiers,
  specialId = '',
  totalSpendApplicable = false,
}) => {
  const itemKey = item?.key;
  let availableQuantity = Number(item?.quantity) ?? 0;
  if (discountToCartApplicable) {
    // we want to subtract if the item has satisfied for that dtc special before, for multiple redemptions
    availableQuantity -= bogoCalcsData.discountToCartSatisfiers?.[specialId]?.[itemKey]?.quantity ?? 0;
    return availableQuantity;
  }
  if (totalSpendApplicable) {
    availableQuantity -= bogoCalcsData.rewardsSatisfiers?.[itemKey]?.quantity ?? 0;
    availableQuantity -= pendingRewardsSatisfiers?.[itemKey]?.quantity ?? 0;
    availableQuantity -= bogoCalcsData?.defeatedRewardsSatisfiers?.[itemKey]?.quantity ?? 0;
    return Math.max(0, availableQuantity);
  }
  const conditionsToCheck = bogoCalcsData?.ignoreConditionsForDiscountBundle
    ? 0
    : bogoCalcsData.conditionsSatisfiers?.[itemKey]?.quantity ?? 0;
  availableQuantity -= conditionsToCheck;
  availableQuantity -= bogoCalcsData.discountToCartSatisfiers?.[specialId]?.[itemKey]?.quantity ?? 0;
  availableQuantity -= bogoCalcsData.rewardsSatisfiers?.[itemKey]?.quantity ?? 0;
  availableQuantity -= pendingConditionsSatisfiers?.[itemKey]?.quantity ?? 0;
  availableQuantity -= pendingRewardsSatisfiers?.[itemKey]?.quantity ?? 0;
  if (excludeDefeatedRewardsSatisfiers) {
    availableQuantity -= bogoCalcsData?.defeatedRewardsSatisfiers?.[itemKey]?.quantity ?? 0;
  }
  return Math.max(0, availableQuantity);
};

// TODO: after the 3.5 migration is complete, we should remove duplicate functions such as this
//  (checkProductForEligibleOptions makes this redundant)
export const isApplicableItem = (conditionOrReward, item, bogoSpecial, type) => {
  // ensure that this isn't an excluded product
  if (isExcludedProduct(item, bogoSpecial, type, bogoSpecial)) {
    return false;
  }

  // if our reward is target price, make sure our item costs enough for the discount to actually apply
  if (!_.isNil(conditionOrReward?.targetPrice)) {
    const targetPriceValue = Big(parseFloat(conditionOrReward.targetPrice));
    if (targetPriceValue.gt(item.basePrice)) {
      return false;
    }
  }

  const categoryMatch = matchesCategory(conditionOrReward, item, conditionOrReward.productGroup);
  const brandMatch = matchesBrand(conditionOrReward, item, conditionOrReward.productGroup);

  if (conditionOrReward.productGroup === 'brands' || conditionOrReward.productGroup === 'brand') {
    let matchesSelections = false;
    if (conditionOrReward?.selectedCategoriesAndSubcategories) {
      matchesSelections =
        _.includes(conditionOrReward.selectedCategoriesAndSubcategories, item.productCategory) ||
        _.includes(conditionOrReward.selectedCategoriesAndSubcategories, item.productSubcategory);
    }

    return brandMatch && (categoryMatch || matchesSelections);
  }

  if (conditionOrReward.productGroup === 'categories' || conditionOrReward.productGroup === 'category') {
    return brandMatch && categoryMatch;
  }

  const matchesProductIds = matchesProductId(conditionOrReward, item, true);
  const matchOnEnterpriseId = conditionOrReward.enterpriseProductId
    ? conditionOrReward.enterpriseProductId === `EPID_${item.enterpriseProductId}` ||
      conditionOrReward.enterpriseProductId === item.enterpriseProductId
    : false;
  const matchOnProductId = conditionOrReward.productId ? conditionOrReward.productId === item.id : false;

  return matchOnEnterpriseId || matchOnProductId || matchesProductIds;
};

export const mergeSatisfiers = (formerSatisfiers = {}, additionalSatisfiers = {}, mergingRewardTypes = false) => {
  const merged = { ...formerSatisfiers };
  _.forEach(additionalSatisfiers, (value, key) => {
    if (!merged[key]) {
      merged[key] = { ...value };
    } else {
      merged[key] = {
        ...merged[key],
        // Using the same function here since it combines objects with the same key while increasing quantity
        ...(value.conditions ? { conditions: mergeSatisfiers(merged[key].conditions, value.conditions) } : {}),
        ...(value.rewards ? { rewards: mergeSatisfiers(merged[key].rewards, value.rewards) } : {}),
        ...(value.satisfiedBy ? { satisfiedBy: mergeSatisfiers(merged[key].satisfiedBy, value.satisfiedBy) } : {}),
        ...(!mergingRewardTypes ? { quantity: merged[key].quantity + value.quantity } : {}),
        ...(value.reward?.redemptionMultiplier
          ? {
              reward: {
                ...value.reward,
              },
            }
          : {}),
      };
    }
  });
  return merged;
};

export const checkCombination = (combination, conditions, logicOperator) => {
  let winningCombination = null;

  if (conditions.length < 1 || logicOperator === 'or') {
    winningCombination = combination;
    return { breakEarly: true, winningCombination };
  }

  // If the product logicOperator was set to AND
  let passesConditions = true;
  // eslint-disable-next-line consistent-return
  _.forEach(conditions, (condition) => {
    const conditionSatisfied = _.reduce(
      condition.eligibleItems,
      (isSatisfied, item) => !(!isSatisfied && !combination.items[item.key]),
      false
    );
    if (!conditionSatisfied) {
      passesConditions = false;
      return false; // break early
    }
  });

  if (passesConditions) {
    winningCombination = combination;
    return { breakEarly: true, winningCombination };
  }

  return { breakEarly: false, winningCombination };
};

export function determineWinningCombo(combinations, itemToConsiderLast, conditions, logicOperator) {
  const considerCombinationsLast = [];
  let breakEarly;
  let winningCombination;

  // eslint-disable-next-line consistent-return
  _.forEach(combinations, (combination) => {
    if (itemToConsiderLast && combination.items[itemToConsiderLast.key]) {
      considerCombinationsLast.push(combination);
    } else {
      ({ breakEarly, winningCombination } = checkCombination(combination, conditions, logicOperator));
      return !breakEarly; // break early if a winningCombination was found
    }
  });

  if (!winningCombination) {
    _.forEach(considerCombinationsLast, (combination) => {
      ({ breakEarly, winningCombination } = checkCombination(combination, conditions, logicOperator));
      return !breakEarly; // break early if a winningCombination was found
    });
  }

  return winningCombination;
}

export function setSatisfiersToWinningCombo(winningCombination) {
  return _.reduce(
    winningCombination.items,
    (result, item) => {
      result[item.key] = { item: item.item, quantity: item.quantity };
      return result;
    },
    {}
  );
}

const isAnyCategory = (type) => _.toLower(type) === 'any category';
const isAnyBrand = (type) => _.toLower(type) === 'any brand';

function matchesPOSMetaDataBrand(brandCondition, product) {
  const posMetaDataBrandId = product?.POSMetaData?.canonicalBrandId;

  return posMetaDataBrandId && _.includes(brandCondition, posMetaDataBrandId);
}

function matchesPOSMetaDataCategory(categoryCondition, product) {
  const posMetaDataCategoryId = product?.POSMetaData?.canonicalCategoryId;

  return posMetaDataCategoryId && _.includes(categoryCondition, posMetaDataCategoryId);
}

function matchesPOSMetaDataStrain(strainCondition, product) {
  const posMetaDataStrainId = product?.POSMetaData?.canonicalStrainId;

  return posMetaDataStrainId && _.includes(strainCondition, posMetaDataStrainId);
}

function matchesPOSMetaDataVendor(vendorCondition, product) {
  const posMetaDataVendorId = product?.POSMetaData?.canonicalVendorId;

  return posMetaDataVendorId && _.includes(vendorCondition, posMetaDataVendorId);
}

export function matchesBrand(rule = {}, product = {}, productGroup = 'individual', failIfNoCriteria = false) {
  const isBrandRule = _.includes(['brand', 'brands'], productGroup);
  const hasLegacyBrandCriteria = rule?.brandName || rule?.brandId;
  const noCriteria = !(rule?.brandIds?.length || rule?.brandNames?.length || hasLegacyBrandCriteria);

  if (noCriteria) {
    return !failIfNoCriteria;
  }

  const allowAnyBrands = !isBrandRule && rule.brandName && isAnyBrand(rule.brandName);
  if (allowAnyBrands) {
    return true;
  }

  const brandCondition = [...(rule?.brandIds ?? []), ...(rule?.brandNames ?? [])];
  // check against new brand arrays
  const matchesBrands =
    (product?.brandId && _.includes(brandCondition, product.brandId)) ||
    (product?.brandName && _.includes(brandCondition, product.brandName)) ||
    // 'product' coming from eligibleItems has a dumb name
    (product?.productBrandId && _.includes(brandCondition, product.productBrandId)) ||
    (product?.productBrand && _.includes(brandCondition, product.productBrand)) ||
    matchesPOSMetaDataBrand(brandCondition, product);

  // check against legacy brand fields
  const matchesLegacyBrand =
    (rule?.brandName && product?.brand?.name && rule.brandName === product.brand.name) ||
    (rule?.brandName && product?.brandName && rule.brandName === product.brandName) ||
    (rule?.brandId && product?.brandId && rule.brandId === product.brandId) ||
    // 'product' coming from eligibleItems has a dumb name
    (rule?.brandName && product?.productBrand && rule.brandName === product.productBrand) ||
    (rule?.brandId && product?.productBrandId && rule.brandId === product.productBrandId);

  return !!(matchesBrands || matchesLegacyBrand);
}

export function matchesCategory(rule = {}, product = {}, productGroup = 'individual', failIfNoCriteria = false) {
  const isCategoryRule = _.includes(['category', 'categories'], productGroup);
  const hasLegacyCategoryCriteria = rule?.categoryName;
  const noCriteria = !(
    rule?.categoryIds?.length ||
    rule?.categoryNames?.length ||
    rule?.subcategoryIds?.length ||
    rule?.subcategoryNames?.length ||
    hasLegacyCategoryCriteria
  );

  if (noCriteria) {
    return !failIfNoCriteria;
  }

  const allowAnyCategories = !isCategoryRule && (isAnyCategory(rule.categoryName) || noCriteria);
  if (allowAnyCategories) {
    return true;
  }

  const categoryCondition = [...(rule?.categoryIds ?? []), ...(rule?.categoryNames ?? [])];
  const subcategoryCondition = [...(rule?.subcategoryIds ?? []), ...(rule?.subcategoryNames ?? [])];
  const combinedConditions = _.flatMap([...categoryCondition, subcategoryCondition]);
  const isUncategorizedProduct =
    _.includes(rule?.categoryName, 'Uncategorized') ||
    _.find(rule?.categoryNames, (cat) => _.includes(cat, 'Uncategorized')) ||
    _.find(rule?.categoryIds, (cat) => _.includes(cat, 'Uncategorized'));

  if (isUncategorizedProduct) {
    const result = !!(
      (product?.type &&
        _.find(combinedConditions, (s) => _.includes(s, product.type)) &&
        (!product?.subcategory || product.subcategory === '')) ||
      (product?.productCategory &&
        _.find(combinedConditions, (s) => _.includes(s, product.productCategory)) &&
        (!product?.productSubcategory || product.productSubcategory === ''))
    );
    // legacy match
    const legacyResult =
      (product?.type &&
        _.includes(rule?.categoryName, product.type) &&
        (!product?.subcategory || product.subcategory === '')) ||
      (product?.productCategory &&
        _.includes(rule?.categoryName, product.productCategory) &&
        (!product?.productSubcategory || product.productSubcategory === ''));
    return result || legacyResult;
  }

  const matchesCategories =
    (product?.type && _.includes(categoryCondition, product.type)) ||
    // 'product' coming from eligibleItems has a dumb name
    (product?.productCategory && _.includes(categoryCondition, product.productCategory)) ||
    matchesPOSMetaDataCategory(categoryCondition, product);

  const matchesSubcategories =
    (product?.subcategory && matchesSubcategory(subcategoryCondition, product)) ||
    (!!_.find(rule.subcategoryIds, (s) => _.includes(s, product.type)) && !product.subcategory) ||
    (!!_.find(rule.subcategoryNames, (s) => _.includes(s, product.type)) && !product.subcategory) ||
    // 'product' coming from eligibleItems has a dumb name
    (product?.productSubcategory && _.includes(subcategoryCondition, product.productSubcategory));

  const matchesLegacyCategories =
    (rule?.categoryName && product?.type && rule.categoryName === product.type) ||
    (rule?.categoryName && product?.productCategory && rule.categoryName === product.productCategory);

  const matchesLegacySubcategories =
    (rule?.categoryName && product?.subcategory && matchesSubcategory([rule.categoryName], product)) ||
    // 'product' coming from eligibleItems has a dumb name
    (rule?.categoryName && product?.productSubcategory && rule.categoryName === product.productSubcategory);

  return !!(matchesCategories || matchesSubcategories || matchesLegacyCategories || matchesLegacySubcategories);
}

export function matchesInventoryTag(
  rule = {},
  product = {},
  failIfNoCriteria = false,
  useActiveBatchTags = false,
  useActiveBatchTagOfWeightOption = false
) {
  if (!useActiveBatchTags) {
    return !failIfNoCriteria;
  }
  if (!rule?.inventoryTags?.length) {
    return !failIfNoCriteria;
  }

  let activeBatchTags;
  if (useActiveBatchTagOfWeightOption) {
    activeBatchTags = _.flatten(
      product?.POSMetaData?.children?.map((child) => child?.activeBatchTags?.map((tag) => tag.tagId))
    );
  } else {
    activeBatchTags = _.map(product?.POSMetaData?.activeBatchTags || [], 'tagId');
  }
  return !_.isEmpty(_.intersection(rule.inventoryTags || [], activeBatchTags));
}

export function matchesProductTag(rule = {}, product = {}, failIfNoCriteria = false, useActiveBatchTags = false) {
  if (!useActiveBatchTags) {
    return !failIfNoCriteria;
  }
  if (!rule?.productTags?.length) {
    return !failIfNoCriteria;
  }

  const productTags = _.map(product?.POSMetaData?.canonicalProductTags || [], 'tagId');
  return !_.isEmpty(_.intersection(rule.productTags || [], productTags));
}

export function matchesSubcategory(subcategories, product) {
  return !!_.filter(subcategories, (subcategory) => {
    const isParentChildSubcategoryKey = subcategory.match(/^.*__.*$/i);

    if (isParentChildSubcategoryKey) {
      const subcategoryKey = `${product?.type}__${product?.subcategory}`;
      return _.isEqual(subcategory, subcategoryKey);
    }

    return _.isEqual(subcategory, product?.subcategory);
  }).length;
}

export function matchesStrain(rule = {}, product = {}, failIfNoCriteria = false) {
  if (!rule?.strainIds?.length) {
    return !failIfNoCriteria;
  }

  const eligibleStrainIds = [...(rule?.strainIds ?? [])];

  const strainMatches = matchesPOSMetaDataStrain(eligibleStrainIds, product);

  return !!strainMatches;
}

export function matchesVendor(rule = {}, product = {}, failIfNoCriteria = false) {
  if (!rule?.vendorIds?.length) {
    return !failIfNoCriteria;
  }

  const eligibleVendorIds = [...(rule?.vendorIds ?? [])];

  const vendorMatches = matchesPOSMetaDataVendor(eligibleVendorIds, product);

  return !!vendorMatches;
}

function matchesPOSMetaDataProduct(productCriteria, product) {
  const posMetaDataCanonicalIds = [];

  if (product?.POSMetaData?.canonicalID) {
    posMetaDataCanonicalIds.push(product.POSMetaData.canonicalID);
  }

  _.forEach(product?.POSMetaData?.children, (child) => {
    if (child?.canonicalID) {
      posMetaDataCanonicalIds.push(child.canonicalID);
    }
  });

  return _.intersection(productCriteria, _.uniq(posMetaDataCanonicalIds)).length > 0;
}

export function matchesProductId(rule = {}, product = {}, failIfNoCriteria = false) {
  const productCriteria = [...(rule.productIds ?? []), ...(rule.enterpriseProductIds ?? [])];

  if (_.isEmpty(productCriteria)) {
    return !failIfNoCriteria;
  }

  return (
    _.includes(productCriteria, product._id || product.id) ||
    _.includes(productCriteria, `EPID_${product.enterpriseProductId}`) ||
    matchesPOSMetaDataProduct(productCriteria, product)
  );
}
