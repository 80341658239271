import React from 'react';
import { Flex } from 'rebass/styled-components';
import { useForm, Controller } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { useYupValidationResolver } from 'src/hooks/use-yup-resolver';
import styled from 'styled-components';
import useErnie from 'shared/hooks/use-ernie';
import useUI from 'shared/hooks/use-ui';
import { useStores } from 'src/hooks/use-stores';
import { useErnieOnSubmitFormErrors } from 'src/utils/form';
import { TextInput } from 'shared/components';
import { ModalContainer, ModalButton, ModalCopy, ModalSecondaryHeader, ModalClose } from 'shared/modals';
import { forgotPasswordEmailResetSchema, ForgotPasswordEmailResetFormInput } from './schema';

type RequestResetPasswordEmailProps = {
  onClose?: () => void;
  onSuccess?: () => void;
};
export function RequestResetPasswordEmail({ onSuccess, onClose }: RequestResetPasswordEmailProps): JSX.Element {
  const UI = useUI();
  const { User } = useStores();
  const showErnie = useErnie();
  const { handleFormErrors } = useErnieOnSubmitFormErrors();
  const resolver = useYupValidationResolver(forgotPasswordEmailResetSchema);
  const { control, handleSubmit, formState } = useForm<ForgotPasswordEmailResetFormInput>({
    resolver,
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });
  const { isSubmitting } = formState;

  function handleClose(): void {
    if (onClose) {
      onClose();
    }
  }

  async function handleSendResetEmail(data: ForgotPasswordEmailResetFormInput): Promise<void> {
    const result = await User.sendPasswordResetEmail(data.email);
    if (result.success) {
      showErnie('An email has been sent with instructions on how to reset your password.', 'success');
      UI.showRequestPasswordResetEmail = false;
      if (onSuccess) {
        onSuccess();
      }
    } else {
      showErnie('Something went wrong. Please contact support.', 'danger');
      datadogLogs.logger.error('Error sending password reset email', {
        pageName: 'RequestResetPasswordEmail',
        error: result.err,
      });
    }
  }

  return (
    <ModalContainer
      isOpen
      onRequestClose={handleClose}
      data-cy='reset-password-modal'
      data-test='reset-password-modal'
      width={['100%', '100%', '100%', '453px']}
    >
      <ModalSecondaryHeader>Reset Your Password</ModalSecondaryHeader>
      <StyledModalCopy>
        Enter the email address associated with your account, and we'll email you a link to reset your password.
      </StyledModalCopy>
      <Flex flexDirection={['column', 'column', 'column', 'row']} mt='25px'>
        <form onSubmit={handleSubmit(handleSendResetEmail, handleFormErrors)}>
          <Controller
            name='email'
            control={control}
            render={(props) => (
              <StyledInput
                data-cy='reset-email-input'
                data-test='reset-email-input'
                {...props}
                placeholder='Email address'
                m={['5px auto', '5px auto', '5px auto', '0 18px 0 0']}
              />
            )}
          />
          <ModalButton
            type='submit'
            loading={isSubmitting}
            mt='25px'
            width={['100%', '100%', '100%', '100px']}
            buttonContainerWidth='auto'
            data-cy='reset-submit-button'
            data-test='reset-submit-button'
          >
            Submit
          </ModalButton>
        </form>
      </Flex>
      {onClose && <ModalClose onClick={handleClose} />}
    </ModalContainer>
  );
}

const StyledModalCopy = styled(ModalCopy)`
  max-width: 354px;
`;

const StyledInput = styled(TextInput)<{ m: string[] }>`
  text-align: center;
  width: 230px;
`;
