import _ from 'lodash';

// eslint-disable-next-line import/no-cycle
import { getProductWeight } from 'shared/helpers/products';
import {
  matchesBrand,
  matchesCategory,
  matchesInventoryTag,
  matchesProductId,
  matchesProductTag,
  matchesStrain,
  matchesVendor,
} from 'shared/order/bogo/common';

/* **************************
 * A set of helpers specifically for 3.5 specials
 *************************** */

// Accepts a product, a set of restrictions (bogoConditions, bogoRewards, saleDiscounts), and a special object
// Returns an object with a boolean for whether any option was found, along with the eligible set per restriction
export function checkProductForEligibleOptions(product = {}, restrictions = [], special = {}) {
  const eligibleProductOptionsPerRestriction = _.map(restrictions, (restriction) =>
    eligibleProductOptionsForRestriction(product, restriction, 'inclusion', special, special.useActiveBatchTags)
  );
  let productHasEligibleOption = false;

  _.forEach(eligibleProductOptionsPerRestriction, (options) => {
    if (options.length > 0) {
      productHasEligibleOption = true;
      return false;
    }
    return true;
  });

  return { productHasEligibleOption, eligibleProductOptionsPerRestriction };
}

// Accepts a special. Returns true or false depending on if the special is v3.5
export function isSpecialVersion3dot5(special = {}) {
  return (
    (special?.version === 3 && special?.minorVersion === 5) ||
    // TODO: remove this once minorVersion is being added by Arma discountSync as 5
    isArmageddonDiscountSyncSpecial(special)
  );
}

// Accepts a special. Returns true or false depending on if the special is created or updated by discount sync v2
export function isArmageddonDiscountSyncSpecial(special = {}) {
  return special?.createdBy === 'ArmageddonDiscountSync' || special?.updatedBy === 'ArmageddonDiscountSync';
}

// Accepts a product, a restriction object (bogoCondition, bogoRewards, saleDiscount, or an exclusion),
//    a restrictionType ('inclusion' or 'exclusion'), and a special object
// Returns an array of all eligible product options (weights) for that restriction
export function eligibleProductOptionsForRestriction(
  product = {},
  restriction = {},
  restrictionType = 'inclusion',
  special = {},
  useActiveBatchTags = false
) {
  // Totally skip the restriction if 'IGNORE' is present
  if (restriction.productIds?.[0] === 'IGNORE') {
    return [];
  }

  const matchOperator = getMatchOperator(restriction, restrictionType);
  let eligibleOptions = [];

  // Only set eligibleOptions in 'or' matchOperator cases at this point.
  // Parsing of weights for comparison isn't needed in 'and' cases if the non-weight restrictions aren't matched to begin with.
  // Also if none of the non-weight restrictions are matched this option set will be used as the eligible option set
  if (matchOperator === 'or') {
    eligibleOptions = getMatchingProductWeightOptions(product, restriction, true, matchOperator, special);
  }

  // Check for non-weight restrictions
  const { isMatch: isRestrictionMatch, isNonProductIdMatch } = matchesRestriction(
    product,
    // In matchOperator 'or' cases combined with directPOSProductIdMapping, we want to bypass checking productIds on the
    // restriction because they have already been considered in the previous getMatchingProductWeightOptions check
    // as a result of the "one POS ID to one weight option" relation.
    matchOperator === 'or' && special?.directPOSProductIdMapping ? { ...restriction, productIds: [] } : restriction,
    restrictionType,
    useActiveBatchTags,
    special?.useActiveBatchTagOfWeightOption
  );

  // Since only matchOperator 'or' cases could have populated the eligibleOptions array at this point, we can safely check it's length here via or
  if (isRestrictionMatch || eligibleOptions.length > 0) {
    // Set eligibleOptions for matchOperator 'and' cases since we did not do so earlier, with all options eligible if there are no weight criteria
    if (matchOperator === 'and') {
      eligibleOptions = getMatchingProductWeightOptions(product, restriction, false, matchOperator, special);
    } else if (isRestrictionMatch) {
      // In matchOperator 'or' cases, if there was a matching non-weight restriction all possible weight options are
      // eligible so we bypass checking specific weight criteria here.
      const adjustedRestriction = {
        productIds: restriction?.productIds ?? [],
        weights: [],
        inventoryTags: restriction?.inventoryTags ?? [],
      };
      // For directPOSProductIdMapping, options tied to productIds will still be considered unless we've matched on
      // restriction criteria outside productIds, in which case we bypass checking productIds as weight options here
      if (isNonProductIdMatch && special?.directPOSProductIdMapping) {
        adjustedRestriction.productIds = [];
      }
      eligibleOptions = getMatchingProductWeightOptions(product, adjustedRestriction, false, matchOperator, special);
    }

    let excludedOptions = [];
    if (!_.isEmpty(restriction.exclusions)) {
      excludedOptions = _.union(
        ..._.map(restriction.exclusions, (exclusion) =>
          eligibleProductOptionsForRestriction(product, exclusion, 'exclusion', special, useActiveBatchTags)
        )
      );
    }

    if (!_.isArray(eligibleOptions)) {
      eligibleOptions = _.uniq([...eligibleOptions.filteredOptions, ...eligibleOptions.filteredRawOptions]);
    }
    // Remove excluded options from the resulting eligible set
    return _.difference(eligibleOptions, excludedOptions);
  }

  return [];
}

// Accepts a product, restriction (bogoCondition, bogoReward, saleDiscount, or an exclusion), and a restrictionType (inclusion or exclusion)
// Returns an object with booleans indicating whether the product matches restriction criteria on productIds or non-productIds fields
function matchesRestriction(
  product = {},
  restriction = {},
  restrictionType = 'inclusion',
  useActiveBatchTags = false,
  useActiveBatchTagOfWeightOption = false
) {
  if (restriction.productGroup === 'all') {
    return { isMatch: true, isNonProductIdMatch: true };
  }

  const matchOperator = getMatchOperator(restriction, restrictionType);

  // For 'or' cases, we should only be returning true if criteria is present and a match
  // For 'and' cases, if criteria is not present it is still considered a match
  const failIfNoCriteria = matchOperator === 'or';

  if (matchOperator === 'or') {
    if (
      matchesBrand(restriction, product, 'brand', failIfNoCriteria) ||
      matchesCategory(restriction, product, 'category', failIfNoCriteria) ||
      matchesStrain(restriction, product, failIfNoCriteria) ||
      matchesVendor(restriction, product, failIfNoCriteria) ||
      matchesInventoryTag(
        restriction,
        product,
        failIfNoCriteria,
        useActiveBatchTags,
        useActiveBatchTagOfWeightOption
      ) ||
      matchesProductTag(restriction, product, failIfNoCriteria, useActiveBatchTags)
    ) {
      return { isMatch: true, isNonProductIdMatch: true };
    }

    if (matchesProductId(restriction, product, failIfNoCriteria)) {
      return { isMatch: true, isNonProductIdMatch: false };
    }

    return { isMatch: false, isNonProductIdMatch: false };
  }

  const brandMatch = matchesBrand(restriction, product, 'brand', failIfNoCriteria);
  const categoryMatch = matchesCategory(restriction, product, 'category', failIfNoCriteria);
  const productMatch = matchesProductId(restriction, product, failIfNoCriteria);
  const strainMatch = matchesStrain(restriction, product, failIfNoCriteria);
  const vendorMatch = matchesVendor(restriction, product, failIfNoCriteria);
  const productTagMatch = matchesProductTag(restriction, product, failIfNoCriteria, useActiveBatchTags);
  const inventoryTagMatch = matchesInventoryTag(
    restriction,
    product,
    failIfNoCriteria,
    useActiveBatchTags,
    useActiveBatchTagOfWeightOption
  );

  const nonProductMatch =
    brandMatch && categoryMatch && strainMatch && vendorMatch && productTagMatch && inventoryTagMatch;
  return {
    isMatch: productMatch && nonProductMatch,
    isNonProductIdMatch: nonProductMatch,
  };
}

// Accepts a product restriction object (bogoCondition, bogoReward, saleDiscount, or an exclusion) and a restrictionType (inclusion or exclusion)
// Returns the matchOperator designating logic between product group restrictions ("and" or "or")
function getMatchOperator(restriction = {}, restrictionType = 'inclusion') {
  const defaultMatchOperator = restrictionType === 'inclusion' ? 'and' : 'or';
  return restriction?.matchOperator ? restriction.matchOperator : defaultMatchOperator;
}

// Accepts a product, restriction object (bogoCondition, bogoReward, saleDiscount, or an exclusion), boolean to define
//    return behavior if no weight criteria is present, matchOperator ('and' or 'or'), and special object
// Returns an array of weight options that the product matches based on the restriction's weight criteria
function getMatchingProductWeightOptions(
  product = {},
  restriction,
  returnEmptyIfNoCriteria = false,
  matchOperator = 'and',
  special = {}
) {
  const { weights = [], weightOperator = 'equalTo' } = restriction;
  const weightConstraints = _.compact(weights); // remove nil values
  const activeBatchTagsConstraints = special?.useActiveBatchTagOfWeightOption ? restriction.inventoryTags ?? [] : [];
  let productOptions = [];

  let rawProductOptions = [];

  if (!_.isEmpty(product.Options)) {
    productOptions = [...product.Options];
  } else if (product.option) {
    productOptions.push(product.option);
  }

  if (special?.rawPOSWeightMapping) {
    if (!_.isEmpty(product.rawOptions)) {
      rawProductOptions = [...product.rawOptions];
    } else if (product.rawOption) {
      rawProductOptions.push(product.rawOption);
    }
  }

  // No weight or activeBatchTags constraints or 'Any Weight'
  if (
    (_.isEmpty(weightConstraints) || _.includes(weightConstraints, 'Any Weight')) &&
    _.isEmpty(activeBatchTagsConstraints)
  ) {
    // returnEmptyIfNoCriteria: true is used for initial 'or' match comparison, where we want the resulting option set
    // to be empty unless a product is explicitly a part of the inclusion / exclusion set due to weight criteria
    const optionsWhenNoCriteria = returnEmptyIfNoCriteria ? [] : productOptions;

    // For discount sync v2 created specials with directPOSProductIdMapping enabled, any productIds on the restriction
    // are also technically weight constraints due to the "one POS ID to one weight option" relation. As such we need to
    // determine the inferred options when product child canonicalIDs match any productIds on the restriction.
    // Otherwise, we simply return optionsWhenNoCriteria
    return special?.directPOSProductIdMapping
      ? getOptionsFromPOSProductIds(product, optionsWhenNoCriteria, restriction, special)
      : optionsWhenNoCriteria;
  }

  // If activeBatchTagsConstraints are present, we need to filter the productOptions by activeBatchTags
  if (!_.isEmpty(activeBatchTagsConstraints) && special?.useActiveBatchTagOfWeightOption) {
    const { filteredOptions, filteredRawOptions } = getOptionsFromActiveBatchTags(
      product,
      productOptions,
      rawProductOptions,
      restriction
    );
    // If no weight constraints are present, return the options filtered by activeBatchTags
    if (_.isEmpty(weightConstraints)) {
      return { filteredOptions, filteredRawOptions };
    }
    // Otherwise, continue with the weight constraint filtering
    productOptions = filteredOptions;
    rawProductOptions = filteredRawOptions;
  }

  const checkEqualTo =
    _.isNil(weightOperator) || weightOperator === 'equalTo' || weightOperator === 'greaterThanEqualTo';
  const checkGreaterThan = weightOperator === 'greaterThan' || weightOperator === 'greaterThanEqualTo';
  const weightConstraintsInGrams = _.map(weightConstraints, (constraint) => getProductWeight(constraint));

  // Filter options by equalTo, greaterThan, or greaterThanEqualTo weight criteria
  const weightFilteredOptions = _.reduce(
    productOptions,
    (matchingProductOptions, productOption, optionIndex) => {
      const productOptionInGrams = getProductWeight(productOption);
      // Grab the rawProductOption at the same index as the productOption
      const rawProductOption = rawProductOptions[optionIndex] ?? null;
      const rawProductOptionInGrams = rawProductOption ? getProductWeight(rawProductOptions[optionIndex]) : null;

      _.forEach(weightConstraintsInGrams, (weightConstraintInGrams, index) => {
        if (
          checkEqualTo &&
          // Try to match the string option directly first before checking weight conversion
          (productOption === weightConstraints[index] ||
            productOptionInGrams === weightConstraintInGrams ||
            rawProductOption === weightConstraints[index] ||
            rawProductOptionInGrams === weightConstraintInGrams ||
            (weightConstraints[index] === 'Any Weight' && special?.useActiveBatchTagOfWeightOption))
        ) {
          matchingProductOptions.push(productOption);
          return false; // exit early
        }
        if (
          checkGreaterThan &&
          (productOptionInGrams > weightConstraintInGrams || rawProductOptionInGrams > weightConstraintInGrams)
        ) {
          matchingProductOptions.push(productOption);
          return false; // exit early
        }
        return true; // consistent return
      });

      return matchingProductOptions;
    },
    []
  );

  // For discount sync v2 created specials with directPOSProductIdMapping enabled...
  if (special?.directPOSProductIdMapping) {
    // Retrieve any inferred options due to productIds on the restriction
    const posProductIdFilteredOptions = getOptionsFromPOSProductIds(product, productOptions, restriction, special);
    // Merge the POS product ID options with those derived from specific weight criteria
    const combinedFilteredOptions =
      matchOperator === 'or'
        ? // In matchOperator 'or' cases, we want the unique list of all weights found in either set
          _.union(posProductIdFilteredOptions, weightFilteredOptions)
        : // In matchOperator 'and' cases, we want the unique list of all weights found in both sets
          _.intersection(posProductIdFilteredOptions, weightFilteredOptions);

    // Sort the results based on original productOptions order
    return _.intersection(productOptions, combinedFilteredOptions);
  }

  // Otherwise return the options filtered by weight criteria alone, which are already in the correct order
  return weightFilteredOptions;
}

// Accepts a product, set of productOptions, and restriction object
// Returns either the set of options applicable for this product based on child canonicalIDs and restriction criteria
//    or the productOptions unaltered if no productIds are present on the restriction
function getOptionsFromPOSProductIds(product = {}, productOptions = [], restriction = {}) {
  const productCriteria = [...(restriction.productIds ?? [])];
  if (_.isEmpty(productCriteria)) {
    return productOptions;
  }

  return _.reduce(
    product.POSMetaData?.children,
    (options, child) => {
      if (_.includes(productCriteria, child?.canonicalID)) {
        options.push(child.option);
      }
      return options;
    },
    []
  );
}

function getOptionsFromActiveBatchTags(product = {}, productOptions = [], rawOptions = [], restriction = {}) {
  const qualifiedTags = [...(restriction.inventoryTags ?? [])];
  if (_.isEmpty(qualifiedTags)) {
    return { productOptions, rawOptions };
  }

  const filterOptions = (options, getOptionValue) =>
    _.reduce(
      options,
      (filteredOptions, option) => {
        const childProduct = product.POSMetaData?.children?.find((child) => child.option === getOptionValue(option));
        const childTags = childProduct?.activeBatchTags?.map((tag) => tag.tagId);
        if (_.intersection(qualifiedTags, childTags).length > 0) {
          filteredOptions.push(option);
        }
        return filteredOptions;
      },
      []
    );

  const filteredOptions = filterOptions(productOptions, (option) => option);
  const filteredRawOptions = filterOptions(rawOptions, getProductWeight);

  return { filteredOptions, filteredRawOptions };
}
