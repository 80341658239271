import React, { SVGAttributes } from 'react';

/* eslint-disable max-len */

export function CheckmarkStroke(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.316 4.19942L14.6752 3.84401L14.316 3.4886L12.7051 1.89459L12.3534 1.54659L12.0017 1.89459L5.97463 7.85856L3.42687 5.33748L3.07518 4.98948L2.72349 5.33748L1.11261 6.9315L0.753436 7.28691L1.11261 7.64232L5.62294 12.1054L5.97463 12.4534L6.32631 12.1054L14.316 4.19942Z'
        fill='white'
        stroke='black'
        strokeLinecap='square'
      />
    </svg>
  );
}
