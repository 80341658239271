import React from 'react';

export function BackToAdmin(props) {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='13' height='13'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0 0H12.9863V13H0V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0033 9.14333H3.85681V0.996667H12.0179L12.0033 9.14333ZM9.14333 12.0033H0.996667V3.85667H2.86V9.64889C2.86 9.92333 3.07667 10.14 3.35126 10.14H9.14333V12.0033ZM12.5089 0H3.35126C3.07667 0 2.86 0.216667 2.86 0.491111V2.86H0.491111C0.216667 2.86 0 3.07667 0 3.35111V12.4944C0 12.7833 0.216667 13 0.491111 13H9.63459C9.90889 13 10.1256 12.7833 10.1256 12.5089V10.14H12.4944C12.769 10.14 12.9857 9.92333 12.9857 9.64889V0.491111C13 0.216667 12.7833 0 12.5089 0Z'
          fill='white'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 2.52442V5.79434C11 6.0874 10.7685 6.31877 10.4752 6.31877C10.182 6.31877 9.95048 6.0874 9.95048 5.79434V3.7892L5.89132 7.84576C5.78328 7.95373 5.65981 8 5.5209 8C5.38199 8 5.24309 7.95373 5.15048 7.84576C4.94984 7.64524 4.94984 7.30591 5.15048 7.08997L9.19421 3.04884H7.18778C6.89438 3.04884 6.66287 2.81748 6.66287 2.52442C6.66287 2.23136 6.89438 2 7.18778 2H10.4598C10.4907 2 10.537 2 10.5678 2.01542C10.5833 2.01542 10.5986 2.03085 10.6141 2.03085C10.6913 2.0617 10.7685 2.09254 10.8301 2.15424C10.892 2.21594 10.9383 2.29306 10.9537 2.37018C10.9537 2.3856 10.9691 2.40103 10.9691 2.41645C11 2.4473 11 2.49357 11 2.52442Z'
        fill='white'
      />
    </svg>
  );
}
