import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { CopyIcon } from 'src/svg/copy-icon';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';

type CopyBoxProps = {
  contentToCopy: string;
  placeholderText?: ReactNode;
  displayContent?: boolean;
  linkText?: string;
  className?: string;
  displayFlex?: boolean;
};

export function CopyBox({
  contentToCopy,
  placeholderText,
  displayContent = true,
  linkText = 'Copy Script',
  className,
  displayFlex,
}: CopyBoxProps): JSX.Element {
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopyClick = (): void => {
    copyToClipboard(contentToCopy);
  };

  return (
    <Container showPlaceholderText={!displayContent} className={className} displayFlex={displayFlex}>
      {displayContent && (
        <>
          <ContentToCopyText>
            {contentToCopy.split('\n').map((item) => (
              <div key={item}>{item}</div>
            ))}
          </ContentToCopyText>
          <CopyLink onClick={handleCopyClick} displayFlex={displayFlex}>
            <CopyIcon /> {linkText}
          </CopyLink>
        </>
      )}
      {!displayContent && <PlaceholderText>{placeholderText}</PlaceholderText>}
    </Container>
  );
}

const Container = styled.div<{ showPlaceholderText: boolean; displayFlex?: boolean }>`
  padding: 15px 23px 17px;
  background-color: #f3f6f8;
  max-width: 503px;
  border-radius: 3px;
  font-size: 13px;
  ${({ showPlaceholderText }) =>
    showPlaceholderText &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 123px;
    `}
  ${({ displayFlex }) =>
    displayFlex &&
    css`
      display: flex;
      gap: 20px;
    `}
`;

const PlaceholderText = styled.p`
  font-size: 13px;
  color: #969ea5;
`;

const ContentToCopyText = styled.div`
  color: #7c7f83;
  line-height: 18px;
  overflow-wrap: break-word;
`;

const CopyLink = styled.a<{ displayFlex?: boolean }>`
  color: #677882;
  font-weight: bold;
  margin-top: ${({ displayFlex }) => (displayFlex ? '0' : '14px')};
  display: inline-flex;
  align-items: center;
  ${({ displayFlex }) =>
    displayFlex &&
    css`
      margin-left: auto;
    `}
  svg {
    margin: 2px 6px 0 0;
  }
  :hover {
    cursor: pointer;
    color: #454e50;
    svg path {
      fill: #454e50;
    }
  }
`;
