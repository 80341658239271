import _ from 'lodash';
import Big from 'big.js';
import moment from 'moment-timezone';
import { isBlank } from 'underscore.string';
import { remove as removeDiacritics } from 'diacritics';
import { formatName } from 'shared/helpers/users';
import { integrationDisplayName } from 'shared/helpers/integrations';
import {
  defaultDurationEstimates,
  archiveReasons,
  paymentMethodsToDisplayNamesAdmin,
  CURBSIDE_PICKUP_SPECIAL_INSTRUCTIONS,
  DRIVE_THRU_PICKUP_SPECIAL_INSTRUCTIONS,
  SPECIAL_INSTRUCTIONS_MAX_LENGTH,
} from 'shared/constants';
import { getDefaultRewardsProgramDisplayName } from 'shared/core/helpers/dispensaries';
import { hasNonCashPaymentOptions } from './dispensaries';

export const getCustomerEmail = (order) => {
  let emailAddress = _.get(order, 'customer.profile.email', null);
  if (emailAddress) {
    return emailAddress;
  }

  emailAddress = _.get(order, 'customer.emails[0].address', null);
  if (emailAddress) {
    return emailAddress;
  }

  if (order.guestCustomer?.email) {
    return order.guestCustomer.email;
  }

  return null;
};

export const getCustomerNameForOrder = (order) => {
  if (order.customer?.profile) {
    return formatName(order.customer?.profile);
  }

  if (order.guestCustomer) {
    return formatName(order.guestCustomer);
  }

  return 'unknown';
};

export const getCustomerPhoneForOrder = (order) => {
  if (order.customer?.profile?.phone) {
    return order.customer.profile.phone;
  }

  if (order.guestCustomer?.phone) {
    return order.guestCustomer.phone;
  }

  return 'Unknown';
};

export const getOrderDateAndTime = (order) => {
  const timezone = order.dispoTimezone || order.dispensary?.timezone || `America/Los_Angeles`;

  let orderTime;
  if (order.createdAt instanceof Date) {
    orderTime = moment.tz(order.createdAt, timezone);
  } else {
    orderTime = moment.tz(parseInt(order.createdAt, 10), timezone);
  }

  const date = orderTime.format(`M/DD/YYYY`);
  const time = orderTime.format(`h:mm A`);

  return `${date} at ${time}`;
};

export const getDurationDisplayValue = (durationMinutes, shortened = false) => {
  if (!_.isFinite(durationMinutes)) {
    return '-';
  }
  if (durationMinutes === 0 || durationMinutes < 2) {
    return shortened ? '1m' : '1 min';
  }
  if (durationMinutes > 60) {
    return `${moment.duration(durationMinutes, 'minutes').hours()}h ${durationMinutes % 60}${shortened ? 'm' : 'mins'}`;
  }
  return `${durationMinutes}${shortened ? ' m' : ' mins'}`;
};

export const getTimestampInfo = (order) => {
  const timezone = order.dispoTimezone || order.dispensary?.timezone || 'America/Los_Angeles';
  const orderTime = moment.tz(order.createdAt, timezone);
  let timeDisplay;

  if (moment().diff(orderTime, 'minutes') < 1) {
    timeDisplay = `${moment().diff(orderTime, 'seconds')} seconds ago`;
    if (timeDisplay.charAt(0) === '-') {
      timeDisplay = 'just now';
    }
  } else {
    const duration = moment.duration(moment().diff(orderTime, 'minutes'), 'minutes');

    if (duration.years() > 0 || duration.months() > 0) {
      timeDisplay = `> ${duration.humanize()}`;
    } else if (duration.days() > 0) {
      timeDisplay = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ago`;
    } else if (duration.hours() > 0) {
      timeDisplay = `${duration.hours()}h ${duration.minutes()}m ago`;
    } else {
      timeDisplay = `${duration.minutes()} minutes ago`;
    }
  }
  let estimate;
  if (order.durationEstimates) {
    estimate = order.durationEstimates[order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup'];
  }

  if (!estimate) {
    estimate = defaultDurationEstimates[order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup'];
  }
  const estimateCeiling = orderTime.clone().add(estimate.highInMinutes, 'minutes');

  return {
    timeDisplay,
    isLate: estimateCeiling.isBefore(moment()),
  };
};

export const getProductNameForLineItem = (item, dispensary) => {
  const { product, option = 'N/A' } = item;

  if (dispensary?.ordersConfig?.posItemNames) {
    if (product.POSMetaData?.children?.length) {
      const childProduct = _.find(product.POSMetaData.children, { option });
      if (childProduct && !isBlank(childProduct.canonicalName)) {
        return childProduct.canonicalName;
      }
    }

    if (!isBlank(product.POSMetaData?.canonicalName)) {
      return product.POSMetaData.canonicalName;
    }
    if (!isBlank(product.integrationKey)) {
      return product.integrationKey;
    }
  }
  return product.Name || '';
};

export const getMedCardString = (order) => {
  const medCard = order.medicalCard || _.get(order, 'customer.profile.medicalCard', null);
  if (!medCard) {
    const orderType = order.deliveryInfo.deliveryOption ? 'delivery' : 'pickup';
    return `Will present upon ${orderType}`;
  }
  const { number } = medCard;
  const expirationDate = medCard?.expirationDate;
  if (expirationDate) {
    return `${number} - Exp: ${expirationDate}`;
  }
  return '';
};

export const getStatusText = (order) => {
  const { reopened, status } = order;
  const suffix = reopened ? ' (reopened)' : '';

  if (status === 'inTransit') {
    if (!order.deliveryInfo.deliveryOption) {
      return 'Awaiting Pickup';
    }
    return `In Transit${suffix}`;
  }
  if (status === 'confirmed') {
    return `Confirmed${suffix}`;
  }
  if (status === 'closed') {
    return 'Closed';
  }
  if (status === 'open') {
    return 'New';
  }

  return '';
};

export const getCustomerDOB = (order) => {
  if (order.isGuestOrder) {
    const { birthMonth, birthDay, birthYear } = order.guestCustomer;
    if (birthMonth && birthDay && birthYear) {
      return `${birthMonth}/${birthDay}/${birthYear}`;
    }

    return null;
  }

  return order.customer.profile.birthday;
};

export const getCustomerBirthdayForOrder = (order) => {
  if (order.customer?.profile?.birthday) {
    return moment(order.customer.profile.birthday, 'M-D-YYYY');
  }

  if (order.guestCustomer) {
    const { guestCustomer } = order;
    return moment(`${guestCustomer.birthMonth}-${guestCustomer.birthDay}-${guestCustomer.birthYear}`, 'M-D-YYYY');
  }

  return null;
};

export const getCustomerIdForOrder = (order) => {
  if (order.customer?.profile?.photoId) {
    return order.customer.profile.photoId;
  }

  if (order.guestCustomer?.photoId) {
    return order.guestCustomer.photoId;
  }

  return null;
};

export const getCustomerDriversLicenceForOrder = (order) => {
  if (order.customer?.profile?.driversLicense) {
    return order.customer.profile.driversLicense;
  }

  if (order.driversLicense) {
    return order.driversLicense;
  }

  return null;
};

export const getCustomerInfoFieldsForOrder = (order) => {
  const customerName = getCustomerNameForOrder(order);
  const customerPhone = getCustomerPhoneForOrder(order);
  const customerEmail = getCustomerEmail(order);
  const customerId = getCustomerIdForOrder(order);
  const customerBirthday = getCustomerBirthdayForOrder(order);
  const customerDriversLicense = getCustomerDriversLicenceForOrder(order);

  return { customerName, customerPhone, customerEmail, customerId, customerBirthday, customerDriversLicense };
};
/** @typedef {{ id: string | null, reason: string | null, customerMessage: string | null }} ArchiveReason  */
/**
 * @returns {ArchiveReason}
 */
export const getArchiveReason = ({ id, ...props }) =>
  _.find(archiveReasons(props), { id }) || { id: null, reason: null, customerMessage: null };

export const getUserContext = (context) => {
  const by = context.user._id || context.user.deviceId;
  const byType = context.user._id ? 'user' : 'device';
  const { agentName } = context.user;
  return { by, byType, agentName };
};

export const getScheduledOrderTime = ({ reservation, timezone }) => {
  if (!reservation?.startTimeISO) {
    return false;
  }
  const { startTimeISO, endTimeISO } = reservation;
  const startTimeMoment = moment.tz(startTimeISO, timezone);
  const endTimeMoment = moment.tz(endTimeISO, timezone);
  let prefix = startTimeMoment.format('M/D');
  const withMinsFormat = 'h:mma';
  const withoutMinsFormat = 'ha';

  const showMinutesFormat = (time) => time.minutes() !== 0;

  if (startTimeMoment.isSame(moment(), 'day')) {
    prefix = 'Today';
  } else if (startTimeMoment.isSame(moment().add(1, 'day'), 'day')) {
    prefix = 'Tomorrow';
  }

  return {
    dayOfWeek: startTimeMoment.format('dddd'),
    prefix,
    startTime: startTimeMoment.format(showMinutesFormat(startTimeMoment) ? withMinsFormat : withoutMinsFormat),
    endTime: endTimeMoment.format(showMinutesFormat(endTimeMoment) ? withMinsFormat : withoutMinsFormat),
  };
};

export const cleanStringForPrinter = (str) => {
  if (!str) {
    return '';
  }
  try {
    // replace accented characters with the most similar ascii character
    let cleanedString = removeDiacritics(str);

    // replace common fraction characters with ascii
    cleanedString = _.replace(cleanedString, /¼/g, ' 1/4');
    cleanedString = _.replace(cleanedString, /½/g, ' 1/2');
    cleanedString = _.replace(cleanedString, /¾/g, ' 3/4');

    // remove any remaining non-ascii
    // eslint-disable-next-line
    cleanedString = _.replace(cleanedString, /[^\x00-\x7F]/g, '');

    return cleanedString;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const formatReward = (reward, { storeSettings, location }) => {
  if (!reward) {
    return '';
  }

  const { copy, operator, value } = reward;

  let rewardPrefix =
    storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName({ location });

  const dollarText = operator === '$' ? operator : '';
  const percentText = operator === '%' ? operator : '';

  // Catch everything that is non-numeric, 0, or falsey
  rewardPrefix += !+value ? '' : ` ${dollarText}${value}${percentText}`;

  // TODO: add "for x pts" at the end here, if available (not currently in data)
  return `${rewardPrefix} discount applied (${copy})`;
};

export const formatRewardStatus = (reward, showRewardStatus) => {
  if (!reward || !showRewardStatus) {
    return '';
  }

  const { redemptionAttempts } = reward;

  const { status, redeemBy } = _.head(redemptionAttempts) || {};
  if (!status || !redeemBy) {
    return '';
  }
  const redeemStatus = status === 'pending' ? '- Redeemable at' : 'by';

  return `Reward ${_.capitalize(status)} ${redeemStatus} ${_.upperCase(redeemBy)}`;
};

export const getFormattedRewardData = ({ appliedRewards }, showRewardStatus, dispensary) => {
  const reward = _.head(appliedRewards);
  const formattedReward = formatReward(reward, dispensary);
  const formattedRewardStatus = formatRewardStatus(reward, showRewardStatus);

  return {
    formattedReward,
    formattedRewardStatus,
  };
};

export const getSpecialInstructions = (order, curbsideArrivalInfo, showRewardStatus, dispensary) => {
  const curbsideMessage = `${CURBSIDE_PICKUP_SPECIAL_INSTRUCTIONS} ${cleanStringForPrinter(
    curbsideArrivalInfo?.arrivalInformation
  )} `;
  const driveThruMessage = `${DRIVE_THRU_PICKUP_SPECIAL_INSTRUCTIONS} `;
  const { formattedReward, formattedRewardStatus } = getFormattedRewardData(order, showRewardStatus, dispensary);

  let specialInstructions = `${cleanStringForPrinter(order.specialInstructions)}`;
  if (!isBlank(formattedRewardStatus)) {
    specialInstructions = formattedRewardStatus.concat(specialInstructions);
  }
  if (!isBlank(formattedReward)) {
    specialInstructions = `${formattedReward} `.concat(specialInstructions);
  }
  if (order.isCurbsidePickupOrder) {
    specialInstructions = curbsideMessage.concat(specialInstructions);
  }
  if (order.isDriveThruPickupOrder) {
    specialInstructions = driveThruMessage.concat(specialInstructions);
  }

  return specialInstructions.substring(0, SPECIAL_INSTRUCTIONS_MAX_LENGTH);
};

export const formatPaymentMethod = ({ card, paymentMethod }, dispensary) => {
  const debitOrder =
    !dispensary.paymentTypesAccepted.creditCardAtDoor &&
    !dispensary.paymentTypesAccepted.creditCardByPhone &&
    !dispensary.paymentTypesAccepted.linx &&
    dispensary.paymentTypesAccepted.debit; // eslint-disable-line

  // old format payment info
  if (card) {
    return card === 'cash' ? 'Cash' : `${debitOrder ? 'Debit' : 'Credit'} Card`;
  }

  let method = _.get(paymentMethodsToDisplayNamesAdmin, paymentMethod, paymentMethod);

  if (method === 'Credit Card' && debitOrder) {
    method = 'Debit Card';
  }

  return method;
};

const acceptsDetailedPaymentTypes = (dispensary) =>
  dispensary.paymentTypesAccepted.payOnlineMerrco ||
  dispensary.paymentTypesAccepted.payOnlineMoneris ||
  dispensary.paymentTypesAccepted.payOnlineHypur ||
  dispensary.paymentTypesAccepted.dutchiePay ||
  dispensary.paymentTypesAccepted.payOnlineChase ||
  dispensary.paymentTypesAccepted.rethinkPay;

const hasDetailedPaymentInfo = (order) =>
  order && (!_.isEmpty(order.paysafe) || !_.isEmpty(order.hypur) || !_.isEmpty(order.moneris));

export const showFullPaymentOptions = ({ isDelivery, order }, dispensary) =>
  (isDelivery && hasNonCashPaymentOptions(dispensary)) ||
  hasDetailedPaymentInfo(order) ||
  acceptsDetailedPaymentTypes(dispensary) ||
  (hasNonCashPaymentOptions(dispensary) && !_.isEmpty(dispensary.paymentFees));

export const getOrderSyncFailureText = ({ pos }) => {
  const posName = integrationDisplayName(pos.name);

  return `this order couldn't be sent to ${posName}.${
    !isBlank(pos?.humanReadableError) ? ` ${pos.humanReadableError} ` : ' '
  }The order must be entered manually into ${posName}.`;
};

export const isWithinThreshold = ({ actual = 0, expected = 0, threshold = 0 }) => {
  const diff = Big(Big(expected).minus(actual)).abs();
  return diff.lte(Big(threshold));
};

export const willSendOrderReadySMS = (dispensary, order, textNotifications) => {
  const { deliveryInfo, guestCustomer } = order;
  const { messagingSettings } = dispensary;
  const { disableReadyForPickup, disableStartDelivery } = messagingSettings;
  const serviceType = deliveryInfo?.deliveryOption ? `delivery` : `pickup`;

  const customerCanBeNotified = textNotifications || guestCustomer?.phone;
  const willSendPickupText = customerCanBeNotified && !disableReadyForPickup && serviceType !== 'delivery';
  const willSendDeliveryText = customerCanBeNotified && !disableStartDelivery && serviceType === 'delivery';

  return willSendPickupText || willSendDeliveryText;
};
