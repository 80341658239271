import { useForm, Control, UseFormMethods, SubmitHandler } from 'react-hook-form';

import { useParams } from 'react-router-dom';

import useErnie from 'shared/hooks/use-ernie';
import { PreviewData } from '../components/preview';
import { useUpdateWebCustomizationSettingsV2, MenuCustomizationData } from '../data-access';
import { FontsFormProps } from './fonts.types';

type HandlePublish = ReturnType<UseFormMethods<FormValues>['handleSubmit']>;

type FormValues = {
  heading: string;
  body: string;
};

type UseFontsFormReturn = {
  control: Control<FormValues>;
  handlePublish: HandlePublish;
  handleReset: () => void;
  isDirty: boolean;
  previewData: PreviewData;
};

function useMergeDataWithFormValues(data: MenuCustomizationData, formValues: FormValues): PreviewData {
  const { heading, body } = formValues;

  return {
    ...data,
    fonts: {
      heading,
      body,
    },
  };
}

export function useFontsForm({ data }: FontsFormProps): UseFontsFormReturn {
  const {
    fonts: { heading, body },
  } = data;

  const defaultValues = {
    heading,
    body,
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<FormValues>({ defaultValues });

  const { id: dispensaryId } = useParams<{ id: string }>();

  const showErnie = useErnie();

  const updateData = useUpdateWebCustomizationSettingsV2();

  const onSubmit: SubmitHandler<FormValues> = async (submittedValues) => {
    const { heading: newHeadingFont, body: newBodyFont } = submittedValues;

    try {
      await updateData({
        variables: {
          dispensaryId,
          input: {
            fonts: {
              heading: newHeadingFont,
              body: newBodyFont,
            },
          },
        },
      });

      showErnie('Your fonts have been updated', 'success');
      reset({ ...submittedValues });
    } catch (err) {
      showErnie('Something went wrong, please try again.', 'danger');
      console.error(err);
    }
  };

  const handlePublish = handleSubmit(onSubmit);

  const handleReset = (): void => reset();

  const formValues = watch();

  const previewData = useMergeDataWithFormValues(data, formValues);

  return {
    control,
    handlePublish,
    handleReset,
    isDirty,
    previewData,
  };
}
