import _ from 'lodash';
import { isBlank } from 'underscore.string';

export const getCityToDisplay = (location) =>
  location?.rootType === 'locality' && location?.locality ? location?.locality : location?.city;

export const hasStreetNumber = (address) => {
  const re = /^\d+$/;
  return address ? re.test(_.split(address, ' ')[0]) : false;
};

export const convertMetersToMiles = (meters = 0.0) => meters * 0.000621371192;

export const buildLocationObjectFromGoogle = (data) => {
  const findByType = (components, type) => _.find(components, (comp) => _.includes(comp.types || [], type));

  return {
    streetNumber: _.get(findByType(data.address_components, 'street_number'), 'short_name'),
    streetName: _.get(findByType(data.address_components, 'route'), 'short_name'),
    ln1: [
      _.get(findByType(data.address_components, 'street_number'), 'short_name'),
      _.get(findByType(data.address_components, 'route'), 'short_name'),
    ].join(' '),
    ln2: _.get(findByType(data.address_components, 'subpremise'), 'short_name', ''),
    city:
      _.get(findByType(data.address_components, 'locality'), 'short_name') ||
      _.get(findByType(data.address_components, 'neighborhood'), 'short_name') ||
      _.get(findByType(data.address_components, 'administrative_area_level_3'), 'short_name') ||
      _.get(findByType(data.address_components, 'sublocality_level_1'), 'short_name'),
    county: _.get(findByType(data.address_components, 'administrative_area_level_2'), 'short_name'),
    state: _.get(findByType(data.address_components, 'administrative_area_level_1'), 'short_name'),
    zipcode: _.get(findByType(data.address_components, 'postal_code'), 'short_name'),
    country: _.get(findByType(data.address_components, 'country'), 'long_name'),
  };
};

export const formatAddress = (location) => {
  const { ln1, ln2, city, locality, state, zipcode, country } = location;

  const preferredAddressAttributesExist = !_.isEmpty(_.reject([ln1, ln2, city, locality, state, zipcode], isBlank));

  if (!preferredAddressAttributesExist && !isBlank(country)) {
    return country;
  }

  const cityPretty = getCityToDisplay(location);

  return _.reject([_.reject([ln1, ln2], isBlank).join(', '), cityPretty, [state, zipcode].join(' ')], isBlank).join(
    ', '
  );
};

export const formatAddressForCheckout = ({ ln1, ln2 }) => _.reject([ln1, ln2], isBlank).join(', ');

export const formatAddressShort = (location) => {
  const { ln1, ln2, state } = location;

  const cityPretty = getCityToDisplay(location);

  const displayFields = !ln1 ? [cityPretty, state] : [ln1, ln2];

  return _.reject(displayFields, isBlank).join(', ');
};

export const formatAddressWithoutApt = ({ ln1, city, state, zipcode, country }) => {
  const preferredAddressAttributesExist = !_.isEmpty(_.reject([ln1, city, state, zipcode], isBlank));

  if (!preferredAddressAttributesExist && !isBlank(country)) {
    return country;
  }

  return _.reject([_.reject([ln1], isBlank).join(', '), city, [state, zipcode].join(' ')], isBlank).join(', ');
};
