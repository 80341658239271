import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { isSuperAdmin, isChainAdmin } from 'shared/helpers/users';
import { mediaQueries } from 'shared/styles';
import PublicEnv from 'shared/utils/public-env';
import { useUser } from 'src/hooks/use-user';
import { openZendeskWidget } from 'shared/helpers/tools';

export function SidebarFooter(): JSX.Element {
  const location = useLocation();
  const user = useUser();
  const showDebugInfo = _.includes(['qa', 'staging'], window.location.host);

  const shouldShowStar =
    ((isSuperAdmin(user) && !(_.includes(location.pathname, '/super/') || location.pathname === '/super')) ||
      (isChainAdmin(user) && !(_.includes(location.pathname, '/chains/') || location.pathname === '/chains'))) &&
    !window.matchMedia(mediaQueries.desktop).matches;

  useEffect(() => {
    const headway = window.Headway;
    if (headway) {
      _.delay(() => {
        headway.init({
          selector: '#headway',
          account: '7X9QqJ',
          position: {
            x: 'right',
          },
        });
      }, 0);
    }
  }, []);

  return (
    <Container>
      {shouldShowStar && (
        <Link to='/'>
          <StarImage src='/images/superadmin-icon.svg' />
        </Link>
      )}

      <HeadWayContainer id='headway'>
        <Image src='/icons/dutchie-wordmark-white.svg' alt='Dutchie Logo' />
      </HeadWayContainer>

      <Section>
        <StyledLink onClick={openZendeskWidget}>Support</StyledLink>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <StyledLink href='https://status.dutchie.com' target='_BLANK'>
          Status
        </StyledLink>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <StyledLink onClick={() => user.logout()}>Logout</StyledLink>
      </Section>

      {showDebugInfo && (
        <Section>
          <StyledLink href={`https://github.com/GetDutchie/Dutchie/commit/${PublicEnv.currentVersion}`} target='_BLANK'>
            {PublicEnv.appEnv}
          </StyledLink>
        </Section>
      )}
    </Container>
  );
}

const HeadWayContainer = styled.div`
  position: relative;
  #HW_badge_cont {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -61px;
    margin-top: -22px;
  }
`;

const Container = styled.div`
  margin-bottom: 22px;
`;

const StarImage = styled.img`
  display: block;
  margin: 0 auto 10px auto;
  width: 140px;

  @media ${mediaQueries.desktop} {
    width: 90%;
  }
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 67px;

  @media ${mediaQueries.desktop} {
    width: 58px;
  }
`;

const Section = styled.div`
  font-size: 12px;
  margin-top: 11px;
  text-align: center;
  span {
    color: #5f937b;
  }
`;

const StyledLink = styled.a`
  color: #ceebe0;
  cursor: pointer;

  &:hover {
    color: #ceebe0;
    text-decoration: none;
  }
`;

const Link = styled(RouterLink)`
  color: #ceebe0;
  cursor: pointer;

  &:hover {
    color: #ceebe0;
    text-decoration: none;
  }
`;
