import { DeepMap, FieldErrors } from 'react-hook-form';
import { EmptyObject } from 'shared/utils/type-utils';
import useErnie from 'shared/hooks/use-ernie';
import { GqlDispensaries as Dispensary } from 'types/graphql';

// lots of type assertions here unfortunately. I'd love for this function
// to be rewritten in a better way. Pushing forward to alleviate dispensary issues.
export function getDirtyFormValues<ValuesType>(
  dirtyFields: DeepMap<ValuesType, true>,
  sourceData: ValuesType
): ValuesType {
  const finalData = {} as ValuesType;
  const objKeys = Object.keys(dirtyFields) as Array<keyof ValuesType>;

  objKeys.forEach((key) => {
    const value = dirtyFields[key] as DeepMap<ValuesType[keyof ValuesType], true>;

    if (value.constructor.name === 'Object') {
      finalData[key] = getDirtyFormValues<ValuesType[keyof ValuesType]>(value, sourceData[key]);
    } else {
      finalData[key] = sourceData[key];
    }
  });

  return finalData;
}
export function nullToUndefined<T>(_castValue: T, originalValue: T | null): T | undefined {
  return originalValue === null ? undefined : originalValue;
}

export function NaNToUndefined<T>(_castValue: T, originalValue: T | number | null): number | undefined {
  return Number.isNaN(originalValue) ? undefined : Number(originalValue);
}

export function removeNullValues(obj: Dispensary | EmptyObject): Partial<Dispensary> {
  return (
    Object.entries(obj)
      // Filter out all null values from the object entries array
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value != null)
      // Check if the value is an object, if it is we will recursively pass that value back through to be evaluated
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value === Object(value) ? removeNullValues(value) : value }), {})
  );
}

type UseErnieOnSubmitFormErrorsReturn = {
  handleFormErrors: (errors: FieldErrors) => void;
};

export function useErnieOnSubmitFormErrors(): UseErnieOnSubmitFormErrorsReturn {
  const showErnie = useErnie();

  function handleFormErrors(errors: FieldErrors): void {
    const { message } = errors[Object.keys(errors)[0]];
    showErnie(message, 'danger');
  }

  return { handleFormErrors };
}
