import React, { SVGAttributes } from 'react';

export function Matter(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='42' height='11' viewBox='0 0 42 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.819 10H2.73V4.709L4.979 10H6.474L8.71 4.709V10H10.621V0.899999H8.359L5.72 7.088L3.081 0.899999H0.819V10ZM15.8845 5.801V5.84L14.7015 5.957C12.8165 6.152 11.8675 6.854 11.8675 8.154C11.8675 9.246 12.7645 10.104 14.0385 10.104C14.7665 10.104 15.5075 9.74 15.8845 9.168V10H17.7305V6.113C17.7305 4.267 16.5215 3.344 14.7405 3.344C13.3365 3.344 12.1405 4.176 11.9325 5.463L13.5965 5.775C13.6615 5.138 14.1165 4.761 14.7405 4.761C15.4425 4.761 15.8845 5.151 15.8845 5.801ZM13.6875 8.089C13.6875 7.712 14.0125 7.387 14.6625 7.322L15.8845 7.179V7.231V7.257V7.296C15.8715 8.05 15.3645 8.739 14.5065 8.739C13.9735 8.739 13.6875 8.453 13.6875 8.089ZM15.8845 7.257V7.231V7.257ZM23.1423 9.935L23.0123 8.284C22.7913 8.401 22.5053 8.44 22.2713 8.44C21.8033 8.44 21.4913 8.102 21.4913 7.517V5.034H23.0773V3.448H21.4913V1.55H19.5803V3.448H18.5403V5.034H19.5803V7.764C19.5803 9.35 20.6593 10.104 22.0763 10.104C22.4663 10.104 22.8303 10.039 23.1423 9.935ZM28.2712 9.935L28.1412 8.284C27.9202 8.401 27.6342 8.44 27.4002 8.44C26.9322 8.44 26.6202 8.102 26.6202 7.517V5.034H28.2062V3.448H26.6202V1.55H24.7092V3.448H23.6692V5.034H24.7092V7.764C24.7092 9.35 25.7882 10.104 27.2052 10.104C27.5952 10.104 27.9592 10.039 28.2712 9.935ZM28.8916 6.724C28.8916 8.349 29.9966 10.104 32.2586 10.104C33.8186 10.104 34.9886 9.389 35.3786 8.154L33.5846 7.673C33.4416 8.219 32.9606 8.518 32.3106 8.518C31.5046 8.518 30.9586 8.076 30.7766 7.27H35.4826C35.4956 7.127 35.5086 6.815 35.5086 6.555C35.5086 5.099 34.5076 3.344 32.1416 3.344C29.8796 3.344 28.8916 5.099 28.8916 6.724ZM30.8026 5.918C30.9846 5.151 31.4526 4.761 32.1416 4.761C32.9866 4.761 33.4026 5.255 33.4676 5.918H30.8026ZM41.0887 5.281L41.2057 3.448C41.0237 3.383 40.7247 3.344 40.5037 3.344C39.7237 3.344 38.8787 3.877 38.5797 4.579V3.448H36.6687V10H38.5797V7.634C38.5797 5.775 39.3987 5.125 40.2567 5.125C40.5557 5.125 40.8677 5.177 41.0887 5.281Z'
        fill='#242526'
      />
    </svg>
  );
}
