import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop, DragObjectWithType, DropTargetMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ListItem } from './list-item';

type DragItem = DragObjectWithType & {
  id?: string;
  index?: number;
};

type DraggableListItemProps = {
  primaryText: string;
  secondaryText: string;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  onRemove: () => void;
};

export function DraggableListItem({
  primaryText,
  secondaryText,
  index,
  onMove,
  onRemove,
}: DraggableListItemProps): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleWhenToMoveOptions = (item: DragItem, monitor: DropTargetMonitor): void => {
    if (!ref.current) {
      return;
    }
    const dragIndex = item.index ?? 0;
    const hoverIndex = index;
    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    if (!clientOffset) {
      return;
    }

    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    onMove(dragIndex, hoverIndex);
    item.index = hoverIndex;
  };

  const [, drop] = useDrop({
    accept: 'option',
    hover: (item, monitor) => handleWhenToMoveOptions(item, monitor),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'option', id: primaryText, index, primaryText, secondaryText, draggableRef: ref },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      isDragging={isDragging}
      primaryText={primaryText}
      secondaryText={secondaryText}
      onRemove={onRemove}
    />
  );
}
