import { useEffect } from 'react';
import { observer } from 'mobx-react';

import PublicEnv from 'shared/utils/public-env';

import { useDispensary } from 'src/hooks/use-dispensary';
import { useUser } from 'src/hooks/use-user';

const PEND_ENVS = new Set(['production', 'qa', 'staging']);

type Config = {
  visitor: Partial<{
    id: string; // Required if user is logged in, default creates anonymous ID
    email: string; // Recommended if using Pendo Feedback, or NPS Email
    // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
    full_name: string; // Recommended if using Pendo Feedback
    userId: string; // Custom event property
  }>;
  account: {
    id: string; // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
  };
};

export type Pendo = {
  initialize: (config: Config) => void;
};

function usePendo(): void {
  const { id: dispensaryId = 'super' } = useDispensary();
  const {
    id: userId,
    email,
    profile: { fullName },
  } = useUser();

  const { pendo } = window;

  const isPendoEnv = PEND_ENVS.has(PublicEnv.appEnv);

  useEffect(() => {
    if (pendo && isPendoEnv && userId && email && dispensaryId) {
      pendo.initialize({
        visitor: {
          email,
          userId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          full_name: fullName ?? undefined,
        },
        account: {
          id: dispensaryId,
        },
      });
    }
  }, [dispensaryId, email, fullName, isPendoEnv, pendo, userId]);
}

export const PendoAnalytics = observer(() => {
  usePendo();

  return null;
});
