import gql from 'graphql-tag';

export const uploadSecureImage = gql`
  mutation UploadSecureImage($input: imageUploadInput!) {
    uploadSecureImage(input: $input) {
      key
    }
  }
`;

export const uploadImage = gql`
  mutation UploadImage($input: imageUploadInput!) {
    uploadImage(input: $input) {
      url
    }
  }
`;
