import React from 'react';
import styled from 'styled-components';

import { Tooltip } from 'shared/components';
import useHover from 'shared/hooks/use-hover';

import { PlusIcon } from './plus-icon';

type SmallOptionProps = {
  sectionKey: string;
  tooltipCopy: string;
  title: string;
  onAddSection: () => void;
};

export function SmallOption(props: SmallOptionProps): JSX.Element {
  const { sectionKey, tooltipCopy, title, onAddSection } = props;
  const [hoverRef, isHovered] = useHover();

  return (
    <SmallOptionStyles ref={hoverRef} hovered={isHovered} data-testid={`${sectionKey}-section-option`}>
      <TitleContainer>
        <Title>{title}</Title>
        {isHovered && tooltipCopy && (
          <Tooltip
            arrowRight='103px'
            bottom='30px'
            grey
            iconOpacity={0.7}
            left='auto'
            right='-103px'
            tooltipCopy={tooltipCopy}
            width='298px'
          />
        )}
      </TitleContainer>

      <PlusIcon isHovered={isHovered} onClick={onAddSection} data-testid={`${sectionKey}-svg-button`} />
    </SmallOptionStyles>
  );
}

const SmallOptionStyles = styled.div<{ hovered: boolean }>`
  align-items: center;
  background: ${({ hovered }) => (hovered ? '#f3f6f8' : 'none')};
  border-radius: 4px;
  color: ${({ hovered }) => (hovered ? '#454e50' : '#677882')};
  display: flex;
  transition: background 0.15s, color 0.15s;
  padding-right: 16px;
  justify-content: space-between;
  height: 36px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  padding-left: 16px;
`;

const Title = styled.p`
  font-size: 13px;
  padding-top: 3px;
`;
