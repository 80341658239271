import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'src/hooks/use-yup-resolver';
import { Button } from 'shared/components/';
import { DefaultInput } from 'shared/components/text-input-with-label';
import { useStores } from 'src/hooks/use-stores';
import useErnie from 'shared/hooks/use-ernie';
import { useErnieOnSubmitFormErrors } from 'src/utils/form';
import { colors } from 'shared/themes';
import { ActionsContainer, Container, ContentContainer, Header, LoginContainer, ResetPassword } from './login.styles';
import { loginSchema, LoginFormInput } from '../schema';
import { PecosValleyLogin } from './pecos-valley';
import { DutchiePay } from './dutchie-pay';

type LoginProps = {
  onSuccess: () => void;
  onOpenForgotPassword: () => void;
};
export function Login({ onSuccess, onOpenForgotPassword }: LoginProps): JSX.Element {
  const { User } = useStores();
  const showErnie = useErnie();
  const flags = useFlags();
  const { handleFormErrors } = useErnieOnSubmitFormErrors();
  const { control, handleSubmit, formState } = useForm<LoginFormInput>({
    resolver: useYupValidationResolver(loginSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { isSubmitting } = formState;
  const isDutchiePayBanner = flags['growth.ecomm.dutchie-pay-admin-banner.rollout'] ?? false;

  async function handleLogin(data: LoginFormInput): Promise<void> {
    try {
      const response = await User.login(data.email, data.password);
      if (response.error) {
        const message = "You've entered an incorrect email or password. Please try again.";
        showErnie(message, 'danger');
      } else {
        onSuccess();
      }
    } catch (error) {
      showErnie('Something went wrong. Please contact support.', 'danger');
      datadogLogs.logger.error('Login error', { pageName: 'login', error });
    }
  }

  return (
    <Container>
      {!isDutchiePayBanner && <PecosValleyLogin />}
      {isDutchiePayBanner && <DutchiePay />}
      <ContentContainer>
        <LoginContainer data-testid='login-inputs'>
          <Header style={{ color: colors.grey[25] }}>Welcome back!</Header>
          <p style={{ paddingBottom: '20px' }}>Login to your Dutchie account:</p>
          <form onSubmit={handleSubmit(handleLogin, handleFormErrors)}>
            <Controller
              control={control}
              name='email'
              render={({ onChange, value, onBlur }, { invalid }) => (
                <DefaultInput
                  label='Email Address'
                  aria-label='email address input'
                  data-testid='email'
                  id='email'
                  width='100%'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={invalid}
                  errorMessage='Please enter a valid email address'
                  mb={3}
                />
              )}
            />
            <Controller
              control={control}
              name='password'
              render={({ onChange, value, onBlur }, { invalid }) => (
                <DefaultInput
                  label='Password'
                  type='password'
                  aria-label='password input'
                  data-testid='password'
                  id='password'
                  width='100%'
                  mb={3}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={invalid}
                  errorMessage='Please enter a valid password'
                />
              )}
            />
            <ActionsContainer>
              <ResetPassword
                data-testid='forgot-password-link'
                onClick={onOpenForgotPassword}
                px={0}
                type='button'
                link
              >
                Forgot your password?
              </ResetPassword>
              <Button aria-label='login button' data-testid='login-button-submit' type='submit' loading={isSubmitting}>
                Login
              </Button>
            </ActionsContainer>
          </form>
        </LoginContainer>
      </ContentContainer>
    </Container>
  );
}
