import { SubcategoryOptions, CategoryOptions } from 'shared/constants';
import { MenuSectionType } from 'types/graphql';
import { LocalSection } from '../../homepage-form.types';
import { CategoryOption, Option } from './add-category-modal.types';

export function getVisibleCategoryOptions(
  menuSections: LocalSection[] | null,
  sectionType: MenuSectionType | null
): CategoryOption[] | null {
  if (sectionType === MenuSectionType.category) {
    return CategoryOptions.filter(
      (categoryOption) =>
        !menuSections?.find((section) => section.category === categoryOption.value) &&
        categoryOption.value !== 'N/A' &&
        !!categoryOption.value
    );
  }

  return CategoryOptions.filter((categoryOption) => categoryOption.value !== 'N/A' && !!categoryOption.value);
}

export function getVisibleSubcategoryOptions(
  menuSections: LocalSection[] | null,
  selectedCategory: CategoryOption | null
): Option[] | null {
  if (selectedCategory?.value) {
    return SubcategoryOptions[selectedCategory.value].filter(
      (subcategoryOption: Option) =>
        !menuSections?.find((menuSection) => menuSection.subcategory === subcategoryOption.value)
    );
  }

  return null;
}
