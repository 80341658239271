import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { width } from 'styled-system';
import Imgix from 'shared/components/imgix';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { inject } from 'mobx-react';
import _ from 'lodash';

import { removeTypename } from 'shared/helpers/utils';
import { mediaQueries } from 'shared/styles';
import { paymentMethodsToDisplayNames } from 'shared/constants';
import { isWeightedProduct } from 'shared/helpers/products';
import { getDefaultRewardsProgramDisplayName } from 'shared/core/helpers/dispensaries';

import { VisuallyHidden } from './visually-hidden';

export const SubTotal = ({ order, fontSize = '13px', color = '#6d747b' }) => (
  <AddCharge
    flexDirection='row'
    width={['100%', '100%', '400px', '100%']}
    justifyContent='space-between'
    alignItems='center'
    mx='auto'
  >
    <Text fontSize={fontSize} color={color}>
      Subtotal:
    </Text>
    <Text data-testid='order-subtotal' fontSize={fontSize} color={color} fontWeight='bolder'>
      {order.subtotal
        ? `$${parseFloat(order.subtotal).toFixed(2)}`
        : `$${Object.keys(order.orders || {})
            .map((o) => order.orders[o].price)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)}`}
    </Text>
  </AddCharge>
);

const getTaxExistence = (medicalOrder, taxExistence) => removeTypename(taxExistence?.[medicalOrder ? 'med' : 'rec']);

const getTaxText = (doesChargeExist, chargeValue) =>
  doesChargeExist && parseFloat(chargeValue) > 0 ? `$${parseFloat(chargeValue).toFixed(2)}` : 'Included';

/**
 * when a dispo has taxesFirst set in their config, the taxes are rolled into the subtotal
 * so the taxes do exist but they're reduced to 0 for the receipt, in this case we want
 * to show taxes 'included'
 */
const shouldTaxDisplay = (taxExistence) => taxExistence && _.some(_.values(taxExistence), (exists) => !!exists);

const getTaxDisplayConfiguration = (medicalOrder, taxExistence, taxValue) => {
  const updatedTaxExistence = getTaxExistence(medicalOrder, taxExistence);

  return {
    taxExistence: updatedTaxExistence,
    displayTax: shouldTaxDisplay(updatedTaxExistence),
    taxText: getTaxText(!!updatedTaxExistence, taxValue),
  };
};

export const AdditionalCharges = ({ order, fontSize = '13px', color = '#6d747b', rewardsDisplayName }) => {
  const {
    medicalOrder,
    deliveryFee,
    bottleDepositTaxCents,
    cannabisTax,
    taxAmount,
    tipAmount,
    paymentFee,
    paymentMethod,
    credit,
    receipt,
  } = order;
  const taxBreakdown = receipt?.details?.taxBreakdown;
  const vapourTax = (taxBreakdown?.vape ?? 0) / 100;
  const salesTaxAmount = taxAmount - vapourTax;

  // since vapourTax is a subset of sales tax, sales tax might "exist" but be completely comprised of vape taxed products
  // in this case we will hide the sales tax line and only show the vapour tax line
  const hideSalesTaxLineItem = !!vapourTax && taxAmount === vapourTax;

  const {
    taxExistence: salesTaxExistence,
    displayTax: displaySalesTax,
    taxText: salesTaxToDisplay,
  } = getTaxDisplayConfiguration(medicalOrder, order?.salesTaxExistence, salesTaxAmount);
  const {
    taxExistence: cannabisTaxExistence,
    displayTax: displayCannabisTax,
    taxText: cannabisTaxToDisplay,
  } = getTaxDisplayConfiguration(medicalOrder, order?.cannabisTaxExistence, cannabisTax);
  const {
    taxExistence: bottleDepositTaxExistence,
    displayTax: displayBottleDepositTax,
    taxText: bottleDepositTaxToDisplay,
  } = getTaxDisplayConfiguration(medicalOrder, order?.bottleDepositTaxExistence, bottleDepositTaxCents / 100);

  const displayVapourTax = !!vapourTax;
  const vapourTaxToDisplay = getTaxText(displayVapourTax, vapourTax);

  const mixAndMatch = order?.mixAndMatch || 0;

  const hasAppliedRewards = order?.appliedRewards?.length > 0;
  let reward = null;
  if (hasAppliedRewards) {
    [reward] = order.appliedRewards;
  }
  return (
    <>
      {mixAndMatch > 0 && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Mix and Match:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>{`($${parseFloat(mixAndMatch).toFixed(
            2
          )})`}</Text>
        </AddCharge>
      )}
      {order.deliveryFee > 0 && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Delivery Fee:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>{`$${parseFloat(deliveryFee).toFixed(2)}`}</Text>
        </AddCharge>
      )}
      {displayBottleDepositTax && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Bottle Deposit Taxes:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>
            {bottleDepositTaxExistence ? bottleDepositTaxToDisplay : 'Not Included'}
          </Text>
        </AddCharge>
      )}
      {displayCannabisTax && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Cannabis Taxes:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>
            {cannabisTaxExistence ? cannabisTaxToDisplay : 'Not Included'}
          </Text>
        </AddCharge>
      )}
      {displaySalesTax && !hideSalesTaxLineItem && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Sales Taxes:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>
            {salesTaxExistence ? salesTaxToDisplay : 'Not Included'}
          </Text>
        </AddCharge>
      )}
      {displayVapourTax && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Vapour Taxes:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>
            {vapourTaxToDisplay}
          </Text>
        </AddCharge>
      )}
      {tipAmount > 0 && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Tip:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>{`$${parseFloat(tipAmount).toFixed(2)}`}</Text>
        </AddCharge>
      )}
      {paymentFee > 0 && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            {paymentMethod in paymentMethodsToDisplayNames ? paymentMethodsToDisplayNames[paymentMethod] : 'Payment'}{' '}
            Fee:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>{`$${(paymentFee / 100).toFixed(2)}`}</Text>
        </AddCharge>
      )}
      {credit > 0 && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            Credit:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>{`($${parseFloat(credit).toFixed(2)})`}</Text>
        </AddCharge>
      )}
      {hasAppliedRewards && (
        <AddCharge
          flexDirection='row'
          width={['100%', '100%', '400px', '100%']}
          justifyContent='space-between'
          alignItems='center'
          mx='auto'
        >
          <Text fontSize={fontSize} color={color}>
            {rewardsDisplayName}:
          </Text>
          <Text fontSize={fontSize} color={color} fontWeight='bolder'>
            {reward.operator === '$' && `($${parseFloat(reward.value).toFixed(2)})`}
            {reward.operator === '%' && `($${parseFloat(reward.value * 0.01 * parseFloat(order.subtotal)).toFixed(2)})`}
          </Text>
        </AddCharge>
      )}
    </>
  );
};

export const Total = ({ order, uppercase = true, fontSize = '14px' }) => (
  <Flex
    flexDirection='row'
    width={['100%', '100%', '400px', '100%']}
    justifyContent='space-between'
    alignItems='center'
    mt='25px'
    mx='auto'
  >
    <Text fontSize={fontSize} color='#46494c' fontWeight='bolder'>
      {uppercase ? 'ORDER TOTAL:' : 'Order Total:'}
    </Text>
    <Text data-testid='order-total' fontSize={fontSize} color='#46494c' fontWeight='bolder'>{`$${parseFloat(
      order.totalCost || 0
    ).toFixed(2)}`}</Text>
  </Flex>
);

const DispoQuery = gql`
  query GetLogoForReceipt($dispensaryFilter: dispensariesFilterInput!) {
    filteredDispensaries(filter: $dispensaryFilter) {
      logoImage
      storeSettings {
        rewardsIntegrationConfiguration {
          rewardsProgramDisplayName
        }
      }
      location {
        country
      }
    }
  }
`;

export const Receipt = inject('apolloClient')(({ order, dispensaryUrl, apolloClient }) => {
  const { data } = useQuery(DispoQuery, {
    variables: {
      dispensaryFilter: {
        cNameOrID: order.dispensaryId || order.dispensary._id,
      },
    },
    client: apolloClient,
  });
  const dispensary = data?.filteredDispensaries?.[0] ?? {};
  const rewardsDisplayName =
    dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName(dispensary);
  const logoImage = dispensary?.logoImage;

  return (
    <>
      <LogoContainer>
        {!_.isEmpty(logoImage) && (
          <a data-cy='dispensary-logo-link' data-testid='dispensary-logo-link' href={dispensaryUrl}>
            <VisuallyHidden>{`Go to${order.dispensary.name} homepage`}</VisuallyHidden>
            <Logo role='img' title='dispensary logo' height={69} fit='clip' src={logoImage} />
          </a>
        )}
        <Flex flexDirection='column'>
          <Text data-testid='dispensary-name' color='#6d747b' fontSize='18px' fontWeight='bold' mb='10px' mt='5px'>
            {order.dispensary.name}
          </Text>
          <Text data-testid='dispensary-address' color='#6d747b' fontSize='13px' lineHeight='1.5'>
            {order.dispensary.address}
          </Text>
        </Flex>
      </LogoContainer>

      <Divider width={['100%', '100%', '400px', '100%']} />
      <Flex flexDirection='column' width={['100%', '100%', '400px', '100%']} mb='17px' mx='auto'>
        <Flex flexDirection='row' alignItems='center' justifyContent='space-between' width='100%' mt='10px'>
          <Text fontSize='14px' color='#6d747b' fontWeight='bold'>{`(${order.orders.length}) Item${
            order.orders.length > 1 ? 's' : ''
          }`}</Text>
          <Text fontSize='13px' color='#a7aeb4' fontWeight='bold'>{`ORDER: ${order.orderId}`}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection='column' width={['100%', '100%', '400px', '100%']} mx='auto'>
        {_.map(order.orders, (ord, i) => {
          const brandName = ord.product.brandName ?? ord.product.brand?.name;

          return (
            <OrderItem key={i} flexDirection='column' width='100%' mx='auto'>
              <Flex flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                {!_.isEmpty(brandName) && (
                  <NamesContainer>
                    <BrandName data-testid='item-brand'>{brandName}</BrandName>
                    <OrderName data-testid='item-name' hasBrand={!_.isEmpty(brandName)}>
                      {ord.product.Name}
                    </OrderName>
                  </NamesContainer>
                )}
                {_.isEmpty(brandName) && (
                  <OrderName data-test='item-name' hasBrand={!_.isEmpty(brandName)}>
                    {ord.product.Name}
                  </OrderName>
                )}
                <OrderQTYContainer flexDirection='row' width='99px' justifyContent='space-between' alignItems='center'>
                  <Text data-testid='item-quantity' fontSize='13px' color='#6d747b'>{`Qty: ${ord.quantity || 1}`}</Text>
                  <Text
                    data-testid='item-price'
                    fontSize='13px'
                    color='#6d747b'
                    fontWeight='bolder'
                  >{`$${ord.price.toFixed(2)}`}</Text>
                </OrderQTYContainer>
              </Flex>
              {isWeightedProduct(ord.product) && (
                <Text fontSize='13px' color='#6d747b' textAlign='left'>
                  {ord.option.replace('gC', 'g')}
                </Text>
              )}
            </OrderItem>
          );
        })}
      </Flex>
      <Divider width={['100%', '100%', '400px', '100%']} />
      <SubTotal order={order} />
      <AdditionalCharges order={order} rewardsDisplayName={rewardsDisplayName} />
      <Total order={order} />
    </>
  );
});

const NamesContainer = styled.div`
  display: block;
`;

const BrandName = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: #949ea8;
  font-size: 12px;
  margin-bottom: 5px;
`;

const OrderName = styled(Text)`
  flex-shrink: 1;
  line-height: 15px;
  text-align: left;
  font-size: 13px;
  padding-right: 9px;
  color: #6d747b;
`;

export const Divider = styled.div`
  ${width}
  margin: 35px 0 32px 0;
  min-height: 1px;
  background-color: #d3dadf;
  @media ${mediaQueries.tablet} {
    margin: 32px auto 29px;
  }
`;

const OrderItem = styled(Flex)`
  &:not(:last-of-type) {
    margin-bottom: 23px;
  }
`;

const AddCharge = styled(Flex)`
  &:not(:last-of-type) {
    margin-bottom: 23px;
  }
`;

const LogoContainer = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media ${mediaQueries.tablet} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Logo = styled(Imgix)`
  height: 69px;
  margin-right: 15px;
  cursor: pointer;
  object-fit: contain;
  object-position: 0 50%;
  @media ${mediaQueries.tablet} {
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

const OrderQTYContainer = styled(Flex)`
  flex-shrink: 0;
`;
