import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { SuperAdminContent } from 'src/components/super-admin-content';

export default function SubheaderLink(props) {
  const { label, onClick = null, onlyActiveOnIndex, superAdminOnly = false, to, status } = props;

  function handleClick(event) {
    event.preventDefault();
    onClick();
  }

  const linkProps = _.pickBy(
    {
      onClick: onClick ? handleClick : null,
      onlyActiveOnIndex,
      to,
    },
    (value) => !_.isNil(value)
  );

  const linkPropsWithTestId = {
    ...linkProps,
    'data-testid': `settings-${_.kebabCase(label)}-link`,
  };

  const link = (
    <StyledLink {...linkPropsWithTestId}>
      <Label>
        {label}
        {status && <Status>{status}</Status>}
      </Label>
    </StyledLink>
  );

  if (superAdminOnly) {
    return (
      <SuperAdminContent>
        <div>{link}</div>
      </SuperAdminContent>
    );
  }

  return link;
}

const StyledLink = styled(NavLink)`
  color: #89939d;
  cursor: pointer;
  font-size: 14px;
  margin-left: 28px;
  white-space: nowrap;

  :hover {
    color: #42505d;
    text-decoration: none !important;
  }

  :focus,
  :active {
    outline: none;
  }

  &.--is-active {
    color: #42505d;
    font-weight: bold;
  }
`;

StyledLink.defaultProps = {
  activeClassName: '--is-active',
};

const Label = styled.span``;

const Status = styled.span`
  background: #ffd3b8;
  border-radius: 4px;
  color: #2e3538;
  display: inline-block;
  font-family: Matter, proxima-nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 9px;
  margin: -2px 0 0 4px;
  padding: 1px 4px;
  vertical-align: middle;
`;
