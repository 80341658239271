import React from 'react';
import styled from 'styled-components';

import { AddCircle } from 'src/svg/add-circle';

export const TEST_ID_ADD_IMAGE_BANNER = 'add-image-banner';

type AddImageBannerProps = {
  addImageModal: () => void;
};

export function AddImageBanner({ addImageModal }: AddImageBannerProps): JSX.Element {
  return (
    <AddBox onClick={() => addImageModal()} data-testid={TEST_ID_ADD_IMAGE_BANNER}>
      <AddCircle fill='none' />
    </AddBox>
  );
}

const AddBox = styled.div`
  width: 100%;
  height: 62px;
  background: #f3f6f8;
  border: 1px dashed #bccad2;
  border-radius: 4px;
  margin: 0 0 20px 0;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s ease-in-out;

  & :hover {
    background: #e1e6eb;
  }
`;
