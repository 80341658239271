import _ from 'lodash';
import { useEffect } from 'react';
import { useStores } from 'src/hooks/use-stores';
import PublicEnv from 'shared/utils/public-env';
import { hideZendeskWidget, showZendeskWidget } from 'shared/helpers/tools';
import { useFlags } from 'launchdarkly-react-client-sdk';

const widgetKey = PublicEnv.zendeskAdminKey;

export default function useAdminZendeskWidget() {
  const { User } = useStores();
  const flags = useFlags();

  const rolloutZendeskMessengerWidget = flags['rollout.zendesk-messenger-widget'];

  useEffect(() => {
    if (_.isNil(rolloutZendeskMessengerWidget) || !widgetKey) {
      return;
    }

    const zendeskInitScript = document.createElement('script');
    zendeskInitScript.id = 'ze-snippet';
    zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`;
    zendeskInitScript.async = true;
    // this function will fire off a Segment tracking event when a live chat is initiated
    const trackChatWithSegment = (userId, userEmail) => {
      if (_.isFunction(window.zE)) {
        window.zE('webWidget:on', 'userEvent', (event) => {
          if (event.action === 'Chat Request Form Submitted' && window.analytics) {
            window.analytics.track('Live Chat Conversation Started', {
              userId,
              email: userEmail,
            });
          }
        });
      }
    };
    const ref = () => {
      console.log('zendesk loaded');

      if (rolloutZendeskMessengerWidget) {
        window.zE('messenger', 'loginUser', (serverCallback) => {
          serverCallback(`${User._user.zendeskWidgetToken}`);
        });
      } else {
        trackChatWithSegment(User.id, User.email);
      }
    };

    document.body.appendChild(zendeskInitScript);
    if (!rolloutZendeskMessengerWidget) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            jwtFn(serverCallback) {
              serverCallback(`${User._user.zendeskWidgetToken}`);
            },
          },
          contactForm: {
            fields: [
              { id: 'name', prefill: { '*': `${User._user.profile.fullName}` } },
              { id: 'email', prefill: { '*': `${User.email}` } },
            ],
          },
        },
      };
    }
    showZendeskWidget();
    window.addEventListener('load', ref);
    return () => {
      hideZendeskWidget();
      document.body.removeChild(zendeskInitScript);
      window.removeEventListener('load', ref);
    };
  }, [User, User._user.zendeskWidgetToken, rolloutZendeskMessengerWidget]);
}
