import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { navBarColors, buttonsLinksColors } from 'shared/constants/menu-colors';
import { MenuCustomizationLayout } from '../components/menu-customization-layout';
import { PreviewSwitcher } from '../components/preview-switcher';

import { useThemeForm } from './use-theme-form';

import { ColorPickerV2 } from '../components/color-picker-v2';

import { ThemeFormProps } from './theme.types';

export function ThemeForm(props: ThemeFormProps): JSX.Element {
  const { control, handlePublish, handleReset, isDirty, previewData } = useThemeForm(props);

  return (
    <MenuCustomizationLayout
      headerText='Colors'
      subHeaderText='Select colors to express your brand.'
      isDirty={isDirty}
      handlePublish={handlePublish}
      handleReset={handleReset}
      previewComponent={<PreviewSwitcher previewData={previewData} />}
    >
      <Inputs>
        <Input>
          <Controller
            name='navBar'
            control={control}
            render={({ onChange, value }) => (
              <ColorPickerV2 label='Navigation Bar' colors={navBarColors} onSelect={onChange} selectedColor={value} />
            )}
          />
        </Input>

        <Input>
          <Controller
            name='buttonsLinks'
            control={control}
            render={({ onChange, value }) => (
              <ColorPickerV2
                label='Buttons and Links'
                colors={buttonsLinksColors}
                onSelect={onChange}
                selectedColor={value}
              />
            )}
          />
        </Input>
      </Inputs>
    </MenuCustomizationLayout>
  );
}

const Inputs = styled.div`
  flex: 0 0 auto;
  min-width: 0;
`;

const Input = styled.div`
  margin-bottom: 20px;
`;
