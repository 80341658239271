import loadable from '@loadable/component';
import React from 'react';

import useEnterpriseFlag from 'shared/hooks/use-enterprise-flag';
import { AdminLayout } from 'src/components/admin-layout';
import useEnterprise from 'src/hooks/use-enterprise';
import { useEnterpriseAdminLinks } from 'src/enterprise-admin/hooks/use-enterprise-admin-links';

const LoadableRoute = loadable((props: { filePath: string }) => import(`${props.filePath}`));

const EnterpriseAdmin = (): JSX.Element => {
  const { id: enterpriseId, uniqueName } = useEnterprise();
  const externalAuthorizationEnabled = useEnterpriseFlag(
    `growth.ecomm.dutchie-plus-external-auth-provider.rollout`,
    enterpriseId
  );
  const { links, redirectPath } = useEnterpriseAdminLinks(externalAuthorizationEnabled);

  return (
    <AdminLayout
      links={links}
      loadableRoute={LoadableRoute}
      redirectPath={redirectPath}
      title={uniqueName ?? 'Enterprise Admin'}
    />
  );
};

export default EnterpriseAdmin;
