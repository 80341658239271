import React from 'react';
import styled from 'styled-components';
import { ModalButton, ModalSecondaryHeader, ModalClose, ModalCopy } from 'shared/modals';
import { CustomModalStyles, EditContainer, Field, DisplayContainer } from './custom-modal.styles';
import { SmallText } from './components/small-text';
import { DragLayer } from './components/drag-layer';
import { ProductList } from './components/product-list';
import { Instructions } from './components/instructions';
import { SectionNameInput } from './components/section-name-input';
import { ProductSelectInput } from './components/product-select-input';
import { LocalSection, Products } from '../../homepage-form.types';

import { useCustomModal } from './use-custom-modal';

type CustomModalProps = {
  handleAddSection: (newSection: LocalSection) => void;
  menuSection: LocalSection | null;
  onToggle: () => void;
  products: Products | null;
};

export function CustomModal({ menuSection, onToggle, products, handleAddSection }: CustomModalProps): JSX.Element {
  const {
    handleMoveOption,
    handleProductSelectChange,
    handleRemoveProduct,
    handleSave,
    handleSectionNameChange,
    loading,
    sectionName,
    selectedProducts,
    showProductList,
  } = useCustomModal({ menuSection, onToggle, products, handleAddSection });

  return (
    <>
      <CustomModalStyles isOpen noPadding height='438px' width='100%' maxWidth='830px'>
        <EditContainer onSubmit={handleSave}>
          <ModalSecondaryHeader mb='5px' lowercase>
            Add a Custom Section
          </ModalSecondaryHeader>
          <Copy>Create a custom homepage section with any products.</Copy>
          <Field>
            <SmallText>SECTION NAME</SmallText>
            <SectionNameInput value={sectionName ?? ''} onChange={handleSectionNameChange} />
          </Field>
          <Field>
            <SmallText>ADD PRODUCTS</SmallText>
            <ProductSelectInput
              options={products}
              selectedProducts={selectedProducts}
              onChange={handleProductSelectChange}
            />
          </Field>
          <ModalButton
            width={[1, 1, 1, '100%']}
            buttonContainerWidth='87px'
            mt='0'
            alignItems='flex-start'
            size='medium'
            loading={loading}
          >
            Save
          </ModalButton>
        </EditContainer>
        <DisplayContainer>
          {showProductList ? (
            <ProductList products={selectedProducts} handleMove={handleMoveOption} handleRemove={handleRemoveProduct} />
          ) : (
            <Instructions />
          )}
        </DisplayContainer>
        <ModalClose onClick={onToggle} />
      </CustomModalStyles>
      <DragLayer />
    </>
  );
}

const Copy = styled(ModalCopy)`
  margin-bottom: 24px;
`;
