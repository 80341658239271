import { useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useStores } from 'src/hooks/use-stores';
import { MenuSectionType } from 'types/graphql';
import { LocalSection, Products } from '../../homepage-form.types';
import { getMenuSectionProductFilter, getProductIds } from '../../homepage-form.utils';
import { getVisibleCategoryOptions, getVisibleSubcategoryOptions } from './add-category-modal.utils';
import { CategoryOption, Option } from './add-category-modal.types';
import { AddCategoryModalProps } from './add-category-modal';

type UseAddCategoryModalParams = AddCategoryModalProps;

type UseAddCategoryModalReturn = {
  addingCategory: boolean;
  allowAdd: boolean;
  handleChangeCategory: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeSubcategory: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSave: () => void;
  selectedCategory: CategoryOption | null;
  selectedSubcategory: Option | null;
  visibleCategoryOptions: CategoryOption[] | null;
  visibleSubcategoryOptions: Option[] | null;
};

export function useAddCategoryModal({
  handleAddSection,
  menuSections,
  onToggle,
  products,
  sectionTypeKey,
}: UseAddCategoryModalParams): UseAddCategoryModalReturn {
  const { UI } = useStores();
  const [selectedCategory, setSelectedCategory] = useState<CategoryOption | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<Option | null>(null);
  const addingCategory = sectionTypeKey === MenuSectionType.category;
  const allowAdd = addingCategory ? !!selectedCategory : !!selectedSubcategory;

  const visibleCategoryOptions = useMemo(() => getVisibleCategoryOptions(menuSections, sectionTypeKey), [
    menuSections,
    sectionTypeKey,
  ]);

  const visibleSubcategoryOptions = useMemo(() => getVisibleSubcategoryOptions(menuSections, selectedCategory), [
    menuSections,
    selectedCategory,
  ]);

  const handleSave = (): void => {
    const filterMethod = getMenuSectionProductFilter(
      selectedSubcategory ? MenuSectionType.subcategory : MenuSectionType.category
    );

    const foundProducts = filterMethod({
      items: { products, offers: undefined },
      menuSection: { category: selectedCategory?.value ?? '', subcategory: selectedSubcategory?.value ?? '' },
    });

    const productIds = getProductIds(foundProducts as Products);
    const label = selectedSubcategory ? selectedSubcategory.label : selectedCategory?.label ?? '';

    const newMenuSection: LocalSection = {
      id: uuid(),
      dispensaryId: UI.dispensary.id ?? '',
      category: selectedCategory?.value ?? '',
      sectionType: selectedSubcategory ? MenuSectionType.subcategory : MenuSectionType.category,
      subcategory: selectedSubcategory?.value ?? '',
      position: 0,
      label: `Popular ${label}`,
      products: productIds,
      count: productIds.length,
    };

    if (sectionTypeKey) {
      handleAddSection(newMenuSection);
    }

    onToggle();
  };

  const handleChangeCategory = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const category =
      visibleCategoryOptions?.find((categoryOption) => categoryOption.key === event.target.value) ?? null;
    setSelectedCategory(category);
    setSelectedSubcategory(null);
  };

  const handleChangeSubcategory = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const subcategory =
      visibleSubcategoryOptions?.find((subcategoryOption) => subcategoryOption.key === event.target.value) ?? null;
    setSelectedSubcategory(subcategory);
  };

  return {
    addingCategory,
    allowAdd,
    handleChangeCategory,
    handleChangeSubcategory,
    handleSave,
    selectedCategory,
    selectedSubcategory,
    visibleCategoryOptions,
    visibleSubcategoryOptions,
  };
}
