import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import MetaTags from 'react-meta-tags';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import styled from 'styled-components';

import PublicEnv from 'shared/utils/public-env';
import { getOrderForDelivery } from 'shared/graphql/order/queries';
import { Receipt } from 'shared/components/receipt';
import { ModalContainer } from 'shared/modals';
import { mediaQueries } from 'shared/styles';
import { FullPageSpinner } from 'src/components/full-page-spinner';

// Fix for issues with LogRocket and Apollo Client
const fetcher = {};
// eslint-disable-next-line lodash/prefer-lodash-typecheck
if (typeof window !== 'undefined' && typeof window.fetch === 'function') {
  fetcher.fetch = (...args) => window.fetch(...args);
}

@inject('UI')
@observer
export default class DeliveryPage extends Component {
  @observable order;
  @observable isLoading = false;

  componentDidMount() {
    this.fetchOrder();
  }

  get apolloClient() {
    if (this._apolloClient) {
      return this._apolloClient;
    }

    this._apolloClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: createHttpLink({
        uri: PublicEnv.graphqlUri,
        headers: { temporaryAdminAccessToken: this.props.match.params.token },
        ...fetcher,
      }),
      name: 'External Receipt Page',
    });
    return this._apolloClient;
  }

  fetchOrder = async () => {
    const { id } = this.props.match.params;

    this.isLoading = true;
    try {
      const { data } = await this.apolloClient.query({
        query: getOrderForDelivery,
        variables: { id },
      });

      this.order = data?.getOrderForDelivery;
    } catch (error) {
      this.props.UI.showErnie('We were unable to find this order. Please contact support.', 'danger');
    } finally {
      this.isLoading = false;
    }
  };

  render() {
    const { order, isLoading } = this;

    if (isLoading) {
      return <FullPageSpinner />;
    }

    if (!order) {
      return null;
    }

    return (
      <DeliveryPageContainer>
        <MetaTags>
          <title>Dutchie Receipt | Order #{order.orderId}</title>
          <meta name='description' content={`Dutchie Receipt | Order #${order.orderId}`} />
        </MetaTags>

        <ModalContainer isOpen>
          <ReceiptContainer>
            <Receipt order={order} />
          </ReceiptContainer>
        </ModalContainer>
      </DeliveryPageContainer>
    );
  }
}

const DeliveryPageContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
`;

const ReceiptContainer = styled.div`
  overflow: auto;

  @media ${mediaQueries.tablet} {
    overflow: unset;
  }
`;
