import React, { Component } from 'react';

export default class GMap extends Component {
  state = {
    zoom: 15,
  };

  render() {
    if (!window.google) {
      return null;
    }
    return (
      <div className='GMap'>
        <div className='GMap-canvas' ref='mapCanvas' style={{ height: this.props.height, width: this.props.width }} />
      </div>
    );
  }

  componentDidMount() {
    if (!window.google) {
      return;
    }
    // create the map, marker and infoWindow after the component has
    // been rendered because we need to manipulate the DOM for Google =(
    this.map = this.createMap();
    this.marker = this.createMarker();
    this.infoWindow = this.createInfoWindow();

    // have to define google maps event listeners here too
    // because we can't add listeners on the map until its created
    window.google.maps.event.addListener(this.map, 'zoom_changed', () => this.handleZoomChange());
  }

  // clean up event listeners when component unmounts
  componentWillUnmount() {
    window.google.maps.event.clearListeners(this.map, 'zoom_changed');
  }

  createMap() {
    const mapOptions = {
      zoom: this.state.zoom,
      center: this.mapCenter(),
    };
    const map = new window.google.maps.Map(this.refs.mapCanvas, mapOptions);
    window.google.maps.event.trigger(map, 'resize');
    return map;
  }

  mapCenter() {
    return new window.google.maps.LatLng(this.props.initialCenter.lat, this.props.initialCenter.lng);
  }

  createMarker() {
    return new window.google.maps.Marker({
      position: this.mapCenter(),
      map: this.map,
    });
  }

  createInfoWindow() {
    return new window.google.maps.InfoWindow({
      map: this.map,
      anchor: this.marker,
      content: this.props.tag,
    });
  }

  handleZoomChange() {
    this.setState({
      zoom: this.map.getZoom(),
    });
  }
}
