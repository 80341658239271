import { useState, useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import update from 'immutability-helper';
import useErnie from 'shared/hooks/use-ernie';
import { useStores } from 'src/hooks/use-stores';
import { MenuSectionType, GqlProducts } from 'types/graphql';
import { PRODUCT_LIMIT } from '../../homepage-form.constants';
import { LocalSection, Products } from '../../homepage-form.types';
import { getProductIds } from '../../homepage-form.utils';

export const MAX_SECTION_NAME_LENGTH = 50;

function mapExistingProducts(menuSection: LocalSection | null, products: Products | null): GqlProducts[] | null {
  if (!menuSection || !products) {
    return null;
  }

  return menuSection.products?.reduce((prev, currId) => {
    const foundProduct = products.find((product) => product?.id === currId);
    if (foundProduct) {
      return [...prev, foundProduct];
    }
    return prev;
  }, []) as GqlProducts[];
}

type UseCustomModalParams = {
  handleAddSection: (newSection: LocalSection) => void;
  menuSection: LocalSection | null;
  onToggle: () => void;
  products: Products | null;
};

type UseCustomModalReturn = {
  handleMoveOption: (dragIndex: number, hoverIndex: number) => void;
  handleProductSelectChange: (newValue: GqlProducts[] | null) => void;
  handleRemoveProduct: (id: string) => void;
  handleSave: (event: React.ChangeEvent<HTMLFormElement>) => void;
  handleSectionNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  sectionName: string | null;
  selectedProducts: GqlProducts[] | null;
  showProductList: boolean;
};

export function useCustomModal({
  menuSection,
  onToggle,
  products,
  handleAddSection,
}: UseCustomModalParams): UseCustomModalReturn {
  const showErnie = useErnie();
  const { UI } = useStores();

  const [loading, setLoading] = useState(false);
  const [sectionName, setSectionName] = useState<string | null>(menuSection?.sectionName ?? null);
  const initialProducts = useMemo(() => mapExistingProducts(menuSection, products), [menuSection, products]);
  const [selectedProducts, setSelectedProducts] = useState<GqlProducts[] | null>(initialProducts);

  const handleSectionNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSectionName(event.target.value.slice(0, MAX_SECTION_NAME_LENGTH));
  };

  const handleProductSelectChange = (newValue: GqlProducts[] | null): void => {
    if (newValue && newValue.length > PRODUCT_LIMIT) {
      showErnie(`Sorry, you can select a maximum of ${PRODUCT_LIMIT} products`, 'danger');
      return;
    }
    if (newValue) {
      setSelectedProducts(newValue);
    }
  };

  const handleRemoveProduct = (id: string): void => {
    setSelectedProducts((previousState: GqlProducts[]) => previousState.filter((product) => product.id !== id));
  };

  const handleMoveOption = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedProduct = selectedProducts?.[dragIndex];
      if (draggedProduct) {
        setSelectedProducts(
          update(selectedProducts, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, draggedProduct],
            ],
          })
        );
      }
    },
    [selectedProducts]
  );

  const validate = (): boolean => {
    if (sectionName && sectionName.length < 1) {
      showErnie(`Please enter a name for the section`, 'danger');
      return false;
    }

    if (selectedProducts && selectedProducts.length < 1) {
      showErnie(`Please select at least one product`, 'danger');
      return false;
    }

    return true;
  };

  const createSection = (): void => {
    setLoading(true);
    const productIds = getProductIds(selectedProducts);

    const newSection: LocalSection = {
      id: uuid(),
      dispensaryId: UI.dispensary.id ?? '',
      sectionName,
      sectionType: MenuSectionType.custom,
      products: productIds,
      label: sectionName ?? 'Custom',
      position: 0,
      count: productIds.length,
    };

    handleAddSection(newSection);
    setLoading(false);
    onToggle();
  };

  const updateSection = (): void => {
    setLoading(true);
    const productIds = getProductIds(selectedProducts);
    const updatedSection: LocalSection = {
      id: menuSection?.id ?? '',
      dispensaryId: UI.dispensary.id ?? '',
      sectionName,
      products: productIds,
      count: productIds.length,
      label: sectionName ?? 'Custom',
      position: 0,
      sectionType: MenuSectionType.custom,
    };

    handleAddSection(updatedSection);
    setLoading(false);
    onToggle();
  };

  const handleSave = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const isValid = validate();
    const isEditing = menuSection?.id;

    if (isValid) {
      if (isEditing) {
        updateSection();
      } else {
        createSection();
      }
    }
  };

  const showProductList = !!selectedProducts?.length;

  return {
    handleMoveOption,
    handleProductSelectChange,
    handleRemoveProduct,
    handleSave,
    handleSectionNameChange,
    loading,
    sectionName,
    selectedProducts,
    showProductList,
  };
}
