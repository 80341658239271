import { Logo } from 'shared/assets/logo';
import { Button } from 'shared/components/';
import Imgix from 'shared/components/imgix';
import { mediaQueries, mediaSizes } from 'shared/styles';
import { colors } from 'shared/themes';
import styled, { css } from 'styled-components';
import { Maybe } from 'types/graphql';

const tabletUp = `${mediaSizes.tablet + 2}px`;

export const ResetPassword = styled(Button)`
  color: ${colors.grey[45]};
  font-weight: bold;
`;
export const Container = styled.main`
  display: flex;
  width: 100%;
  min-height: 100vh;

  @media ${mediaQueries.largeDesktop} {
    flex-wrap: wrap;
  }
`;

export const SlideCta = styled.a<{ styles?: Maybe<string> }>`
  background: ${colors.primaryBlue};
  color: ${colors.white};
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: ${16 / 12};
  padding: 8px 12px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

export const StyledLogo = styled(Logo)<{ styles?: Maybe<string> }>`
  left: 56px;
  position: absolute;
  top: 56px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

export const Slide = styled.div<{ styles: Maybe<string> }>`
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100vh;

  @media ${mediaQueries.tablet} {
    height: 50vh;
    max-width: -webkit-fill-available;
  }

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

export const Header = styled.h2`
  color: ${colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: ${37 / 32};
  max-width: 460px;
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  line-height: ${24 / 16};
  margin: 16px 0 32px;
  max-width: 460px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const SplashImage = styled(Imgix)<{ src: string; htmlAttributes: any }>`
  max-width: 600px;
`;

export const ContentContainer = styled.div`
  align-items: center;
  background: ${colors.white};
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  width: 60%;

  @media ${mediaQueries.tablet} {
    order: -1;
    width: 100%;
    padding-top: 125px;
  }

  @media ${mediaQueries.largeDesktop} {
    width: 100%;
    order: -1;
  }
`;

export const LoginContainer = styled.div`
  max-width: 460px;
  width: 100%;
  margin: 0 40px;
`;

export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const SplashContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 55px;
  background-image: linear-gradient(to bottom, ${colors.rebrandPrimaryBlue} 15%, ${colors.flora} 94%);

  @media only screen and (max-width: ${tabletUp}) {
    width: 100%;
  }
`;

export const SplashCtaWrapper = styled.div`
  padding: 0 55px 55px;
`;

export const InsuranceWrapper = styled.div`
  display: flex;
  height: -webkit-fill-available;
  width: 55%;

  @media ${mediaQueries.tablet} {
    width: 100%;
  }
`;

export const InsuranceTextWrapper = styled.div`
  padding: 0 60px 0;
  margin-bottom: 90px;

  @media ${mediaQueries.tablet} {
    padding: 0;
    margin-top: 55px;
    inline-size: min-content;
    position: relative;
    width: 100%;
  }
`;
