import { GqlProducts } from 'types/graphql';

type Brand = Pick<NonNullable<NonNullable<GqlProducts>['brand']>, 'id' | 'name'>;

export function concatBrandName(brand: Brand | null | undefined, name: string): string {
  if (brand?.name) {
    return `${brand.name} | ${name}`;
  }

  return name;
}
