import React, { MouseEvent, useEffect, useState } from 'react';
import { RadioGroup } from 'shared/components';
import useStores from 'shared/hooks/use-stores';

import styled from 'styled-components';

type TextBannerCollapseProps = {
  onToggle: (value: boolean) => void;
  toggleValue: boolean;
};

const TextBannerCollapse = ({ onToggle, toggleValue }: TextBannerCollapseProps): JSX.Element => {
  const { UI } = useStores();
  const [shouldProceed, setShouldProceed] = useState(false);

  async function handleToggle(value: MouseEvent<HTMLDivElement> | boolean): Promise<boolean | undefined> {
    if (!value) {
      const confirmed = await UI.openTextBannerNotificationModal({ confirmed: false });
      setShouldProceed(confirmed);
      return;
    }
    setShouldProceed(false);
    onToggle(!toggleValue);
  }

  useEffect(() => {
    if (shouldProceed) {
      onToggle(false);
    }
  }, [shouldProceed, onToggle]);

  return (
    <TextBannerContainer>
      <TextBannerHeader>Banner Default View:</TextBannerHeader>
      <RadioGroup
        options={[
          {
            label: 'Collapsed',
            value: true,
            sublabel: '(Recommended)',
            subDescription: 'The text banner will be collapsed by default when a user arrives on the homepage.',
            marginBottom: '12px',
          },
          {
            label: 'Expanded',
            value: false,
            subDescription: 'The text banner will be expanded by default when a user arrives on the homepage.',
            marginBottom: '12px',
          },
        ]}
        defaultValue={toggleValue}
        updateCurrentValueWithDefaultValue
        onChange={(value: boolean) => handleToggle(value)}
        width='100%'
        hasSublabels
        hasSubDescriptions
      />

      <p>
        Menus that expand the banner by default tend to experience significantly lower conversion rates compared to
        those that collapse by default.
      </p>
    </TextBannerContainer>
  );
};

export default TextBannerCollapse;

const TextBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid #e4e8ec;
  border-bottom: 1px solid #e4e8ec;
  padding: 24px 0;
`;

const TextBannerHeader = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #707478;
`;
