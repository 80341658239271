import { action, computed, observable, toJS } from 'mobx';
import { DispensaryJSONFeatureFlag, EnterpriseJSONFeatureFlag } from 'shared/helpers/types';

import { RootState } from 'src/state/app';

type FeatureFlagValueType = DispensaryJSONFeatureFlag | EnterpriseJSONFeatureFlag | boolean | string;

type FeatureFlags = Record<string, FeatureFlagValueType>;

export class FeatureFlagsState {
  @observable _flags: FeatureFlags = {};

  constructor(private readonly rootStore: RootState) {}

  @action
  setFlags(newFlags: FeatureFlags): void {
    this._flags = newFlags;
  }

  @computed
  get flags(): FeatureFlags {
    return toJS(this._flags);
  }
}
