import React from 'react';

import { ModalContainer, ModalPrimaryHeader } from 'shared/modals';
import { SmallLoader } from 'shared/components/loading';
import RadioGroup from 'shared/components/radio-group';
import { validateURL, validateAlt } from './image-banner-modal.helper';
import { useImageBannerModal } from './use-image-banner-modal';
import {
  Content,
  LeftColumn,
  RightColumn,
  Block,
  ImageBlock,
  Title,
  Body,
  HiddenInput,
  FileSelect,
  InputContainer,
  Input,
  ValidateCheckIcon,
  PlaceHolderBanner,
  MobilePlaceHolderBanner,
  StyledButton,
  DesktopBannerImage,
  MobileBannerImage,
} from './image-banner-modal.styles';
import { ImageBanner } from '../image-banner.types';

type ImageBannerModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  addBanner: (banner: ImageBanner) => void;
  bannerToEdit: ImageBanner | null;
};

export const TEST_ID_IMAGE_BANNER_MODAL = 'image-banner-modal';

const imgixParams = {
  fit: 'crop',
  crop: 'edges',
};

export function ImageBannerModal({ onClose, isOpen, addBanner, bannerToEdit }: ImageBannerModalProps): JSX.Element {
  const {
    alt,
    link,
    openInNewTab,
    isUploadingImage,
    isUploadingMobileImage,
    image,
    mobileImage,
    handleSetAlt,
    handleSetLink,
    handleChangeLinkBehavior,
    handleFile,
    handleSave,
    handleClose,
    isEditingBanner,
  } = useImageBannerModal({ onClose, addBanner, bannerToEdit });

  const buttonText = isEditingBanner ? 'EDIT FILE' : 'SELECT FILE';

  const linkBehaviorOptions = [
    {
      label: 'New tab',
      value: true,
    },
    {
      label: 'Current tab',
      value: false,
    },
  ];

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={handleClose}
      width='930px'
      fancy
      alignItems='flex-start'
      pt='90px'
      ariaHideApp={false}
    >
      <ModalPrimaryHeader copy={isEditingBanner ? 'Edit an Image Banner' : 'Add an Image Banner'} close={handleClose} />
      <Content data-testid={TEST_ID_IMAGE_BANNER_MODAL}>
        <LeftColumn>
          <ImageBlock>
            <Title>Desktop Image</Title>
            <Body>Uploaded images should be 2400px (width) 800px (height), and in JPEG or PNG formats.</Body>
            <HiddenInput
              id='image-banner-file-input'
              type='file'
              name='image-banner'
              accept='image/png, image/jpeg'
              onChange={(e) => {
                handleFile(e);
                e.target.value = '';
              }}
              disabled={isUploadingImage || isUploadingMobileImage}
            />
            <FileSelect htmlFor='image-banner-file-input'>
              {isUploadingImage ? <SmallLoader height={20} color='#0b99e6' /> : buttonText}
            </FileSelect>
          </ImageBlock>

          <ImageBlock>
            <Title>Mobile image</Title>
            <Body>Uploaded images should be 800px (width) 360px (height), and in JPEG or PNG formats.</Body>
            <HiddenInput
              id='mobile-image-banner-file-input'
              type='file'
              name='mobile-image-banner'
              accept='image/png, image/jpeg'
              onChange={(e) => {
                handleFile(e);
                e.target.value = '';
              }}
              disabled={isUploadingImage || isUploadingMobileImage}
            />
            <FileSelect htmlFor='mobile-image-banner-file-input'>
              {isUploadingMobileImage ? <SmallLoader height={20} color='#0b99e6' /> : buttonText}
            </FileSelect>
          </ImageBlock>

          <Block>
            <Title>Link</Title>
            <Body>Add a link to your image. When your image is clicked, a customer will be directed to this link.</Body>
            <InputContainer>
              <Input type='url' value={link} onChange={handleSetLink} />
              <ValidateCheckIcon valid={validateURL(link) ? 1 : 0} />
            </InputContainer>
          </Block>

          <Block>
            <Title>Image Alt Text</Title>
            <Body>Describe your image to improve accessibility and SEO.</Body>
            <InputContainer>
              <Input type='text' value={alt} onChange={handleSetAlt} />
              <ValidateCheckIcon valid={validateAlt(alt) ? 1 : 0} />
            </InputContainer>
          </Block>
          <Block>
            <Title>Link Behavior</Title>
            <Body>Decide where your image banner link opens when a customer clicks it.</Body>
            <RadioGroup
              options={linkBehaviorOptions}
              display={['inline-block']}
              defaultValue={openInNewTab}
              onChange={handleChangeLinkBehavior}
            />
          </Block>
        </LeftColumn>
        <RightColumn>
          {image ? (
            <DesktopBannerImage {...imgixParams} src={`${image}?ar=6:2`} htmlAttributes={{ alt }} />
          ) : (
            <PlaceHolderBanner>Desktop image preview will show here.</PlaceHolderBanner>
          )}
          {mobileImage ? (
            <MobileBannerImage {...imgixParams} src={`${mobileImage}?ar=2.22:1`} htmlAttributes={{ alt }} />
          ) : (
            <MobilePlaceHolderBanner>Mobile image preview will show here.</MobilePlaceHolderBanner>
          )}
        </RightColumn>
      </Content>
      <StyledButton
        onClick={handleSave}
        disabled={
          isUploadingImage || isUploadingMobileImage || !image || !mobileImage || !(validateURL(link) || link === '')
        }
      >
        SAVE
      </StyledButton>
    </ModalContainer>
  );
}
