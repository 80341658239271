/* eslint-disable @typescript-eslint/naming-convention */
import { v4 as uuid } from 'uuid';
import { GqlMenuSection, MenuSectionType, GqlMenuSectionBulkUpdateInput } from 'types/graphql';
import { SubcategoryOptions } from 'shared/constants';
import {
  defaultSectionOptions,
  dynamicSectionOptions,
  PRODUCT_LIMIT,
  DYNAMIC_LABEL_MAP,
  DEFAULT_SECTIONS,
} from './homepage-form.constants';
import {
  FilterItems,
  Products,
  Offers,
  SectionParams,
  LocalSection,
  MenuSectionOption,
  DynamicSectionType,
} from './homepage-form.types';

type FilterByParams = {
  items: FilterItems;
  menuSection?: GqlMenuSection | SectionParams;
};

type Option = {
  key: string;
  label: string;
  value: string;
};

export function getAvailableMenuSectionTypes(menuSections: GqlMenuSection[] | LocalSection[]): MenuSectionOption[] {
  return [
    ...defaultSectionOptions,
    ...dynamicSectionOptions.filter(
      (menuSectionType) => typeof menuSectionType.visible === 'function' && !!menuSectionType.visible(menuSections)
    ),
  ];
}

const filterByBrand = (params: FilterByParams): Products | null => {
  const { items, menuSection } = params;
  return (
    items.products?.filter((product) => product?.brand?.id === menuSection?.brandId)?.slice(0, PRODUCT_LIMIT) ?? null
  );
};

const filterByCategory = (params: FilterByParams): Products | null => {
  const { items, menuSection } = params;

  return (
    items.products
      ?.reduce((prev, curr) => {
        if (curr?.type === menuSection?.category) {
          return [...prev, curr];
        }
        return prev;
      }, [])
      .slice(0, PRODUCT_LIMIT) ?? null
  );
};

const filterByOffers = (params: FilterByParams): Offers | null => params.items.offers?.slice(0, PRODUCT_LIMIT);

const filterBySubcategory = (params: FilterByParams): Products | null => {
  const { items, menuSection } = params;
  const foundSubcategoryValue = (SubcategoryOptions[menuSection?.category ?? ''] as Option[]).find(
    (subcategoryOption) => subcategoryOption.value === menuSection?.subcategory
  )?.value;

  return (
    items.products
      ?.reduce((prev, curr) => {
        if (curr?.subcategory === foundSubcategoryValue) {
          return [...prev, curr];
        }
        return prev;
      }, [])
      .slice(0, PRODUCT_LIMIT) ?? null
  );
};

const filterBySpecials = (params: FilterByParams): Products | null =>
  params.items.products?.filter((product) => !!product?.special).slice(0, PRODUCT_LIMIT) ?? null;

const filterByStaffPicks = (params: FilterByParams): Products | null =>
  params.items.products?.filter((product) => !!product?.featured?.current).slice(0, PRODUCT_LIMIT) ?? null;

const filterByTopSellers = (params: FilterByParams): Products | null =>
  params.items.products?.slice(0, PRODUCT_LIMIT) ?? null;

const filterByCustom = (params: FilterByParams): Products | null =>
  params.menuSection?.products?.reduce((prev, currId) => {
    const foundProduct = params.items.products?.find((product) => product?.id === currId) ?? null;
    if (foundProduct) {
      return [...prev, foundProduct];
    }
    return prev;
  }, []) ?? null;

const filterMapping = {
  BRAND: filterByBrand,
  CATEGORY: filterByCategory,
  OFFERS: filterByOffers,
  SPECIALS: filterBySpecials,
  STAFF_PICKS: filterByStaffPicks,
  SUBCATEGORY: filterBySubcategory,
  TOP_SELLERS: filterByTopSellers,
  CUSTOM: filterByCustom,
};

export const getMenuSectionProductFilter = (
  category: MenuSectionType
): ((params: FilterByParams) => Offers | Products | null) => filterMapping[category];

export function filterMenuSections(menuSections: GqlMenuSection[], filterItems: FilterItems): LocalSection[] {
  return menuSections.map((menuSection) => {
    const filterMethod = getMenuSectionProductFilter(menuSection.sectionType);
    const items = filterMethod({ items: { ...filterItems }, menuSection });

    return {
      brandId: menuSection.brandId,
      id: menuSection.id,
      label: menuSection.label,
      category: menuSection.category ?? '',
      subcategory: menuSection.subcategory ?? '',
      position: menuSection.position,
      sectionType: menuSection.sectionType,
      count: items?.length ?? 0,
      sectionName: menuSection.sectionName,
      products: menuSection.products,
    };
  });
}

export function getProductIds(products: Products | null): string[] {
  if (products) {
    return products.reduce((prev, currProduct) => {
      if (typeof currProduct?.id === 'string') {
        return [...prev, currProduct.id];
      }
      return prev;
    }, []);
  }

  return [];
}

export function createNewDynamicLocalSection(
  dispensaryId: string,
  sectionType: DynamicSectionType,
  filterItems: FilterItems
): LocalSection | null {
  if (DEFAULT_SECTIONS.includes(sectionType)) {
    return null;
  }

  const filterMethod = getMenuSectionProductFilter(sectionType);
  const items = filterMethod({ items: { ...filterItems } });

  return {
    id: uuid(),
    dispensaryId,
    label: DYNAMIC_LABEL_MAP[sectionType],
    position: 0,
    sectionType,
    count: items?.length ?? 0,
    products: getProductIds(items as Products),
  };
}

type UpdateParams = {
  dispensaryId: string;
  menuSections: GqlMenuSectionBulkUpdateInput[];
  deletes?: string[];
};

export function getUpdateParams(
  dispensaryId: string,
  initialSections: LocalSection[] | null,
  localSections: LocalSection[] | null
): UpdateParams {
  const initialSectionsIds = initialSections?.map((section) => section.id);
  const localSectionsIds = localSections?.map((section) => section.id);
  const idsMarkedForDeletion = initialSectionsIds?.filter(
    (initialSectionId: string) => !localSectionsIds?.find((id) => initialSectionId === id)
  );

  const cleanSectionsForUpdate: GqlMenuSectionBulkUpdateInput[] =
    localSections?.map((section, index) => {
      const menuSectionUpdateParams = {
        ...(section.subcategory ? { subcategory: section.subcategory } : {}),
        brandId: section.brandId,
        ...(section.category ? { category: section.category } : {}),
        dispensaryId,
        id: section.id,
        position: index + 1,
        products: section.products,
        sectionName: section.sectionName,
        sectionType: section.sectionType,
      } as GqlMenuSectionBulkUpdateInput;

      const foundInInitialSections = initialSectionsIds?.find((id) => id === section.id);
      if (!foundInInitialSections) {
        delete menuSectionUpdateParams.id;
      }

      return menuSectionUpdateParams;
    }) ?? [];

  return {
    dispensaryId,
    menuSections: cleanSectionsForUpdate,
    deletes: idsMarkedForDeletion,
  };
}
