import React, { SVGAttributes } from 'react';

// rationale: SVG paths are looooong
/* eslint-disable max-len */

export function CopyIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.21755 3.77734H2.12256C1.55961 3.77822 1.01999 3.9963 0.622077 4.38376C0.224165 4.77122 0.000448214 5.29641 0 5.84414V14.9328C0.000448214 15.4805 0.224165 16.0057 0.622077 16.3932C1.01999 16.7806 1.55961 16.9987 2.12256 16.9996H9.21755C9.77933 16.997 10.3172 16.7781 10.7137 16.3908C11.1101 16.0036 11.3329 15.4794 11.3333 14.9328V5.84414C11.3329 5.29755 11.1101 4.77335 10.7137 4.38608C10.3172 3.99881 9.77933 3.77996 9.21755 3.77734ZM9.76345 14.9328C9.76345 15.0736 9.70593 15.2087 9.60356 15.3083C9.50118 15.4079 9.36233 15.4639 9.21755 15.4639H2.12256C1.97808 15.4635 1.83966 15.4073 1.73765 15.3078C1.63564 15.2082 1.57836 15.0733 1.57836 14.9328V5.84414C1.57836 5.70371 1.63569 5.56904 1.73775 5.46974C1.83981 5.37044 1.97823 5.31466 2.12256 5.31466H9.21755C9.36204 5.31466 9.50063 5.37039 9.60296 5.46964C9.70529 5.56889 9.763 5.70356 9.76345 5.84414V14.9328Z'
        fill='#677882'
      />
      <path
        d='M6.60353 0H13.0294C13.5817 0.000923757 14.111 0.231582 14.5013 0.641381C14.8917 1.05118 15.1111 1.60666 15.1115 2.18597V11.2491C15.1111 11.772 14.913 12.2733 14.5608 12.6432C14.2085 13.0131 13.7307 13.2213 13.2323 13.2222H12.8349H12.3301V11.5963H12.8349H13.2307C13.3189 11.5963 13.4035 11.5595 13.4659 11.4941C13.5282 11.4286 13.5633 11.3399 13.5633 11.2474V2.18597C13.5633 2.03728 13.5071 1.89466 13.407 1.78936C13.307 1.68406 13.1712 1.62467 13.0294 1.62421H6.60353C6.51532 1.62421 6.43072 1.66097 6.36835 1.72641C6.30597 1.79184 6.27093 1.88059 6.27093 1.97313V3H4.72266V1.97313C4.7231 1.44996 4.9214 0.948359 5.27404 0.578426C5.62668 0.208493 6.10483 0.000461955 6.60353 0Z'
        fill='#677882'
      />
    </svg>
  );
}
