import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/themes';
import { Logo } from 'shared/assets/logo';
import { mediaQueries } from 'shared/styles';

export function PecosValleyLogin(): JSX.Element {
  return (
    <ImageWrapper>
      <Logo role='img' fill={colors.white} />
      <TextWrapper>
        <Description>
          <span>Case study:</span> How Dutchie's full stack helped Pecos Valley scale to 24 locations, boost sales &
          reduce wait times.
        </Description>
        <LearnMore
          href='https://business.dutchie.com/post/pecos-valley-production-leading-the-cannabis-revolution-in-new-mexico??utm_medium=in-app&utm_source=ecomm&utm_campaign=organic_Q423_LOGIN_ECOMM_PECOS&utm_content=base_base_link'
          target='_blank'
          rel='noreferrer'
        >
          Read the full story
        </LearnMore>
      </TextWrapper>
    </ImageWrapper>
  );
}

const LearnMore = styled.a`
  background: transparent;
  border: 1px solid ${colors.white};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.00375rem;
  color: ${colors.white};
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    color: ${colors.white};
    font-weight: 700;
  }
`;

const Description = styled.p`
  color: ${colors.white};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.3125rem;
  max-width: 30rem;

  span {
    color: ${colors.orange[50]};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  background-image: url('https://images.dutchie.com/admin-login/admin-login-pecos-valley.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 3.5rem 18.5rem 14.25rem 3.5rem;
  flex-direction: column;
  justify-content: space-between;

  @media ${mediaQueries.largeDesktop} {
    width: 100%;
    padding: 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
