export type TabsData = {
  label: string;
  key: string;
  icon?: string;
};

type GetTabsProps = {
  isEnabledAgeVerification: boolean;
  isCustomizationV2Enabled: boolean;
};

export function getSelectedTab(currentTab: string, tabs: TabsData[]): string {
  return tabs.map((t) => t.key).some((t) => t === currentTab) ? currentTab : tabs[0].key;
}

export function getTabs({ isEnabledAgeVerification, isCustomizationV2Enabled }: GetTabsProps): TabsData[] {
  const tabs: TabsData[] = [
    {
      label: 'Homepage',
      key: 'homepage',
    },
  ];

  if (isCustomizationV2Enabled) {
    tabs.push(
      {
        label: 'Theme',
        key: 'theme',
      },
      {
        label: 'Fonts',
        key: 'fonts',
      }
    );
  } else {
    tabs.push({
      label: 'Colors & Fonts',
      key: 'colors',
    });
  }

  tabs.push(
    {
      label: 'Image Banner',
      key: 'image-banner',
    },
    {
      label: 'Text Banner',
      key: 'menu-banner',
    }
  );

  if (isEnabledAgeVerification) {
    tabs.push({
      label: 'Age Verification',
      key: 'age-verification',
    });
  }

  tabs.push(
    {
      label: 'Legal Disclaimer',
      key: 'legal-disclaimer',
    },
    {
      label: 'Category Photos',
      key: 'category-photos',
    },
    {
      label: 'Embed',
      key: 'embed',
    }
  );

  return tabs;
}
